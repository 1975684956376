import React from 'react'
import { Typography, Button, Row, Col, Form, Card, Avatar, Image, Checkbox, Divider, Modal } from "antd";

import endUserCoverimage from "../Assets/endUserCoverimage.jpg"
import dummyPhoto from "../Assets/photo.png";

const EndUserHeader = (venueInfo) => {  
  const styles = {
    container: {
      position: 'relative',
      width: '100%',
      height: "220px"
    },
    coverImageContainer: {
      width: '100%',
      height: '140px',
      overflow: 'hidden',
    },
    coverImage: {
      objectFit: 'cover',
    },
    profileImageContainer: {
      width: "100%",
      height:"100%",
      display: "flex",
      position: 'absolute',
      top: '80px',
      left: window.innerWidth <= 768 ? "50%" : "51%",
      transform: 'translateX(-50%)',
      // textAlign: 'center',

    },
    profileImage: {
      border: '2px solid white',
    },
    companyName: {
      width:"70%",
      fontSize: '22px',
      marginTop: '28px',
      // marginLeft:"10px",
      fontWeight: 'bolder',
      color: '#222222',
    },
  };
  return (
    <>
      <div style={styles.container}>
        <div style={styles.coverImageContainer}>
          <Image
            width="100%"
            height="150px"
            src={venueInfo &&  venueInfo?.venueInfo?.location?.banner ?venueInfo?.venueInfo?.location?.banner:endUserCoverimage}
            style={styles.coverImage}
            preview={false}
          />
        </div>

        <div style={styles.profileImageContainer}>
          <Avatar
            size={100}
            src={venueInfo && venueInfo.venueInfo.company_logo ? venueInfo.venueInfo.company_logo : dummyPhoto}
            style={styles.profileImage}
          />
          <div style={styles.companyName}>Venue : {venueInfo ? venueInfo?.venueInfo?.location?.name : "Venu Name"}</div>
          {/* <div style={styles.companyName}>Venue: Ester dddweedasdasdasdasdasdasdasdasdasdwHoward</div>  */}
        </div>
      </div>
    </>
  )
}

export default EndUserHeader