import React from "react";
import { Card, Row, Col } from "antd";
import "./paymentIntegration.scss";
import { Link } from "react-router-dom";

const SetupPayment = () => {
  return (
    <div>
      <div>
        <Card className="main-card">
          <h1>Get Paid by PlanSpace</h1>
          <p className="message-text">
            Please select your tax reporting classification to continue.
          </p>

          <Row gutter={[16, 16]}>
            <Col span={24} lg={12}>
              <Link to="/integration/stripe/setup/individual">
                <Card className="integration-card">
                  <h3 style={{ marginTop: "20px" }}>Individual</h3>
                  <p>You file taxes as an individual.</p>
                </Card>
              </Link>
            </Col>
            <Col span={24} lg={12}>
              <Link to={`/integration/stripe/setup/company`}>
                <Card className="integration-card">
                  <h3>Company</h3>
                  <p>
                    You file taxes as a business entity registered with a U.S.
                    state as a limited liability company (LLC) that has only one
                    member or owner.
                  </p>
                </Card>
              </Link>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};

export default SetupPayment;
