import React from "react";
import { Row, Col, Form, Input, Button } from "antd";
import { Link } from "react-router-dom";
import logo2 from "../Assets/logo2.png";
import "./BizPhone.css";

const BusinessPhone = () => {
  return (
    <Row className="phone-container">
      <Col span={3}>
        <div className="phone-logo-container">
          <img src={logo2} alt="Logo" className="phone-logo" />
        </div>
      </Col>
      <Col span={21} className="phone-form-container">
        <div className="phone-setup-title">
          <p>Base Setup</p>
        </div>

        <div className="phone-setup-input-container">
          <div className="phone-setup-contact-group">
            {/* <p className='phone-setup-contact'>Enter Email Address</p> */}
            <input
              type="text"
              id="businessemail"
              placeholder="Enter Email Address"
              name="businessemail"
              disabled
            />
          </div>
        </div>
        <div className="phone-setup-content">
          <div className="phone-card-container">
            <div className="phone-input-group-active">
              <p className="phone-active-setup-phone">Enter Phone Number</p>
              <input
                style={{
                  backgroundColor: "#F8F8F8",
                  color: "#B6B6B6",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 100,
                  lineHeight: "15px",
                }}
                type="phone"
                id="phone-businessphone"
                placeholder="Enter Phone Number"
                name="businessphone"
              />
            </div>
            <Link to="/setuploading">
              <button type="primary" className="phone-setup-next-button">
                Complete
              </button>
            </Link>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default BusinessPhone;
