import React from 'react';
import { Button, Modal } from 'antd';
import { Link } from 'react-router-dom';

const VenueModal = ({ setVenueMissingModal, venueMissingModal }) => {
    return (
        <div>
            <Modal
                title="Hummm..."
                centered
                onCancel={setVenueMissingModal}
                open={venueMissingModal}
                footer={
                    <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
                        <Button key="add" type="primary">
                            <Link to='/company/venues'>Add Venue</Link>
                        </Button>
                        <Button key="cancel" type="primary" onClick={setVenueMissingModal}>
                            Cancel
                        </Button>
                    </div>
                }
            >
                <p>I can’t find any Venue. Let’s create a venue first. Then come back to this Booking Tool.</p>
            </Modal>
        </div>
    );
}

export default VenueModal;