import React from "react";
import { Row, Col, Spin } from "antd";
import LoginHero from "../Login/Assets/Login.png";
import logo from "../Login/Assets/logo.png";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import toast from "react-hot-toast";
import "./Activate.css";
import axiosInstance from "../../common/axiosInstance";

const ActivateAccount = () => {
  const navigate = useNavigate();
  const [isTokenValid, setIsTokenValid] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true); // New loading state

  function useQuery() {
    let search = new URL(document.location).searchParams;
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  const token = query.get("token");

  const validateToken = async () => {
    try {
      await axiosInstance.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/auth/register/activate/`,
        { token: token },
      );
      setIsTokenValid(true);
    } catch (error) {
      setIsTokenValid(false);
    } finally {
      setIsLoading(false); // Set loading state to false when validation is done
    }
  };

  React.useEffect(() => {
    validateToken();
  }, [token]);

  function CircularProgressWithLabel(props) {
    return (
      <div>
        <CountdownCircleTimer
          isPlaying
          duration={5}
          colors={["#2576b8"]}
          colorsTime={[0]}
          onComplete={() => {
            navigate("/login");
          }}
        >
          {({ remainingTime }) => {
            return (
              <div className="Activate-col">
                <p>Redirecting to login</p>
                <p style={{ textAlign: "center", marginTop: "2px" }}>in</p>
                <div style={{ textAlign: "center", marginTop: "2px" }}>
                  <h2>{remainingTime}</h2>
                  <h3>Seconds</h3>
                </div>
              </div>
            );
          }}
        </CountdownCircleTimer>
      </div>
    );
  }

  return (
    <Row>
      <div className="Activate-login-container">
        <Col span={11} className="Activate-image-column">
          <div className="Activate-image">
            <img src={LoginHero} alt="Activate Image" />
          </div>
        </Col>
        <Col span={13} className="Activate-col">
          <div className="Activate-form-container">
            {isLoading ? (
              <>
                <Spin size="large" />
              </>
            ) : isTokenValid ? (
              <div>
                <div className="Activate-logo">
                  <img src={logo} alt="" />
                </div>
                <CircularProgressWithLabel />
              </div>
            ) : (
              <div className="Activate-logo">
                <img src={logo} alt="" />
                <h3>Invalid OR Expired Link</h3>
              </div>
            )}
          </div>
        </Col>
      </div>
    </Row>
  );
};

export default ActivateAccount;
