import { ROOM_API_ENDPOINT } from "./endpoints";
import client from "../axios";

// Rooms
export const getRooms = async (location_id) => {
  return await client.get(
    ROOM_API_ENDPOINT + (location_id ? `?location=${location_id}` : ""),
  );
};

// Get Room
export const getRoom = async (id) => {
  return await client.get(ROOM_API_ENDPOINT + id + "/");
};

// Create Room
export const createRoom = async (body) => {
  return await client.post(ROOM_API_ENDPOINT, body);
};

// Create Room
export const updateRoom = async (id, body) => {
  return await client.put(ROOM_API_ENDPOINT + id + "/", body);
};

// Delete Room
export const deleteRoom = async (id) => {
  return await client.delete(ROOM_API_ENDPOINT + id + "/");
};
