import React, { useState, useEffect } from "react";
import usePopup from "../../../common/MessageModal/usePopup";
import { Select } from "antd";
import { getEventStages, updateEvent } from "../../../services/events";

const StagesDropdown = ({ event, value, fetchEvents, ...rest }) => {
  const popup = usePopup();
  const [loading, setLoading] = useState(false);
  const [stages, setStages] = useState([]);
  const [selectedStage, setSelectedStage] = useState({});

  const fetchStages = async () => {
    setLoading(true);
    try {
      const res = await getEventStages(event?.status ? event?.status : "");
      const filterd = res?.data?.results?.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setStages(filterd);
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = "/server-error";
        console.log("Redirecting to server error page...");
      } else {
        popup.error("Oops...", error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const updateEventStage = async (id) => {
    setLoading(true);
    try {
      const res = await updateEvent(event?.id, {
        stage: id,
      });
      popup.success("Success!", res?.data?.message);
      fetchEvents("no_loading");
    } catch (error) {
      popup.error("Oops!", error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (value) {
      setSelectedStage({ label: value.name, value: value.id });
    }
  }, [value]);

  return (
    <Select
      {...rest}
      value={selectedStage}
      popupMatchSelectWidth={false}
      options={stages}
      onChange={(newVal) => {
        updateEventStage(newVal);
        setSelectedStage(newVal);
      }}
      loading={loading}
      onDropdownVisibleChange={(open) => {
        if (open) {
          fetchStages();
        }
      }}
    />
  );
};

export default StagesDropdown;
