import React, { useState, useEffect } from "react";
import ImageUploader from "../../../../components/ImageUploader";
import usePopup from "../../../../common/MessageModal/usePopup";
// UI's
import { Button, Modal, Checkbox } from "antd";
import { Form, Input, Collapse, Row, Col } from "antd";
import { createRoom, updateRoom } from "../../../../services/rooms";
import { createVenue } from "../../../../services/venues";
import {
  spaces_options,
  amenities_options,
  ceremony_types_options,
  AddOptions,
} from "./utils";

const RoomForm = ({
  open,
  handelClose,
  fetchRooms,
  venue_id,
  room,
  venue_payload,
  closeAll,
}) => {
  const popup = usePopup();
  const [form] = Form.useForm();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [spacesOptions, setSpacesOptions] = useState(spaces_options);
  const [amenitiesOptions, setAmenitiesOptions] = useState(amenities_options);
  const [ceremonyTypesOptions, setCeremonyTypesOptions] = useState(
    ceremony_types_options,
  );

  const checkIfNotHave = (item, options) => {
    const have = options?.find((opt) => opt.label === item);
    return Boolean(!have);
  };
  const addMissingOptions = (selected, setOptions, oldOptions) => {
    const filtered = selected.filter((item) =>
      checkIfNotHave(item, oldOptions),
    );
    if (filtered.length) {
      const newData = filtered.map((item) => ({ label: item, value: item }));
      const marged = oldOptions.concat(newData);
      setOptions(marged);
    }
  };

  useEffect(() => {
    if (room) {
      const { images, ...rest } = room;
      form.setFieldsValue(rest);
      if (rest?.spaces?.length) {
        addMissingOptions(rest?.spaces, setSpacesOptions, spacesOptions);
      }
      if (rest?.amenities?.length) {
        addMissingOptions(
          rest?.amenities,
          setAmenitiesOptions,
          amenitiesOptions,
        );
      }
      if (rest?.ceremony_types?.length) {
        addMissingOptions(
          rest?.ceremony_types,
          setCeremonyTypesOptions,
          ceremonyTypesOptions,
        );
      }
    }
  }, [room, open]);

  const handleCancel = () => {
    handelClose();
    form.resetFields();
    setImages([]);
  };

  const renderdButtons = () => {
    let buttons = [
      <Button size={"large"} key="cancel" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button
        style={{ marginLeft: "auto" }}
        size={"large"}
        key="next"
        type="primary"
        loading={loading}
        onClick={() => form.submit()}
      >
        {room?.type === "edit" ? "Update" : "Save"}
      </Button>,
    ];
    return buttons;
  };

  const handleSubmit = async (values, venueId) => {
    const formData = new FormData();
    if (venue_id && open?.type !== "venue") {
      formData.append("location", venue_id);
    }
    if (venueId) {
      formData.append("location", venueId);
    }
    Object.entries(values).map(([key, value]) => {
      formData.append(
        key,
        typeof value === "array" || typeof value === "object"
          ? JSON.stringify(value)
          : value,
      );
    });

    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images", images[i].originFileObj);
      }
    }

    setLoading(true);
    try {
      if (room?.type === "edit") {
        const res = await updateRoom(room?.id, formData);
        popup.success("Success!", res?.data?.message);
      } else {
        const res = await createRoom(formData);
        popup.success("Success!", res?.data?.message);
      }
      fetchRooms();
      handleCancel();
      if (open?.type === "venue") {
        closeAll();
      }
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = "/server-error";
        console.log("Redirecting to server error page...");
      }
      if (typeof error?.response?.data?.message === "object" || "array") {
        const errors = error?.response?.data?.message[0];
        Object.entries(errors).map(([field, message]) => {
          form.setFields([
            {
              name: field,
              errors: message,
            },
          ]);
        });
      } else {
        popup.error("Oops...", error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  // Venue and room creation
  const handleVenue = async (room_payload) => {
    setLoading(true);
    try {
      const res = await createVenue(venue_payload);
      handleSubmit(room_payload, res?.data?.data?.id);
    } catch (error) {
      if (typeof error?.response?.data?.message === "object" || "array") {
        const errors = error?.response?.data?.message[0];
        Object.entries(errors).map(([field, message]) => {
          form.setFields([
            {
              name: field,
              errors: message,
            },
          ]);
        });
      } else {
        popup.error("Oops...", error?.response?.data?.message);
      }
    }
  };

  const collapse_items = [
    {
      key: "1",
      label: "Spaces Type",
      children: (
        <>
          <Form.Item name={"spaces"}>
            <Checkbox.Group options={spacesOptions} />
          </Form.Item>
          <AddOptions values={spacesOptions} handleChange={setSpacesOptions} />
        </>
      ),
    },
    {
      key: "2",
      label: "Select amenities",
      children: (
        <>
          <Form.Item name={"amenities"}>
            <Checkbox.Group options={amenitiesOptions} />
          </Form.Item>
          <AddOptions
            values={amenitiesOptions}
            handleChange={setAmenitiesOptions}
          />
        </>
      ),
    },
    {
      key: "3",
      label: "Event types",
      children: (
        <>
          <Form.Item name={"ceremony_types"}>
            <Checkbox.Group options={ceremonyTypesOptions} />
          </Form.Item>
          <AddOptions
            values={ceremonyTypesOptions}
            handleChange={setCeremonyTypesOptions}
          />
        </>
      ),
    },
  ];

  return open ? (
    <Modal
      centered
      title={"Add Room"}
      open={open}
      onCancel={handleCancel}
      footer={renderdButtons()}
    >
      <Form
        style={{ marginTop: 24 }}
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        className="modal-form"
        onFinish={(values) => {
          if (open?.type === "venue") {
            handleVenue(values);
          } else {
            handleSubmit(values);
          }
        }}
      >
        <Form.Item
          name="name"
          label="Room Name*"
          rules={[
            {
              required: true,
              message: "Room name is required!",
            },
          ]}
        >
          <Input maxLength={50} size="large" placeholder="Enter room name" />
        </Form.Item>
        <Row gutter={[24, 24]}>
          <Col span={24} xs={12}>
            <Form.Item
              name={"flat_price"}
              label="Flat Price*"
              rules={[
                {
                  validator: (rule, value) => {
                    const numericValue = parseFloat(value);
                    if (
                      isNaN(numericValue) ||
                      numericValue <= 0 ||
                      numericValue > 1000000
                    ) {
                      return Promise.reject(
                        "Please enter a valid amount between 1 and 1,000,000!",
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input size="large" type="number" prefix={<>$</>} />
            </Form.Item>
          </Col>
          <Col span={24} xs={12}>
          <Form.Item
              name={"pp_price"}
              label="Per Person Price*"
              rules={[
                {
                  validator: (rule, value) => {
                    const numericValue = parseFloat(value);
                    if (
                      isNaN(numericValue) ||
                      numericValue <= 0 ||
                      numericValue > 1000000
                    ) {
                      return Promise.reject(
                        "Please enter a valid amount between 1 and 1,000,000!",
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input size="large" type="number" prefix={<>$</>} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
              name={"max_guests"}
              label="Max Guests*"
              rules={[
                {
                  validator: (rule, value) => {
                    const numericValue = parseFloat(value);
                    if (
                      isNaN(numericValue) ||
                      numericValue <= 0 ||
                      numericValue > 500
                    ) {
                      return Promise.reject("Guest must be between 1 and 500");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input size="large" type="number" placeholder="type here..." />
            </Form.Item>
        <Collapse
          className="room-options-collapse"
          defaultActiveKey={room?.type === "edit" && ["1", "2", "3"]}
          items={collapse_items}
        />

        <ImageUploader
          previous_images={room?.images}
          revalidate={fetchRooms}
          output={setImages}
        />
      </Form>
    </Modal>
  ) : null;
};

export default RoomForm;
