import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Spin } from "antd";
import axios from "axios";
import MainLayout from "../common/MainLayout";

const PrivateRoutes = () => {
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const token = localStorage.getItem("access_token");

  const verifyUser = async () => {
    if (!token) {
      setAuthenticated("not_valid");
      return;
    }
    setLoading(true);
    try {
      await axios
        .post(`${process.env.REACT_APP_BASE_API_URL}/api/auth/verify/`, {
          token,
        })
        .then((res) => {
          localStorage.setItem("access_token", res.data.data.access);
          localStorage.setItem(
            "planspace_user_obj",
            JSON.stringify(res.data.data),
          );
          setAuthenticated("valid");
        });
    } catch (error) {
      setAuthenticated("not_valid");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    verifyUser();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (authenticated === "valid") {
    return (
      <MainLayout>
        <Outlet />
      </MainLayout>
    );
  }
  if (authenticated === "not_valid") {
    return <Navigate to="/login" exact />;
  }

  return null;
};

export default PrivateRoutes;
