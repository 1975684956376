import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { Row, Col, Card, Progress } from "antd";
import logo2 from "../Assets/logo2.png";
import "./SetupLoading.css";

const CompletedLoading = () => {
  const navigate = useNavigate(); // Use useNavigate hook
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress < 100 ? prevProgress + 1 : 100,
      );
    }, 50);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (progress === 100) {
      // Use setTimeout to add a delay before navigating to 'setupcomplete'
      const delay = 2000; // 2 seconds
      const timeoutId = setTimeout(() => {
        navigate("/setupcomplete");
      }, delay);

      // Cleanup the timeout if the component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, [progress, navigate]);

  return (
    <div className="loading-container">
      <div className="logo-container">
        <img className="setuploading-logo" src={logo2} alt="" />
      </div>
      <div className="loading-card-parent">
        <div className="loading-card">
          <p className="loading-heading">
            Please wait, we are grouping your entries
          </p>
          <p className="loading-para">We are collecting your inputs to feed</p>
          <div className="loading-bar">
            <Progress percent={progress} strokeColor="#52c41a" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletedLoading;
