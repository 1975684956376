import React, { useState } from "react";
import { Button, Input, Space, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";

export const spaces_options = [
  { label: "Backyard", value: "Backyard" },
  { label: "Ballroom", value: "Ballroom" },
  { label: "Barn", value: "Barn" },
  { label: "Farm & Ranch", value: "Farm & Ranch" },
  { label: "Country Club", value: "Country Club" },
  { label: "Estate", value: "Estate" },
  { label: "Garden", value: "Garden" },
  { label: "Historic Venue", value: "Historic Venue" },
  { label: "Mountain", value: "Mountain" },
  { label: "Park", value: "Park" },
  { label: "Restaurant", value: "Restaurant" },
  { label: "Vineyard & Winery", value: "Vineyard & Winery" },
];

export const amenities_options = [
  { label: "Tech Equipment & Support", value: "Tech Equipment & Support" },
  { label: "Valet Parking", value: "Valet Parking" },
  { label: "Shuttles", value: "Shuttles" },
  { label: "Ceremony Area", value: "Ceremony Area" },
  { label: "Covered Outdoors Space", value: "Covered Outdoors Space" },
  { label: "Bride Dressing Room", value: "Bride Dressing Room" },
  { label: "Groom Dressing Room", value: "Groom Dressing Room" },
  { label: "Handicap Accessible", value: "Handicap Accessible" },
  { label: "Indoor Event Space", value: "Indoor Event Space" },
  { label: "Liability Insurance", value: "Liability Insurance" },
  { label: "Outdoor Event Space", value: "Outdoor Event Space" },
  { label: "Reception Area", value: "Reception Area" },
  { label: "Wireless Internet", value: "Wireless Internet" },
  { label: "Civil Ceremony", value: "Civil Ceremony" },
  { label: "Religious Ceremony", value: "Religious Ceremony" },
  { label: "Renewal Ceremony", value: "Renewal Ceremony" },
  { label: "Destination Ceremony", value: "Destination Ceremony" },
];

export const ceremony_types_options = [
  { label: "Corporate Party", value: "Corporate Party" },
  { label: "General Party", value: "General Party" },
  { label: "Birthday", value: "Birthday" },
  { label: "Sweet Sixteen", value: "Sweet Sixteen" },
  { label: "Quinceañera", value: "Quinceañera" },
  { label: "Bar/Bat Mitzvah", value: "Bar/Bat Mitzvah" },
  { label: "Anniversary", value: "Anniversary" },
  { label: "Divorce", value: "Divorce" },
  { label: "Wedding", value: "Wedding" },
  { label: "Ethnic Wedding", value: "Ethnic Wedding" },
  { label: "Engagement", value: "Engagement" },
  { label: "Bachelor/Bachelorette", value: "Bachelor/Bachelorette" },
  { label: "Bridal Shower", value: "Bridal Shower" },
  { label: "Baby Shower", value: "Baby Shower" },
  { label: "Reunion", value: "Reunion" },
  { label: "Other", value: "Other" },
];

export const AddOptions = ({ values, handleChange }) => {
  const { Text } = Typography;
  const [error, setError] = useState("");
  const [value, setValue] = useState("");

  const addValue = () => {
    if (!value) {
      setError("Please type first to add new option!");
    } else {
      const alreadyHave = values?.find((item) => item.label === value);
      if (alreadyHave) {
        setError("This option alrady exist.");
      } else {
        handleChange([...values, { label: value, value: value }]);
        setValue("");
        setError("");
      }
    }
  };

  return (
    <>
      <Space.Compact style={{ width: "100%" }}>
        <Input
          status={error && "error"}
          style={{ width: "100%" }}
          placeholder="Add new option"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            setError("");
          }}
        />
        <Button icon={<PlusOutlined />} onClick={addValue}>
          Add
        </Button>
      </Space.Compact>
      {error ? (
        <Text style={{ fontSize: 12 }} type="danger">
          {error}
        </Text>
      ) : null}
    </>
  );
};
