import React from 'react';
import { Table, Typography, Row, Col, Divider, Button, Modal, Input, Form } from 'antd';
import client from '../../../../../axios';
import jsPDF from 'jspdf';
import generatePDF from 'react-to-pdf';
import { DownloadOutlined, ShareAltOutlined } from '@ant-design/icons';
import Moment from 'react-moment';

const { Title, Text } = Typography;

const Receipt = ({ payment_id }) => {
  const targetRef = React.useRef();
  const [isGeneratingPDF, setIsGeneratingPDF] = React.useState(false);
  const [receiptData, setReceiptData] = React.useState(null);
  const [openEmail, setOpenEmail] = React.useState(false);
  const [emails, setEmails] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  
  const handleDownloadPDF = () => {
    const buttonContainer = document.getElementById('button-container');
        buttonContainer.style.display = 'none';  // Hide the buttons
        generatePDF(targetRef, options).then(() => {
            buttonContainer.style.display = 'block';  // Show the buttons again
        });
};

  const getPaymentReceipt = async () => {
    await client
      .get(`/api/event/payment/${payment_id}/receipt_info/`)
      .then((res) => {
        setReceiptData(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    getPaymentReceipt();
  }, []);

  const handleDownload = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: "a4",
    });
    doc.html(document.getElementById("receipt").innerHTML, {
      callback: function (doc) {
        doc.save(receiptData.id + ".pdf");
      },
      html2canvas: { scale: 1 },
    });
  };

  const handleShare = async () => {
    setLoading(true);
    if (emails === "") {
      setLoading(false);
      return;
    }
    await client
      .post(`/api/event/payment/${payment_id}/receipt_share/`, {
        email: emails,
      })
      .then((res) => {
        setOpenEmail(false);
        setEmails("");
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const options = {
    orientation: "landscape",
    filename: receiptData?.id + ".pdf",
    format: "letter",
  };

    return (
        <div style={{ padding: '20px', margin: '0 auto', width: '100%' }}>
            {openEmail && (
                <Modal
                    title="Share Receipt"
                    open={openEmail}
                    okText="Share Receipt"
                    onOk={handleShare}
                    okButtonProps={{ loading: loading }}
                    cancelButtonProps={{ disabled: loading }}
                    onCancel={() => setOpenEmail(false)}
                    centered
                    maskClosable={false}
                >
                    <Input
                        placeholder="Enter email (comma separated in case of multiple)" value={emails} onChange={(e) => setEmails(e.target.value)} />
                    {/* <Button type="primary" onClick={handleShare}>Submit</Button> */}
                </Modal>
            )}

            <div id='receipt' ref={targetRef} style={{padding:"40px"}}>
                <Title level={2} style={{ textAlign: 'center' }}>{receiptData?.company_name}</Title>
                <Title level={5} style={{ textAlign: 'left' }}>Dear {receiptData?.contact}</Title>
                {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}> */}
                <p style={{ textAlign: 'left', width: "100%" }}>
                    Thank you for choosing our venue to celebrate your occasion. We are delighted to
                    host you and enjoy your celebration.
                </p>
                {/* </div> */}
                <Row gutter={16} style={{ marginTop: "10px" }}>
                    <Col span={17}>
                        <Text strong>Payment Sequence:</Text> <Text>{receiptData?.payment_sequence}</Text>
                    </Col>
                    <Col span={7}>
                        {receiptData?.date && (
                            <>
                                <Text strong>Date:</Text> <Text><Moment format="DD/MM/YYYY HH:mm" date={receiptData?.date} /></Text>
                            </>
                        )}
                    </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: "10px" }}>
                    <Col span={17}>
                        <Text strong>Payment Terms:</Text> <Text>{receiptData?.type}</Text>
                    </Col>
                    <Col span={7}>
                        {receiptData?.due_date && (
                            <>
                                <Text strong>Due Date:</Text> <Text><Moment format="DD/MM/YYYY" date={receiptData?.due_date} /></Text>
                            </>
                        )}
                    </Col>
                </Row>
                <Divider />

                <table style={{ width: '100%' }} border>
                    <thead>
                        <tr style={{ backgroundColor: '#F9FAFB' }}>
                            <th colSpan={2} style={{ textAlign: 'left' }}>My Booking Summary</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={2} style={{ textAlign: 'left' }}>{receiptData?.package?.name}</td>
                            <td style={{ textAlign: 'center' }}>${receiptData?.package?.price}</td>
                        </tr>
                        {receiptData?.services.length > 0 && (
                            <>
                                <tr>
                                    <td colSpan={3} style={{ textAlign: 'left', fontWeight: 'bold' }}>Additional Services:</td>
                                </tr>
                                {receiptData?.services?.map((item, index) => (
                                    <tr key={index}>
                                        <td colSpan={2} style={{ textAlign: 'left' }}>{item?.name}</td>
                                        <td style={{ textAlign: 'center' }}>${item?.flat_price}</td>
                                    </tr>
                                ))}
                            </>
                        )}
                        <Divider />

                        <tr>
                            <td colSpan={2} style={{ textAlign: 'left', fontWeight: 'bold' }}>Subtotal:</td>
                            <td style={{ textAlign: 'center' }}>${receiptData?.total_payment}</td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{ textAlign: 'left', fontWeight: 'bold' }}>Tax (0%):</td>
                            <td style={{ textAlign: 'center' }}>$0</td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{ textAlign: 'left', fontWeight: 'bold' }}>Total:</td>
                            <td style={{ textAlign: 'center' }}>${receiptData?.total_payment}</td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{ textAlign: 'left', fontWeight: 'bold' }}>Amount Paid:</td>
                            <td style={{ textAlign: 'center' }}>${receiptData?.paid_payment}</td>
                        </tr>
                        {/* {receiptData?.schedule_payments && */}
                        <tr>
                            <td colSpan={2} style={{ textAlign: 'left', fontWeight: 'bold' }}>Schedule balance due:</td>
                            <td style={{ textAlign: 'center' }}>${receiptData?.schedule_payments ? receiptData?.schedule_payments : "0"}</td>
                        </tr>
                        {/* } */}

                        {receiptData?.schedule_payments > 0 && receiptData?.next_payment &&
                            <tr>
                                <td colSpan={2} style={{ textAlign: 'left', fontWeight: 'bold' }}>Next Payment Date:</td>
                                <td style={{ textAlign: 'center' }}>
                                    <Moment format='DD/MM/YYYY' date={receiptData?.next_payment} />
                                </td>
                            </tr>
                        }



                        <tr>
                            <td colSpan={2} style={{ textAlign: 'left', fontWeight: 'bold' }}>Payment method:</td>
                            <td style={{ textAlign: 'center' }}>{receiptData?.payment_method}</td>
                        </tr>
                    </tbody>
                </table>
                <Divider />
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        Payment ID: <Text strong>{receiptData?.id}</Text>
                    </Col>

                    {!isGeneratingPDF && (
                    <Col id="button-container" style={{ textAlign: "end" }} xs={24} md={10}>
                        <Button icon={<DownloadOutlined />} type="primary" onClick={handleDownloadPDF}>Download PDF</Button>
                        <Button icon={<ShareAltOutlined />} type="primary" onClick={() => setOpenEmail(true)} style={{ marginLeft: '10px' }}>Share</Button>
                    </Col>
                )}
                </Row>
                <Divider />
                <div style={{ alignItems: "center", width: "100%" }}>
                    <p style={{ textAlign: 'center', width: "100%" }}>
                        Stay connected with us.  || Questions or remarks? We're happy to help
                    </p>
                    <p style={{ textAlign: 'center', width: "100%" }}>
                        This e-mail has been sent to user123@gmail.com
                    </p>
                    <p style={{ textAlign: 'center', width: "100%", textDecoration: "underline" }}>
                        Terms and Conditions | Privacy Policy
                    </p>
                </div>
            </div>

        </div >
    );
};

export default Receipt;
