import React, { useState, useEffect } from "react";
import myApi from "../../../../network/axios";
import { Button, Modal, Form, Input } from "antd";
import InputMask from "react-input-mask";
import { toast } from "react-hot-toast";

const AddTeamModal = ({ isModalVisible, modalHideShow, getUsers }) => {
  const [form] = Form.useForm();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isModalVisible) {
      form.resetFields();
      setName("");
      setEmail("");
      setPhone("");
    }
  }, [isModalVisible, form]);

  const handleAdd = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        submitData();
      })
      .catch((errorInfo) => {
        toast.error("Please fill all fields");
      });
  };

  const submitData = async () => {
    let formData = new FormData();
    // remove douple spaces from name
    let full_name = name.replace(/\s{2,}/g, " ").trim();
    if (full_name.split(" ").length === 3) {
      formData.append("first_name", full_name.split(" ")[0]);
      formData.append("last_name", full_name.split(" ")[2]);
      formData.append("middle_name", full_name.split(" ")[1]);
    } else if (full_name.split(" ").length === 2) {
      formData.append("first_name", full_name.split(" ")[0]);
      formData.append("last_name", full_name.split(" ")[1]);
    } else {
      formData.append("first_name", full_name);
    }
    formData.append("username", email);
    formData.append("mobile", phone);
    formData.append("location", "Location");

    try {
      const result = await myApi.post("api/auth/user/", formData);
      setLoading(false);
      toast.success(result.data.message);
      form.resetFields();
      getUsers();
      modalHideShow();
    } catch (error) {
      setLoading(false);
      const errorMessage = error.response?.data?.message;
      if (errorMessage) {
        errorMessage.map((items) => {
          if (items.username && items.username[0].includes("already exists")) {
            toast.error(
              "User with this email already exists. Please choose a different email.",
            );
          } else {
            toast.error(items.username[0]);
          }
        });
      }
    }
  };

  return (
    <div>
      <Modal
        style={{ marginTop: "50px" }}
        title="Add Team Members"
        visible={isModalVisible}
        onCancel={modalHideShow}
        footer={[
          <Button key="cancel" onClick={modalHideShow} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key="add"
            type="primary"
            onClick={handleAdd}
            loading={loading}
          >
            Add
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Name*"
            name="name"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Please enter a name",
              },
            ]}
          >
            <Input onChange={(e) => setName(e.target.value)} />
          </Form.Item>

          <Form.Item
            label="Email*"
            name="email"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Please enter an email",
              },
              {
                type: "email",
                message: "Invalid email format",
              },
            ]}
          >
            <Input onChange={(e) => setEmail(e.target.value)} />
          </Form.Item>

          <Form.Item
            label="Phone*"
            placeholder="(123) 345-6789"
            name="phone"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Phone number is required!",
              },
              {
                validator(_, value) {
                  const phoneMaskPattern = /\(\d{3}\) \d{3}-\d{4}/;
                  if (!value || phoneMaskPattern.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Please enter a valid Phone Number!");
                },
              },
            ]}
          >
            <InputMask
              mask="(999) 999-9999" // Define the mask format for a US phone number
              maskChar=""
              placeholder="(123) 345-6789"
              onChange={(e) => setPhone(e.target.value)}
            >
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddTeamModal;
