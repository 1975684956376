import React from "react";
import "./ResendPassword.css";
import mail from "../RegisterComplete/Assets/mail.png";
import axios from "axios";
import toast from "react-hot-toast";
import { Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../common/axiosInstance";
const ResendPassword = ({ email }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const resend = async () => {
    setLoading(true);

    try {
      await axiosInstance.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/auth/password_reset/request/`,
        { username: email },
      );
      toast.success("Password reset request sent successfully");
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        // Navigate to the server error page
        navigate("/server-error");
      } else {
        let errorMessage = error?.response?.data?.message;
        errorMessage = errorMessage?.errors
          ? errorMessage?.errors[0]
          : String(errorMessage);
        toast.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="main-container">
      <div className="box">
        <div className="rectangle">
          <div className="centered-content">
            <img src={mail} alt="img" />
            <div className="label">
              <div>
                <h1 className="success-heading">Almost There!</h1>
              </div>
              {/* <div className="resend-para"> */}
              <p className="resend-password-paragraph">
                We have sent the password reset instructions to your registered
                email ID.
              </p>
              {/* </div> */}
            </div>
            <br />
            <hr style={{ marginTop: "1.5rem", color: "#5A607F" }} />
            <div className="not-recieve-email-text">
              <p>Didn’t receive any email?</p>
              <div style={{ marginTop: "1.4rem", height: "70px" }}>
                <button
                  type="primary"
                  className="resend-code"
                  loading={loading}
                  onClick={resend}
                >
                  {loading ? "loading..." : "Resend email"}
                </button>
                {/* <Button
                  type="primary"
                  className="resend-code"
                  loading={loading}
                  onClick={resend}
                >
                  {loading ? null : "Resend email"}
                </Button> */}
              </div>
              <p className="back-to-login-text">
                Back to log in page?{" "}
                <Link
                  to="/login"
                  style={{
                    color: "#0499CB",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  Back now
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResendPassword;
