import React, { useState, useEffect } from "react";
import { Typography, Card, Select } from "antd";

import { getEventTypes } from "../../../services/events";

import "../EndUserStyles.scss";

const SelectType = ({ eventType, setEventType }) => {
  const { Title } = Typography;
  const [loading, setLoading] = useState(false);
  const [eventTypes, setEventTypes] = useState([]);

  const fetchEventTypes = async () => {
    setLoading(true);
    try {
      const res = await getEventTypes();
      const data = res?.data?.data?.map((type) => ({
        label: type.name,
        value: type.id,
      }));
      setEventTypes(data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEventTypes();
  }, []);

  return (
    <Card className="booking-card">
      <Title level={4}>Event Type</Title>
      <Select
        size="large"
        placeholder="select"
        style={{ width: "100%" }}
        options={eventTypes}
        loading={loading}
        value={eventType}
        onChange={(val) => setEventType(val)}
        onDropdownVisibleChange={(open) => {
          if (open) {
            !eventTypes?.length && fetchEventTypes();
          }
        }}
      />
    </Card>
  );
};

export default SelectType;
