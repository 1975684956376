import React, { useState, useEffect } from "react";
import { Upload, message, Button, Row, Col, Typography } from "antd";
import { Images } from "../../services/images";
import usePopup from "../../common/MessageModal/usePopup";

import { ReactComponent as ImageIcon } from "../../../src/assets/icons/image.svg";
import { ReactComponent as EditIcon } from "../../../src/assets/icons/fill-pencil.svg";
import { ReactComponent as DeleteIcon } from "../../../src/assets/icons/trash.svg";

import styles from "./imageUploader.module.scss";

const ImageUploader = ({ output, revalidate, previous_images, max_length }) => {
  const popup = usePopup();
  const { Text } = Typography;
  const [files, setFiles] = useState([]);
  const [replaceTarget, setReplaceTarget] = useState("");
  const [oldImages, setOldImages] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [replaceLoading, setReplaceLoading] = useState(false);

  useEffect(() => {
    setOldImages(previous_images);
  }, [previous_images]);

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  const beforeUpload = (file) => {
    const isLtMaxSize = file.size / 1024 / 1024 < 2;
    if (!isLtMaxSize) {
      message.error(
        `Can't upload "${truncateString(file.name, 30)}". File must be smaller than 2MB!`,
      );
    }

    return isLtMaxSize;
  };

  const props = {
    name: "file",
    multiple: true,
    maxCount: oldImages
      ? (max_length || 5) - Number(oldImages?.length)
      : max_length || 5,
    accept: ".jpg, .jpeg, .png, .gif",
    defaultFileList: files,
    fileList: files,
    beforeUpload,
    customRequest: dummyRequest,
    onChange(info) {
      if (info.file.status === "uploading") {
        setFiles(info.fileList);
      } else if (info.file.status === "done") {
        setFiles(info.fileList);
        message.success(
          `${truncateString(info.file.name, 30)} file uploaded successfully`,
        );
      } else if (info.file.status === "error") {
        message.error(
          `${truncateString(info.file.name, 30)} file upload failed.`,
        );
      } else if (info.file.status === "removed") {
        setFiles(info.fileList);
        message.error(`${truncateString(info.file.name, 30)} file removed.`);
      }
    },
  };

  useEffect(() => {
    if (files?.length) {
      output(files);
    }
  }, [files]);

  // delete image from server image
  const handleDelete = async (id) => {
    setDeleteLoading(true);
    try {
      const res = await Images.delete(id);
      popup.success("Success!", res?.data?.message);
      revalidate();
      const filtered = oldImages?.filter((img) => img.id !== id);
      setOldImages(filtered);
    } catch (err) {
      popup.error("Oops...", err?.response?.data?.message);
    } finally {
      setDeleteLoading(false);
    }
  };
  // delete new local uploaded image
  const deleteFilefromList = (id) => {
    const filtered = files?.filter((file) => file?.uid !== id);
    setFiles(filtered);
  };

  // Replace image

  // delete image from server image
  const replaceServerFile = async (id, image) => {
    setReplaceLoading(id);

    const formData = new FormData();
    formData.append("image", image);

    try {
      const res = await Images.update(id, formData);
      popup.success("Success!", res?.data?.message);
      revalidate();
      replaceServerFileUrl(id, res?.data?.data);
    } catch (err) {
      popup.error("Oops...", err?.response?.data?.message);
    } finally {
      setReplaceLoading(false);
    }
  };

  const replaceServerFileUrl = (id, url) => {
    const updatedObjects = oldImages?.map((obj) =>
      obj.id === id ? { ...obj, image: url } : obj,
    );
    setOldImages(updatedObjects);
  };

  const replaceLocalFile = (targetId, newObject) => {
    setFiles((prev) => {
      const updatedObjects = prev.filter((obj) => obj.uid !== targetId);
      return updatedObjects?.length
        ? [...updatedObjects, newObject]
        : [newObject];
    });
    setReplaceTarget("");
  };

  const replace_props = {
    name: "file",
    multiple: false,
    accept: ".jpg, .jpeg, .png, .gif",
    customRequest: dummyRequest,
    onChange(info) {
      if (info.file.status === "uploading") {
        if (replaceTarget?.type === "server") {
          replaceServerFile(
            replaceTarget?.id,
            info.fileList[info.fileList.length - 1]?.originFileObj,
          );
        } else {
          replaceLocalFile(
            replaceTarget,
            info.fileList[info.fileList.length - 1],
          );
          message.success(
            `${truncateString(info.file.name, 30)} file replaced successfully`,
          );
        }
      } else if (info.file.status === "error") {
        message.error(
          `${truncateString(info.file.name, 30)} file upload failed.`,
        );
      } else if (info.file.status === "removed") {
        message.error(`${truncateString(info.file.name, 30)} file removed.`);
      }
    },
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        {/* Old images */}
        {oldImages?.length
          ? oldImages.map((file, i) => (
              <Col key={i} span={24} sm={12}>
                <div className={styles.uploadedImage}>
                  <img
                    key={file?.id}
                    src={file?.image}
                    alt={file?.image_name}
                  />
                  <div className={styles.actions}>
                    <Upload
                      {...replace_props}
                      showUploadList={false}
                      onClick={() =>
                        setReplaceTarget({ id: file?.id, type: "server" })
                      }
                    >
                      <Button
                        type="primary"
                        size="large"
                        shape="circle"
                        loading={replaceLoading === file.id}
                        icon={<EditIcon />}
                      />
                    </Upload>
                    <Button
                      type="primary"
                      size="large"
                      icon={<DeleteIcon />}
                      shape="circle"
                      loading={deleteLoading}
                      danger
                      onClick={() => {
                        popup.confirm(
                          "Delete Image",
                          "Are you sure? This action is permanent and cannot be undone.",
                          () => handleDelete(file?.id),
                          true,
                        );
                      }}
                    />
                  </div>
                </div>
              </Col>
            ))
          : null}

        {/* New images */}
        {files?.length
          ? files?.map((file, i) => (
              <Col key={i} span={24} sm={12}>
                <div className={styles.uploadedImage}>
                  <img
                    key={file.uid}
                    src={URL.createObjectURL(file?.originFileObj)}
                    alt={file?.name}
                  />
                  <div className={styles.actions}>
                    <Upload
                      {...replace_props}
                      showUploadList={false}
                      onClick={() => setReplaceTarget(file?.uid)}
                    >
                      <Button
                        type="primary"
                        size="large"
                        shape="circle"
                        icon={<EditIcon />}
                      />
                    </Upload>
                    <Button
                      type="primary"
                      size="large"
                      icon={<DeleteIcon />}
                      shape="circle"
                      danger
                      onClick={() => {
                        popup.confirm(
                          "Delete Image",
                          "Are you sure? This action is permanent and cannot be undone.",
                          () => deleteFilefromList(file?.uid),
                          true,
                        );
                      }}
                    />
                  </div>
                </div>
              </Col>
            ))
          : null}

        {Number((files?.length || 0) + (oldImages?.length || 0)) <
        (max_length || 5) ? (
          <Col span={24} xs={12}>
            <Upload {...props} showUploadList={false}>
              <div className={styles.addImage}>
                <ImageIcon />
                <p>Click to add a image or multiple image</p>
                <span>Add Image</span>
              </div>
            </Upload>
          </Col>
        ) : max_length < 3 ? null : (
          <Col span={24} xs={24}>
            <Text type="danger" style={{ fontSize: 12 }}>
              Max 5 images are allowd to be upload.
            </Text>
          </Col>
        )}
      </Row>
    </>
  );
};

export default ImageUploader;
