import React, { useState, useEffect } from "react";
import { Card, Typography, Button, Empty, Row, Col, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import Loader from "../../common/Loader";
import RoomCard from "./comps/RoomCard";
import RoomForm from "./comps/forms/RoomForm";
import { getRooms } from "../../services/rooms";
import usePopup from "../../common/MessageModal/usePopup";

import "./styles/venue-details.scss";

const Rooms = ({ venue_id }) => {
  const popup = usePopup();
  const { Title } = Typography;
  const [loading, setLoading] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [addRoom, setAddRoom] = useState(false);

  const fetchRooms = async () => {
    setLoading(true);
    try {
      const res = await getRooms(venue_id);
      setRooms(res?.data?.results);
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = "/server-error";
        console.log("Redirecting to server error page...");
      } else {
        popup.error("Oops...", error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (venue_id) {
      fetchRooms();
    }
  }, [venue_id]);

  return (
    <Card className="sub-section-card">
      <div className="section-header">
        <Title level={4}>Rooms</Title>
        <Button
          size="small"
          type="text"
          icon={<PlusOutlined />}
          className="add-room"
          disabled={!venue_id}
          onClick={() => setAddRoom(true)}
        >
          Add Room
        </Button>
      </div>
      {!rooms?.length && loading ? (
        <Loader minHeight={200} />
      ) : (
        <Spin spinning={loading}>
          <Row gutter={[24, 24]} style={{ minHeight: 200 }}>
            {rooms?.length ? (
              rooms?.map((item, i) => (
                <Col key={i} span={24} md={12} xl={8} xxl={6}>
                  <RoomCard room={item} fetchRooms={fetchRooms} />
                </Col>
              ))
            ) : (
              <Col span={24}>
                <Empty description="No Rooms" />
              </Col>
            )}
          </Row>
        </Spin>
      )}
      <RoomForm
        fetchRooms={fetchRooms}
        open={addRoom}
        venue_id={venue_id}
        handelClose={() => setAddRoom(false)}
      />
    </Card>
  );
};

export default Rooms;
