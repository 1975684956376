import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import {
  Typography,
  Card,
  DatePicker,
  Divider,
  InputNumber,
  TimePicker,
} from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { getEventAvailability } from "../../../services/events";

import "../EndUserStyles.scss";

const DateTime = ({
  startTime,
  setStartTime,
  validateFields,
  dateDuration,
  setDateDuration,
  venue_id,
}) => {
  const { MonthPicker } = DatePicker;

  const { Title, Text } = Typography;
  const [month, setMonth] = useState(dayjs().format("YYYY-MM"));
  const [days, setDays] = useState([]);
  const [eventAvailability, setEventAvailability] = useState([]);

  function disabledPastMonths(current) {
    const currentDate = new Date();
    return current && current.isBefore(currentDate, "month");
  }

  const checkEventAvailability = async () => {
    try {
      const res = await getEventAvailability(venue_id);
      setEventAvailability(res?.data?.data);
    } catch (err) {
      const errorCode = err?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = "/server-error";
        console.log("Redirecting to server error page...");
      } else {
        console.error("Error: ", err);
      }
    }
  };

  useEffect(() => {
    checkEventAvailability();
  }, []);

  const disabledStartTime = (now) => {
    return {
      disabledHours: () => {
        const selectedDate = dateDuration.event_date;
        const bookedEvent = eventAvailability?.booked_events?.find(
          (item) => setStartTime && item.date === selectedDate,
        );

        const filterFunction = (val) => {
          if (bookedEvent) {
            const startTime = dayjs(bookedEvent.start_time, "hh:mm:ss").hour();
            const endTime = dayjs(bookedEvent.end_time, "hh:mm:ss").hour();
            const hide = Boolean(
              val >= Number(startTime) - 1 && val <= Number(endTime) + 1,
            );
            return hide;
          }
          return false;
        };

        return Array.from({ length: 24 }, (_, index) => index).filter(
          filterFunction,
        );
      },
    };
  };

  useEffect(() => {
    const selectedMonth = dayjs(month);
    const daysInMonth = selectedMonth.daysInMonth();
    const daysArray = [];
    for (let day = 1; day <= daysInMonth; day++) {
      const date = selectedMonth.date(day);
      const dayObject = {
        day: date.format("DD"),
        day_name: date.format("ddd"),
        full_date: date.format("YYYY-MM-DD"),
      };
      daysArray.push(dayObject);
    }
    setDays(daysArray);
  }, [month]);

  const checkAvailability = (date) => {
    const currentDate = dayjs();
    const dateToCheckObj = dayjs(date);
    const isPassed = dateToCheckObj.isBefore(currentDate, "day");
    return Boolean(isPassed);
  };

  const checkStartTime = (value) => {
    const selectedDate = dateDuration?.event_date
      ? dayjs(dateDuration?.event_date, "YYYY-MM-DD")
      : "";
    const bookedEvent = eventAvailability?.booked_events?.find(
      (item) => selectedDate && item.date === selectedDate.format("YYYY-MM-DD"),
    );
    const startTime = dayjs(bookedEvent?.start_time, "hh:mm:ss");
    const endTime = dayjs(bookedEvent?.end_time, "hh:mm:ss");
    const currentTime = dayjs(value);

    const oneHourBefore = startTime.subtract(1, "hour");
    const oneHourAfter = endTime.add(29, "minute");
    const isWithinOneHour =
      currentTime.isAfter(oneHourBefore) && currentTime.isBefore(oneHourAfter);

    if (isWithinOneHour) {
      return false;
    } else {
      return true;
    }
  };

  const checkEndTime = (value) => {
    const selectedDate = dateDuration?.event_date
      ? dayjs(dateDuration?.event_date, "YYYY-MM-DD")
      : "";
    const bookedEvent = eventAvailability?.booked_events?.find(
      (item) => selectedDate && item.date === selectedDate.format("YYYY-MM-DD"),
    );
    const eStartTime = dayjs(bookedEvent?.start_time, "HH:mm:ss");
    const endTime = dayjs(bookedEvent?.end_time, "HH:mm:ss");
    const currentStartTime = dayjs(startTime, "HH:mm:ss");
    const currentTimeWidthSec = dayjs(value);
    const currentTime = currentTimeWidthSec.startOf("minute");
    const oneHourBefore = eStartTime?.add(1, "minute");
    const oneHourAfter = endTime?.add(29, "minute");
    const isWithinOneHour =
      (currentTime.isAfter(oneHourBefore) &&
        currentTime.isBefore(oneHourAfter)) ||
      (!currentStartTime.isAfter(oneHourAfter) &&
        !currentTime.isSame(startTime) &&
        currentTime.isAfter(startTime));

    if (isWithinOneHour) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Card className="booking-card data-time-card">
      <div className="inner-body">
        <div className="date-picker-header">
          <Title level={4}>Select Date</Title>
          <MonthPicker
            value={dayjs(month)}
            disabledDate={disabledPastMonths}
            onChange={(date) => {
              setMonth(dayjs(date).format("YYYY-MM"));
              setDateDuration((prev) => ({ ...prev, event_date: "" }));
            }}
            format={"MMMM YYYY"}
            bordered={false}
            suffixIcon={<CaretDownOutlined />}
          />
        </div>
        <div className="days-wrapper">
          {days?.length
            ? days.map((day, i) =>
                !checkAvailability(day?.full_date) ? (
                  <div
                    key={i}
                    className={`day ${
                      day.full_date === dateDuration?.event_date
                        ? "active"
                        : null
                    } ${
                      eventAvailability?.disable?.includes(day?.full_date) &&
                      "disabled"
                    }`}
                    onClick={() =>
                      setDateDuration((prev) => ({
                        ...prev,
                        event_date: day.full_date,
                      }))
                    }
                  >
                    <Text type="secondary">{day?.day_name}</Text>
                    <Text>{day.day}</Text>
                  </div>
                ) : null,
              )
            : null}
        </div>
      </div>
      <Divider />
      <div className="inner-body">
        <Title level={4}>Select Time</Title>
        <div className="time-wrapper">
          <TimePicker
            value={startTime ? dayjs(startTime, "HH:mm:ss") : ""}
            size="large"
            showNow={false}
            style={{
              border:
                startTime === "" && validateFields === true
                  ? "1px solid red"
                  : null,
              width: "100%",
              padding: "10px 10px",
            }}
            format="hh:mm A"
            onChange={(time, timeString) => {
              setStartTime(null);
              if (checkStartTime(time)) {
                if (time && !timeString.includes(":")) {
                  const newTime = time.clone();
                  newTime.minutes(0);
                  setStartTime(newTime.format("HH:mm:ss"));
                } else {
                  setStartTime(time ? time.format("HH:mm:ss") : "");
                }
              } else {
                toast.error("This time slot is not avaliable!");
                setStartTime(null);
              }
            }}
          />
        </div>
      </div>
      <Divider />
      <div className="inner-body">
        <Title level={4}>Select Duration</Title>
        <div className="time-wrapper">
          {["01", "02", "03", "04", "05", "06"].map((time, i) => (
            <div
              key={i}
              className={`time ${
                time == dateDuration?.duration_minutes / 60 ? "active" : null
              }`}
              onClick={() => {
                const timeUpdated = dayjs(startTime, "HH:mm:ss").add(
                  Number(time),
                  "hour",
                );
                const midnight = dayjs().endOf("day");
                const nextDay = timeUpdated.isAfter(midnight);
                if (checkEndTime(timeUpdated) && !nextDay) {
                  setDateDuration((prev) => ({
                    ...prev,
                    duration_minutes: time * 60,
                  }));
                } else {
                  toast.error("This time slot is not avaliable!");
                  setDateDuration((prev) => ({
                    ...prev,
                    duration_minutes: "",
                  }));
                }
              }}
            >
              <Text>{time}</Text>
              <Text type="secondary">{i > 0 ? "HOURS" : "HOUR"}</Text>
            </div>
          ))}
          <div className="custom-wrapper">
            <Text>Custom</Text>
            <InputNumber
              value={dateDuration?.duration_minutes / 60 || ""}
              onChange={(time) => {
                const timeUpdated = dayjs(startTime, "HH:mm:ss").add(
                  Number(time),
                  "hour",
                );
                const midnight = dayjs().endOf("day");
                const nextDay = timeUpdated.isAfter(midnight);
                if (checkEndTime(timeUpdated) && !nextDay) {
                  setDateDuration((prev) => ({
                    ...prev,
                    duration_minutes: time * 60,
                  }));
                } else {
                  toast.error("This time slot is not avaliable!");
                  setDateDuration((prev) => ({
                    ...prev,
                    duration_minutes: "",
                  }));
                }
              }}
              type="number"
              min={1}
              max={8}
              placeholder="00 HOURS"
              size="large"
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default DateTime;
