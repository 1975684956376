import React, { useState } from "react";
import "./Header.scss";
import { Space, Select, Button, Input, Badge } from "antd";
import { ReactComponent as Logo } from "../../../assets/Header/planspace-logo.svg";
import { ReactComponent as NotificationLogo } from "../../../assets/Header/notification-logo.svg";
import { ReactComponent as LocIcon } from "../../../assets/location-dropdown-icon.svg";
import { ReactComponent as SearchIcon } from "../../../assets/SearchIcon.svg";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setLocation } from "../../../store/Location/LocationSlice";

const Header = () => {
  const locations = useSelector((state) => state.location.locations);
  const dispatch = useDispatch();
  const [currentLocation, setCurrentLocation] = useState(locations[0]?.name);
  const navigate = useNavigate(true);

  return (
    <>
      <Space className="header-wrapper">
        <div className="logo">
          <Logo />
        </div>
        <div className="search" style={{ width: "600px" }}>
          <Input
            placeholder="Search"
            suffix={<SearchIcon />}
            size="large"
            style={{
              width: 400,
            }}
          />
        </div>
        <div className="right-items">
          <div className="drop-down">
            <Select
              value={currentLocation ? currentLocation : locations[0]?.name}
              style={{ width: 220 }}
              className="header-dropdown"
              size="large"
              onChange={(value) => {
                setCurrentLocation(value);
                dispatch(
                  setLocation(locations?.find((item) => item.id === value)),
                );
              }}
              popupClassName="header-dropdown-popup"
              dropdownStyle={{
                backgroundColor: "#eaedf4",
                alignItems: "center",
              }}
              options={locations.map((location) => {
                return {
                  value: location?.id,
                  label: (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      {" "}
                      <LocIcon /> &nbsp; <span>{location.name}</span>{" "}
                    </span>
                  ),
                };
              })}
            />
          </div>
          <div className="notifications">
            <Badge count={5}>
              <NotificationLogo />
            </Badge>
          </div>
          <div className="buttons">
            <Button className="btn-event" size="large">
              New Event
            </Button>
          </div>
        </div>
      </Space>
    </>
  );
};

export default Header;
