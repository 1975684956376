import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { Elements, EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Typography, Button, Row, Col, Form, Card, Avatar, Image, Checkbox, Divider, Modal } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { deleteEventAsGuest, getIntigrationDetails, getIntigrationEventType } from "../../services/integrations";
import Loader from "../../common/Loader";
import logo from "../../assets/Header/planspace-logo.svg";
import HomeImga1 from "../../assets/HomeImga1.jpg"
import endUserCoverimage from "../../assets/endUserCoverimage.jpg"
import dummyPhoto from "../Integrations/Assets/photo.png";
import Payments from "./comps/Payments";

import "./EndUserStyles.scss";

import SelectType from "./comps/SelectType";
import Rooms from "./comps/Rooms";
import DateTime from "./comps/DateTime";
import Packages from "./comps/Packages";
import Services from "./comps/Services";
import TransactionDetail from "./comps/TransactionDetail";
import PaymentForm from "./comps/PaymentForm";
import ContactInfoForm from "./comps/ContactInfoForm";
import GenerelInfoform from './comps/GenerelInfoform'
import VenueDetails from "./comps/VenueDetails";
import EndUserHeader from "./comps/EndUserHeader";
import EventInfo from './comps/EventInfo'
import { createEndUserEvent } from "../../services/integrations";
import usePopup from "../../common/MessageModal/usePopup";
import { duration } from "moment";

import dayjs from "dayjs";
import { Box } from "@mui/material";
import { createCheckoutSession, createCheckoutSessionAsGuest } from "../../services/events";
import CartDetails from "./comps/CartDetails";
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const { Title, Paragraph, Text, Link } = Typography;

const EndUserFlow = () => {
  const popup = usePopup();
  const [form] = Form.useForm();
  const { Title, Text } = Typography;
  const [loading, setLoading] = useState(false);
  const [paymentContact, setPaymentContact] = useState(false);
  const [details, setDetails] = useState([]);
  const [eventType, setEventType] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [eventStartTime, setEventStartTime] = useState('');
  const [eventEndTime, setEventEndTime] = useState('');
  const [eventGuestCount, setEventGuestCount] = useState('');
  const [selectEventDate, setEventDate] = useState('');

  const [room, setRoom] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [services, setServices] = useState([]);

  const [stripePromise, setStripePromise] = useState(null);
  const [payments, setPayments] = useState(null);
  const [pricingModel, setPricingModel] = useState({});
  const [paymentInfo, setPaymentInfo] = useState({
    is_partial_allowed: false,
    payment_method_id: "",
  });
  const [createPaymentId, setCreatePaymentId] = useState(0);
  const [dateDuration, setDateDuration] = useState({
    duration_minutes: "20",
    event_date: selectEventDate,
  });
  const [startTime, setStartTime] = useState("");
  const [validateFields, setValidateFields] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");

  const [submitLoading, setSubmitLoading] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState(null);
  const [paymentDue, setPaymentDue] = React.useState(
    selectedPackage?.total_price,
  );

  const [filteredServices, setFilteredServices] = useState([]);
  const [additionalServices, setAdditionalServices] = React.useState([]);
  const [paymentDue1, setPaymentDue1] = React.useState(null);
  const [paymentDue2, setPaymentDue2] = React.useState(null);
  const [clientSecret, setClientSecret] = React.useState(null);
  const [openCheckout, setOpenCheckout] = React.useState(false);
  const [eventID, setEventID] = React.useState(null);

  // console.log(filteredServices)

  useEffect(() => {
    fetchDetails();
  }, []);
  const fetchEventTypes = async () => {
    const url = window.location.href;
    const res = await getIntigrationEventType(url)
    setEventType(res.data.results)
  }
  const fetchDetails = async () => {
    setLoading(true);
    try {
      const url = window.location.href;
      const res = await getIntigrationDetails(url);
      setDetails(res?.data?.data);
      console.log(res.data?.data, "get details ");

      fetchEventTypes();
      setPricingModel({
        roomsPricingType: res.data?.data.rooms_pm || '',
        servicesPricingType: res.data?.data.addons_pm || '',
        packagesPricingType: res.data?.data.packages_pm || '',
      });
      setPaymentTerms(res.data?.data?.payment_terms);
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = "/server-error";
        console.log("Redirecting to server error page...");
      } else {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const moveToNextScreen = () => {
    if (room === "") {
      toast.error("Please choose a room ");
      setValidateFields(true);
    } else if (
      !dateDuration?.duration_minutes ||
      !dateDuration?.event_date ||
      startTime === ""
    ) {
      toast.error("Please select event date & duration!");
      setValidateFields(true);
    } else {
      setPaymentContact(true);
      setValidateFields(false);
    }
  };

  const handleOnComplete = () => {
    setOpenCheckout(false)
    // toast.success("Event created successfully");
    popup.success("Event created successfully");
    // form.resetFields();
    // setEventType("");
    // setRoom("");
    // setSelectedPackage("");
    // setPaymentContact(false);
    // setServices([]);
    // setPaymentInfo({
    //   is_partial_allowed: false,
    //   payment_method_id: "",
    // });
    // setCreatePaymentId(0);
    // setSubmitLoading(false);
    // setDateDuration({
    //   duration_minutes: "",
    //   event_date: "",
    // });
    window.location.reload();
  }

  const handelProceed = () => {
    if (!eventGuestCount || !eventStartTime || !eventEndTime || !selectEventDate || !selectedEvent) {
      popup.error("Please fill all required Event information.");
    } else {
      handleSubmit();
    }

    // if (cardHolderName === "" || paymentInfo.payment_method_id === "") {
    //   toast.error("Please enter complete card details!");
    // } else if (paymentInfo.payment_method_id) {
    //   form.submit();
    // } else {
    //   setCreatePaymentId((prev) => prev + 1);
    // }
  };

  const handleModalClose = async () => {
    await deleteEventAsGuest(eventID);
    setOpenCheckout(false);
  }

  const handleSubmit = async (values) => {
    // if (!paymentInfo.payment_method_id) {
    //   handelProceed();
    //   return;
    // }
    const payload = {
      generated_url: window.location.href.replaceAll("%20", " "),
      num_of_guests: eventGuestCount,
      start_time: eventStartTime,
      end_time: eventEndTime,
      event_date: selectEventDate,
      location: details?.location?.id,
      event_type: selectedEvent,
      chosen_package: selectedPackage?.length ? selectedPackage.map((item) => item.id) : [],
      ...paymentInfo,
      rooms: room?.length ? room.map((item) => item.id) : [],
      addons: services?.length ? services.map((item) => item.id) : [],
      // ...dateDuration,
      num_of_installments: details?.payment_terms?.length,
      terms: details?.terms[0],
      // contact: values,
      status: "open",
      is_partial_allowed: paymentInfo?.is_partial_allowed,
    };
    setSubmitLoading(true);
    const res = await createEndUserEvent(payload).then((response) => {
      setClientSecret(response.data.data.client_secret);
      setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
        stripeAccount: response.data.data.stripe_account_id
      }));
      setEventID(response.data.data.id);
      setOpenCheckout(true);
    }).catch(error => {
      toast.error(JSON.stringify(error?.response?.data?.message));
    }).finally(() => {
      setSubmitLoading(false);
    })
  };

  const handlePartialPayment = () => {
    if (paymentInfo?.is_partial_allowed) {
      if (paymentTerms.length === 2) {
        setPaymentDue(
          (selectedPackage?.total_price +
            (additionalServices
              ? additionalServices.reduce((a, b) => a + b?.servicePrice, 0)
              : 0)) /
          2,
        );
        setPaymentDue1({
          amount: Math.floor(paymentDue / 2),
          date: new Date(
            dateDuration?.event_date?.setDate(
              dateDuration?.event_date?.$d?.getDate() -
              (paymentTerms[0]?.payment_day === 0
                ? paymentTerms[1]?.payment_day
                : paymentTerms[0]?.payment_day),
            ),
          ),
        });
      } else if (paymentTerms.length === 3) {
        let arr = paymentTerms.filter((item) => item?.payment_day !== 0);

        if (arr.length === 2) {
          setPaymentDue(
            (selectedPackage?.total_price +
              (additionalServices
                ? additionalServices.reduce((a, b) => a + b?.servicePrice, 0)
                : 0)) /
            3,
          );

          setPaymentDue2({
            amount1: Math.floor(paymentDue / 3),
            date1: new Date(
              dateDuration?.event_date?.setDate(
                dateDuration?.event_date?.$d?.getDate() -
                (arr[0]?.payment_day > arr[1]?.payment_day
                  ? arr[0]?.payment_day
                  : arr[1]?.payment_day),
              ),
            ),
            amount2: Math.floor(paymentDue / 3),
            date2: new Date(
              dateDuration?.event_date?.setDate(
                dateDuration?.event_date?.$d?.getDate() -
                (arr[1]?.payment_day < arr[0]?.payment_day
                  ? arr[1]?.payment_day
                  : arr[0]?.payment_day),
              ),
            ),
          });
        } else {
          setPaymentDue(
            (selectedPackage?.total_price +
              (additionalServices
                ? additionalServices.reduce((a, b) => a + b?.servicePrice, 0)
                : 0)) /
            3,
          );

          setPaymentDue2({
            amount1: Math.floor(paymentDue / 3),
            date1: new Date(
              dateDuration?.event_date?.setDate(
                dateDuration?.event_date?.$d?.getDate() - 0,
              ),
            ),
            amount2: Math.floor(paymentDue / 3),
            date2: new Date(
              dateDuration?.event_date?.setDate(
                dateDuration?.event_date?.$d?.getDate() - arr[0].payment_day,
              ),
            ),
          });
        }
      }
    } else {
      setPaymentDue(
        selectedPackage?.total_price +
        (additionalServices
          ? additionalServices.reduce((a, b) => a + b?.servicePrice, 0)
          : 0),
      );
    }
  };

  if (loading) {
    return <Loader minHeight={600} />;
  }
  return (
    <div className="booking-wrapper">

      {!paymentContact && !loading && details ? (
        <>
          <EndUserHeader
            venueInfo={details}
          />
          <Row gutter={[16, 16]} style={{ padding: "10px" }}>
            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
              <VenueDetails
                venueInfo={details}
                StartTime={eventStartTime}
                EndTime={eventEndTime}
                eventGuestCount={eventGuestCount}
                selectEventDate={selectEventDate}
              />
            </Col>
            <Col xs={24} sm={24} md={18} lg={8} xl={13} className="form-integration">
              <Form
                className={"contact-form"}
                form={form}
                name="validateOnly"
                layout="vertical"
                autoComplete="off"
                onFinish={handleSubmit}
              >
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <EventInfo
                      venueInfo={details}
                      eventType={eventType}
                      setSelectedEvent={setSelectedEvent}
                      setEventStartTime={setEventStartTime}
                      setEventEndTime={setEventEndTime}
                      setEventGuestCount={setEventGuestCount}
                      setEventDate={setEventDate}
                    />
                  </Col>
                </Row>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Rooms
                      setRoom={setRoom}
                      validateFields={validateFields}
                      room={room}
                      rooms={details?.location?.rooms}
                      pricingModel={pricingModel?.roomsPricingType}
                    />
                  </Col>
                </Row>


                {/* <DateTime
              validateFields={validateFields}
              startTime={startTime}
              setStartTime={setStartTime}
              dateDuration={dateDuration}
              setDateDuration={setDateDuration}
              venue_id={details?.location?.id}
            /> */}
                <Packages
                  setSelectedPackage={setSelectedPackage}
                  value={selectedPackage}
                  packages={details?.packages}
                  setFilteredServices={setFilteredServices}
                  pricingModel={pricingModel?.packagesPricingType}
                />
                {/* services */}
                <Services
                  selectedPackage={selectedPackage}
                  filteredServices={filteredServices}
                  selected={services?.length ? services.map((item) => item.id) : []}
                  setSelected={setServices}
                  location={details?.location?.id}
                  AllServices={details?.addons}
                  pricingModel={pricingModel?.servicesPricingType}

                />
                {/* <Payments createdPayments={setPayments} /> */}
                {/* <Button
                  type="primary"
                  size="large"
                  style={{ marginBlock: 16, maxWidth: 320, marginInline: "auto" }}
                  block
                  onClick={moveToNextScreen}
                >
                  Next
                </Button> */}
                {/* </div> */}
              </Form>
            </Col>
            <Col xs={24} sm={24} md={4} lg={6} xl={6}>
              {/* <CartDetails createSession={createSession} /> */}
              <TransactionDetail
                paymentInfo={paymentInfo}
                paymentTerms={paymentTerms}
                dateDuration={dateDuration}
                selectedServices={services}
                selectedPackage={selectedPackage}
                selectedRooms={room}
                eventGuestCount={eventGuestCount}
                details={details}
                pricingModel={pricingModel}
                sebmitButton={(checked) => (
                  <Button
                  style={{marginTop:"8px"}}
                    loading={submitLoading}
                    disabled={!checked}
                    type="primary"
                    onClick={handelProceed}
                    block
                    size="large"
                  >
                    Proceed
                  </Button>
                )}
              />
            </Col>

          </Row>
        </>
      ) : (
        <>
          <div className="page-title">
            <Title level={3}>Kindly share a few more details</Title>
            <Text>Please fill in the details to confirm your order</Text>
          </div>

          <Form
            className={"contact-form"}
            form={form}
            name="validateOnly"
            layout="vertical"
            autoComplete="off"
            onFinish={handleSubmit}
          >
            <div className="content-wrapper pay-contact-wrapper">
              <Button
                icon={<ArrowLeftOutlined />}
                type="text"
                style={{
                  width: "fit-content",
                  marginBottom: "-16px",
                  marginLeft: "auto",
                  fontWeight: "bold",
                }}
                onClick={() => setPaymentContact(false)}
              >
                Back
              </Button>
              <Row gutter={[24, 24]}>
                <Col span={16}>
                  <Elements stripe={stripePromise}>
                    <PaymentForm
                      setCardHolderName={setCardHolderName}
                      cardHolderName={cardHolderName}
                      paymentTerms={paymentTerms}
                      details={details}
                      createPaymentId={createPaymentId}
                      setPaymentInfo={setPaymentInfo}
                      paymentInfo={paymentInfo}
                      form={form}
                      setSubmitLoading={setSubmitLoading}
                      submitLoading={submitLoading}
                      handlePartialPayment={handlePartialPayment}
                    />
                  </Elements>
                  <ContactInfoForm />
                </Col>
                {/* <Col span={8}>
                  <TransactionDetail
                    paymentInfo={paymentInfo}
                    paymentTerms={paymentTerms}
                    dateDuration={dateDuration}
                    selectedServices={services}
                    selectedPackage={
                      details?.packages?.find(
                        (item) => item.id === selectedPackage,
                      ) || null
                    }
                    details={details}
                    sebmitButton={(checked) => (
                      <Button
                        loading={submitLoading}
                        disabled={!checked}
                        type="primary"
                        onClick={handelProceed}
                        block
                        size="large"
                      >
                        Proceed
                      </Button>
                    )}
                  />
                </Col> */}
              </Row>
            </div>
          </Form>
        </>
      )}
      {openCheckout && (
        <Modal
          centered
          // title={"Payment"}
          open={true}
          onCancel={() => handleModalClose()}
          footer={[]}
          id="checkout"
        >
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
              clientSecret: clientSecret,
              onComplete: () => handleOnComplete(),
            }}
          >
            <EmbeddedCheckout id="card-element" />
          </EmbeddedCheckoutProvider>
        </Modal>
      )}
    </div>
  );
};

export default EndUserFlow;