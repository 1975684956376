import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import "./AddNewRoom.scss";

import { ReactComponent as CrossIcon } from "../../../../assets/Modals/CrossIcon.svg";
import { ReactComponent as BackArrow } from "../../../../assets/Modals/backArrow.svg";

import { Input, Select, Row, Col, Spin, message } from "antd";
import { Upload } from "antd";
import ImageUploader from "../../../../components/ImageUploader";
import MessageModal from "../MessageModal";
import client from "../../../../axios";

const AddNewRoom = ({
  open,
  setOpen,
  handleLocationModal,
  isDirect,
  setUploadImageURL,
  locationName,
  setLocationName,
  locationAddress,
  setLocationAddress,
  locationCity,
  file,
  setLocationCity,
  locationState,
  setLocationState,
  locationZipCode,
  setLocationZipCode,
  getVenues,
  phone,
  setPhone,
  email,
  setEmail,
  venueId,
  loactionImages,
  setLoactionImages,
}) => {
  // eslint-disable-next-line

  // Add new room form
  const [roomName, setRoomName] = useState("");
  const [price, setPrice] = useState("");
  const [maxGuest, setMaxGuest] = useState("");
  const [roomAmenities, setRoomAmenities] = useState([]);
  const [roomSpaces, setRoomSpaces] = useState([]);
  const [roomCeremonyTypes, setRoomCeremonyTypes] = useState([]);

  const [isfirstScreen, setIsFirstScreen] = useState(true);
  const [isSuccessModalOpen, setIsSuccessModelOpen] = useState(false);
  const [isSpin, setIsSpin] = useState(false);
  const [uploadRoomImageURL, setUploadRoomImageURL] = useState(false);
  const [RoomImageFile, setRoomImageFile] = useState(null);
  const [imageModal, setImageModal] = useState(false);
  const [images, setImages] = useState([]);

  const [isNameError, setIsNameError] = useState(false);
  const [isPriceError, setIsPriceError] = useState(false);
  const [isGuestError, setIsGuestError] = useState(false);

  const spaces = [
    { label: "Backyard", value: "Backyard" },
    { label: "Ballroom", value: "Ballroom" },
    { label: "Barn", value: "Barn" },
    { label: "Farm & Ranch", value: "Farm & Ranch" },
    { label: "Country Club", value: "Country Club" },
    { label: "Estate", value: "Estate" },
    { label: "Garden", value: "Garden" },
    { label: "Historic Venue", value: "Historic Venue" },
    { label: "Mountain", value: "Mountain" },
    { label: "Park", value: "Park" },
    { label: "Restaurant", value: "Restaurant" },
    { label: "Vineyard & Winery", value: "Vineyard & Winery" },
  ];

  const handleSpaces = (value) => {
    setRoomSpaces(value);
  };

  const amenities = [
    { label: "Tech Equipment & Support", value: "Tech Equipment & Support" },
    { label: "Valet Parking", value: "Valet Parking" },
    { label: "Shuttles", value: "Shuttles" },
    { label: "Ceremony Area", value: "Ceremony Area" },
    { label: "Covered Outdoors Space", value: "Covered Outdoors Space" },
    { label: "Bride Dressing Room", value: "Bride Dressing Room" },
    { label: "Groom Dressing Room", value: "Groom Dressing Room" },
    { label: "Handicap Accessible", value: "Handicap Accessible" },
    { label: "Indoor Event Space", value: "Indoor Event Space" },
    { label: "Liability Insurance", value: "Liability Insurance" },
    { label: "Outdoor Event Space", value: "Outdoor Event Space" },
    { label: "Reception Area", value: "Reception Area" },
    { label: "Wireless Internet", value: "Wireless Internet" },
    { label: "Civil Ceremony", value: "Civil Ceremony" },
    { label: "Religious Ceremony", value: "Religious Ceremony" },
    { label: "Renewal Ceremony", value: "Renewal Ceremony" },
    { label: "Destination Ceremony", value: "Destination Ceremony" },
  ];

  const handleAmenities = (value) => {
    setRoomAmenities(value);
  };

  useEffect(() => {
    if (open?.type === "edit") {
      setRoomName(open?.name);
      setPrice(open?.flat_price);
      setMaxGuest(open?.max_guests);
      setRoomAmenities(open?.amenities);
      setRoomSpaces(open?.spaces);
      setRoomCeremonyTypes(open?.ceremony_types);
      setImages(open?.images);
    }
  }, [open]);

  const ceremony_types = [
    { label: "Corporate Party", value: "Corporate Party" },
    { label: "General Party", value: "General Party" },
    { label: "Birthday", value: "Birthday" },
    { label: "Sweet Sixteen", value: "Sweet Sixteen" },
    { label: "Quinceañera", value: "Quinceañera" },
    { label: "Bar/Bat Mitzvah", value: "Bar/Bat Mitzvah" },
    { label: "Anniversary", value: "Anniversary" },
    { label: "Divorce", value: "Divorce" },
    { label: "Wedding", value: "Wedding" },
    { label: "Ethnic Wedding", value: "Ethnic Wedding" },
    { label: "Engagement", value: "Engagement" },
    { label: "Bachelor/Bachelorette", value: "Bachelor/Bachelorette" },
    { label: "Bridal Shower", value: "Bridal Shower" },
    { label: "Baby Shower", value: "Baby Shower" },
    { label: "Reunion", value: "Reunion" },
    { label: "Other", value: "Other" },
  ];

  const handleCeremonyTypes = (value) => {
    setRoomCeremonyTypes(value);
  };

  const dummyRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    customRequest: dummyRequest,
    onChange(info) {
      const { status } = info.file;

      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
        setRoomImageFile(info.file.originFileObj);
        setUploadRoomImageURL(URL.createObjectURL(info.file.originFileObj));
      } else if (status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },

    onDrop(e) {
      console.log("Dropped files", e);
    },
  };

  const submitLocationAndRoom = () => {
    try {
      setIsSpin(true);
      const formData = new FormData();
      if (file) {
        formData.append("image", file);
      }
      if (loactionImages.length > 0) {
        for (let i = 0; i < loactionImages.length; i++) {
          formData.append("images", loactionImages[i].originFileObj);
        }
      }
      formData.append("name", locationName);
      formData.append("address_line1", locationAddress);
      formData.append("city", locationCity);
      formData.append("state", locationState);
      formData.append("zip_code", locationZipCode);
      formData.append("phone", phone);
      formData.append("email", email);

      client
        .post(
          `${process.env.REACT_APP_BASE_API_URL}/api/company/location/`,
          formData,
        )
        .then((res) => {
          try {
            const formData = new FormData();
            formData.append("name", roomName);
            formData.append("flat_price", price);
            formData.append("max_guests", maxGuest);
            formData.append("spaces", JSON.stringify(roomSpaces));
            formData.append("location", res.data.data.id);
            formData.append("amenities", JSON.stringify(roomAmenities));
            formData.append(
              "ceremony_types",
              JSON.stringify(roomCeremonyTypes),
            );
            if (images.length > 0) {
              for (let i = 0; i < images.length; i++) {
                formData.append("images", images[i].originFileObj);
              }
            }
            setIsSuccessModelOpen(true);
            setIsSpin(false);
            getVenues();

            client
              .post(
                `${process.env.REACT_APP_BASE_API_URL}/api/company/room/`,
                formData,
              )
              .then((res) => {
                setImages([]);
                setIsSpin(false);
              })
              .catch((error) => {
                message.error(error?.response?.data?.message);
                setIsSpin(false);
              });
          } catch (error) {
            setIsSpin(false);
          }

          setOpen(false);
          setLocationName("");
          setLocationAddress("");
          setLocationCity("");
          setUploadImageURL(null);
          setLocationState("");
          setLocationZipCode("");
          setPhone("");
          setEmail("");
          setRoomName("");
          setPrice("");
          setMaxGuest("");
          setRoomAmenities("");
          setRoomSpaces("");
          setRoomCeremonyTypes("");
          setLoactionImages([]);
        })
        .catch((error) => {
          let formattedMessage = "";
          let errorMessage = error?.response?.data?.message;
          if (typeof errorMessage == "object" || typeof errorMessage == Array) {
            errorMessage.map((item) => {
              for (let key in item) {
                formattedMessage += key + ": " + item[key][0];
              }
            });
          } else if (typeof errorMessage == "string") {
            formattedMessage = errorMessage;
          }
          message.error(formattedMessage);
          setIsSpin(false);
        });
    } catch (error) {
      message.error(error?.response?.data?.message);
      setIsSpin(false);
    }
  };

  const addRoomToVenue = async () => {
    const formData = new FormData();
    setIsSpin(true);
    formData.append("name", roomName);
    formData.append("flat_price", price);
    formData.append("max_guests", maxGuest);
    formData.append("spaces", JSON.stringify(roomSpaces));
    formData.append("location", venueId);
    formData.append("amenities", JSON.stringify(roomAmenities));
    formData.append("ceremony_types", JSON.stringify(roomCeremonyTypes));
    if (RoomImageFile) {
      formData.append("image", RoomImageFile);
    }
    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images", images[i].originFileObj);
      }
    }
    try {
      if (open?.type === "edit") {
        await client.put(
          `${process.env.REACT_APP_BASE_API_URL}/api/company/room/${open?.id}/`,
          formData,
        );
      } else {
        await client.post(
          `${process.env.REACT_APP_BASE_API_URL}/api/company/room/`,
          formData,
        );
      }
      setIsSuccessModelOpen(true);
      setOpen(false);
      // reset values
      setRoomName("");
      setPrice("");
      setMaxGuest("");
      setRoomAmenities("");
      setRoomSpaces("");
      setRoomCeremonyTypes("");
      setRoomImageFile(null);
      setUploadRoomImageURL(null);
      getVenues();
      setLoactionImages([]);
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setIsSpin(false);
    }
  };

  return (
    <>
      <Modal
        title={null}
        centered
        open={open}
        onOk={() => setOpen(false)}
        footer={null}
        className="add-new-room"
        closable={false}
        onCancel={() => setOpen(false)}
      >
        <div className="header">
          <div className="header-left">
            <BackArrow
              onClick={() => {
                if (isDirect) {
                  setOpen(false);
                } else {
                  setOpen(false);
                  handleLocationModal(true);
                }
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
          <div className="header-center">Add New Room</div>
          <div className="header-right">
            <CrossIcon
              onClick={() => setOpen(false)}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </div>

        {isfirstScreen && (
          <Spin spinning={isSpin} delay={500}>
            <div className="content-edit-company-2">
              <div className="content-edit-company-input-wrapper">
                <Input
                  placeholder="Enter Room Name*"
                  size="large"
                  value={roomName}
                  onChange={(e) => setRoomName(e.target.value)}
                />
                {isNameError && (
                  <span style={{ color: "red" }}>Please enter room name</span>
                )}
              </div>

              <Row gutter={[16, 16]}>
                <Col md={12}>
                  <div className="content-edit-company-input-wrapper">
                    <Input
                      placeholder="Price*                       $0.00"
                      size="large"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                  {isPriceError && (
                    <span style={{ color: "red" }}>
                      Please enter room price
                    </span>
                  )}
                </Col>
                <Col md={12}>
                  <div className="content-edit-company-input-wrapper">
                    <Input
                      placeholder="Max Guest*                       0.0"
                      size="large"
                      value={maxGuest}
                      onChange={(e) => setMaxGuest(e.target.value)}
                    />
                    {isGuestError && (
                      <span style={{ color: "red" }}>
                        Please enter room guest
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                {images.length
                  ? images.map((file, i) => (
                      <Col sm={12} key={i}>
                        <div className="uploaded-image-wrapper">
                          <img
                            src={
                              file?.originFileObj
                                ? URL.createObjectURL(file.originFileObj)
                                : file
                            }
                            alt={file?.name ? file?.name : `image${i + 1}`}
                          />
                        </div>
                      </Col>
                    ))
                  : null}
                <Col sm={12}>
                  <div
                    className="add-new-img"
                    onClick={() => setImageModal(true)}
                  >
                    <span>Add Image</span>
                    <button>Add</button>
                  </div>
                  {imageModal ? (
                    <ImageUploader
                      open={imageModal}
                      setOpen={setImageModal}
                      images={images}
                      setImages={(images) => {
                        setImages(images);
                      }}
                    />
                  ) : null}
                </Col>
              </Row>
              <h4 className="text-heading">
                Select what kind of amenities you are offering
              </h4>
              <div className="content-edit-company-input-wrapper">
                <Select
                  value={roomAmenities}
                  mode="tags"
                  allowClear
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select"
                  onChange={handleAmenities}
                  options={amenities}
                />
              </div>
              <h4 className="text-heading">
                Select what kind of event type you are offering
              </h4>
              <div className="content-edit-company-input-wrapper">
                <Select
                  value={roomCeremonyTypes}
                  mode="tags"
                  allowClear
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select"
                  onChange={handleCeremonyTypes}
                  options={ceremony_types}
                />
              </div>
              <h4 className="text-heading">
                Select what kind of space you are offering
              </h4>
              <div className="content-edit-company-input-wrapper">
                <Select
                  value={roomSpaces}
                  mode="tags"
                  allowClear
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select"
                  onChange={handleSpaces}
                  options={spaces}
                />
              </div>
            </div>
          </Spin>
        )}

        <div className="footer">
          <div className="footer-left"></div>
          <div className="footer-center"></div>
          <div className="footer-right">
            <div className="back-btn">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  setOpen(false);
                  handleLocationModal(true);
                }}
              >
                Back
              </button>
            </div>
            <div className="next-btn">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  if (roomName && price && maxGuest) {
                    if (venueId) {
                      addRoomToVenue();
                    } else {
                      submitLocationAndRoom();
                    }
                  } else {
                    if (!roomName) {
                      setIsNameError(true);
                    }
                    if (!price) {
                      setIsPriceError(true);
                    }
                    if (!maxGuest) {
                      setIsGuestError(true);
                    }
                  }
                }}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <MessageModal
        open={isSuccessModalOpen}
        setOpen={setIsSuccessModelOpen}
        content="Your room and Location has been added successfully"
      />
    </>
  );
};
export default AddNewRoom;
