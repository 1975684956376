import React, { useState, useEffect } from "react";
import { States } from "countries-states-cities-service";
import { Select } from "antd";

export const validateStateInput = (_, value) => {
  const lettersOnlyRegex = /^[A-Za-z]+$/;
  if (value && !value.match(lettersOnlyRegex)) {
    return Promise.reject("Please enter only letters");
  }
  return Promise.resolve();
};

const StateAutoComplete = (props) => {
  const [stateDataSource, setStateDataSource] = useState([]);

  const handleStateSearch = (value) => {
    if (value.length) {
      const usStates =
        States.getStates({
          filters: {
            country_code: "US",
          },
        }) || [];
      const filteredStates = usStates?.filter((state) =>
        state?.state_code?.toLowerCase().includes(value.toLowerCase()),
      );
      const options = filteredStates.length
        ? filteredStates.map((state) => ({
            label: state.state_code,
            value: state.state_code,
          }))
        : [];
      setStateDataSource(options);
    } else {
      setStateDataSource([]);
    }
  };

  useEffect(() => {
    const usStates =
      States.getStates({
        filters: {
          country_code: "US",
        },
      }) || [];
    const options = usStates.length
      ? usStates.map((state) => ({
          label: state.state_code,
          value: state.state_code,
        }))
      : [];
    setStateDataSource(options);
  }, []);

  return (
    <Select
      size="large"
      showSearch
      options={stateDataSource}
      maxLength={2}
      // onSearch={handleStateSearch}
      placeholder="Select state name"
      className="state-field"
      {...props}
    />
  );
};

export default StateAutoComplete;
