import React, { useState, useEffect } from 'react';
import { Typography, Card, Space, Checkbox, Grid, Empty, Table, Switch } from "antd";
import { useSelector } from "react-redux";
import { getServices } from "../../../services/services";
import usePopup from "../../../common/MessageModal/usePopup";
import Loader from "../../../common/Loader";
import templateImage from "../../../assets/background.jpg";

const { useBreakpoint } = Grid;

const Services = ({ selectedServices, setSelectedServices, error, setErrors, setEnableServices, enableServices, pricingModel }) => {
    const { Title, Text } = Typography;
    const screens = useBreakpoint();
    const popup = usePopup();
    const current_venue = useSelector(
        (state) => state?.venues?.selectedVenue?.id,
    );

    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState([]);
    const [statusFilter, setStatusFilter] = useState("all");

    const fetchServices = async () => {
        setLoading(true);
        try {
            const res = await getServices({
                location: current_venue,
                is_active: statusFilter && statusFilter !== "all" ? statusFilter : "",
            });
            setServices(res?.data?.results);
            setSelectedServices(res?.data?.results.map(service => service.id));
        } catch (error) {
            const errorCode = error?.response?.status || 500;
            if (errorCode === 500 || errorCode === 503) {
                window.location.href = "/server-error";
                console.log("Redirecting to server error page...");
            } else {
                popup.error("Oops...", error?.response?.data?.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (current_venue) {
            fetchServices();
        }
    }, [pricingModel]);

    useEffect(() => {
        if (current_venue) {
            fetchServices();
        }
    }, [current_venue]);

    const handleServiceCheckboxChange = (service) => {
        const isSelected = selectedServices.includes(service.id);
        const updatedServices = isSelected
            ? selectedServices.filter(id => id !== service.id)
            : [...selectedServices, service.id];

        setSelectedServices(updatedServices);
        if (updatedServices.length === 0 || services.length === 0) {
            setErrors(prev => ({ ...prev, services: "Please select at least one service." }));
        } else {
            setErrors(prev => {
                const { services, ...rest } = prev;
                return rest;
            });
        }
    };

    const dataSource = services.map((item) => ({
        key: item.id,
        name: item?.name,
        description: item?.description,
        service: item,
        image: item?.images?.length ? item?.images[0]?.image : templateImage,
        price:  pricingModel === "PER_PERSON" ? `${item?.pp_price || "0"}` : `${item?.flat_price || "0"}`,
    }));

    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            width: 40,
            render: (text) => (
                <img
                    src={text}
                    alt="Service"
                    style={{ width: "50px", height: "50px", borderRadius: "5px", marginTop: "3px", opacity: enableServices ? '' : '0.4' }}
                />
            ),
        },
        {
            title: '',
            width: "50%",
            key: 'service',
            render: (text, record) => (
                <div style={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
                    <h4 style={{ margin: 0, color: enableServices ? 'black' : '#999' }}>{record.name}</h4>
                    <p style={{ margin: 0, color: enableServices ? 'black' : '#999' }}>{record.description}</p>
                </div>
            ),
        },
        {
            title: '',
            dataIndex: 'price',
            key: 'price',
            render: (text) => <h4 style={{ margin: 0, color: enableServices ? 'black' : '#999' }}>${text || 0}</h4>,
        },
        {
            title: 'Select',
            key: 'select',
            width: 40,
            render: (text, record) => (
                <Checkbox
                    checked={selectedServices.includes(record.key)}
                    onChange={() => handleServiceCheckboxChange(record.service)}
                    disabled={!enableServices}
                />
            ),
        },
    ];

       useEffect(() =>{
    if (services.length === 0) {
        setErrors(prev => ({  ...prev, services: "Please select at least one service."  }));
    }
    else {
        if (selectedServices.length === 0) {
            setErrors(prev => ({  ...prev, services: "Please select at least one service."  }));
        } else {
            setErrors(prev => {
                const { packages, ...rest } = prev;
                return rest;
            });
        }
    }
   } , [services, selectedServices])

    return (
        <div>
            <Card
                style={{
                    marginTop: "20px",
                    // borderColor: error && "#ff4d4f",
                    backgroundColor: enableServices ? "white" : "#f0f0f0", // Disabled background
                    color: enableServices ? "black" : "#999", // Disabled text color
                }}
            >
                <Space
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Title level={4} style={{ color: enableServices ? "black" : "#999" }}>
                        Services
                    </Title>
                    <Switch
                        style={{ marginRight: "10px" }}
                        checked={enableServices}
                        onChange={(checked) => setEnableServices(checked)}
                    />
                </Space>

                {/* {error ? (
                    <Text style={{ display: "block" }} type="danger">
                        {error}
                    </Text>
                ) : null} */}

                <div
                    className="events-details"
                    style={{ marginTop: screens.xs ? "10px" : "0" }}
                >
                    <p
                        className="event-head"
                        style={{ color: enableServices ? "#667085" : "#999", pointerEvents: "none" }}
                    >
                        Select Services to add
                    </p>
                </div>

                <div className="packages-services-card" style={{ height: "140px" }}>
                    <Card>
                        {loading && !services?.length ? (
                            <Loader minHeight={100} />
                        ) : services?.length ? (
                            <Table
                                scroll={{ x: 600 }}
                                dataSource={dataSource}
                                columns={columns}
                                pagination={false}
                                showHeader={false}
                                rowKey="key"
                                style={{ borderCollapse: "separate", borderSpacing: "0" }}
                            />
                        ) : !loading ? (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Packages" />
                        ) : null}
                    </Card>
                </div>
            </Card>

        </div>
    );
}

export default Services;