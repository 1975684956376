import {
  AppstoreFilled,
  UnorderedListOutlined,
  CalendarOutlined,
} from "@ant-design/icons";

export const filters = [
  {
    title: "All Events",
    value: "all",
  },
  {
    title: "Open",
    value: "open",
  },
  {
    title: "In Progress",
    value: "in_progress",
  },
  {
    title: "Booked",
    value: "booked",
  },
  {
    title: "Scheduled",
    value: "scheduled",
  },
  {
    title: "Completed",
    value: "completed",
  },
  {
    title: "Cancelled",
    value: "cancelled",
  },
];

export const layout = [
  {
    icon: <AppstoreFilled />,
    value: "grid",
  },
  {
    icon: <UnorderedListOutlined />,
    value: "list",
  },
  {
    icon: <CalendarOutlined />,
    value: "calendar",
  },
];
