import React from "react";
import "./errorPage.scss";

import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "antd";
import { MailOutlined } from "@ant-design/icons";
import logo from "../../assets/Header/planspace-logo.svg";
// assests
import errorImage from "./assets/404-illustration.png";

const ServerError = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="header-logo">
        <img src={logo} />
      </div>
      <div className="error-main-page">
        <Row>
          <Col span={24} lg={14}>
            <p className="error-sub-heading">500 Server Error</p>
            <p className="main-error-text">Internal Server Error</p>
            <p className="error-text" style={{ marginTop: "5px" }}>
              Sorry, Something went wrong on our side. Try again later.
            </p>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <Button className="error-back-btn" onClick={() => navigate(-1)}>
                Go Back
              </Button>
            </div>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <p className="error-check">or you can check</p>
              <div className="line-check" />
            </div>
            <a
              href="mailto:support@theplanspace.io"
              className="services-text"
              style={{ marginTop: "20px" }}
            >
              <Button className="error-homepage-btn" type="primary">
                Contact Us
              </Button>
            </a>
            <a
              href="mailto:support@theplanspace.io"
              className="services-text"
              style={{ marginTop: "5px" }}
            >
              <MailOutlined /> support@theplanspace.io
            </a>
            <p className="error-text">Our friendly team is here to help.</p>
            {/* 
            <p className="services-text">
            Contact: support@theplanspace.io <RightOutlined />
            </p>
            <p className="error-text">Check our services status.</p> */}
          </Col>
          <Col span={24} lg={10}>
            <img src={errorImage} style={{ width: "100%" }} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ServerError;
