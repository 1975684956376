import { useState } from "react";
import { Button, Card, Form, Input } from "antd";
import myApi from "./MyApi";
import AccountOnboarding from "./AccountOnboarding";
import toast from "react-hot-toast";
import { ConnectComponentsProvider } from "@stripe/react-connect-js";
import { loadConnectAndInitialize } from "@stripe/connect-js";

const Index = () => {
  const [email, setEmail] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [accountID, setAccountId] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchClientSecret = async (values) => {
    try {
      const response = await myApi.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/company/integration/stripe/`,
        { email: values.email }
      );
      setAccountId(response.data.data.account_id);
      return response.data.data.client_secret;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true); // Start loader
    try {
      const secret = loadConnectAndInitialize({
        publishableKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
        fetchClientSecret: () => fetchClientSecret(values),
      });

      if (secret) {
        setClientSecret(secret);
      } else {
        throw new Error("Failed to retrieve client secret");
      }
    } catch (error) {
      toast.error("Failed to load Stripe components.");
    } finally {
      setLoading(false); // Stop loader after processing is done
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  if (clientSecret && accountID) {
    return (
      <ConnectComponentsProvider connectInstance={clientSecret} children={null}>
        <AccountOnboarding accountID={accountID} />
      </ConnectComponentsProvider>
    );
  }

  return (
    <div>
      <Card style={{ padding: "60px 30px 60px 30px" }}>
        <h4 style={{ fontSize: '24px', fontWeight: '700', marginBottom: '20px', color: 'rgb(11, 152, 210)' }}>
          Create Your Stripe Account
        </h4>
        <div style={{ width: 300 }}>
          <Form onFinish={handleSubmit}>
            <label>Email<span style={{ color: "red" }}>*</span></label>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please enter your email" },
                { type: "email", message: "Please enter a valid email" },
              ]}
            >
              <Input placeholder="Email" onChange={handleEmailChange} value={email} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item style={{ textAlign: "right", fontWeight: "bold", marginLeft: "10px" }}>
              <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                Create
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default Index;
