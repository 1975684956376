import { IMAGES } from "./endpoints";
import client from "../axios";

class ImagesClass {
  // image delete
  async delete(id) {
    return await client.delete(IMAGES + `${id}/`);
  }
  // image update
  async update(id, payload) {
    return await client.put(IMAGES + `${id}/`, payload);
  }
}

export const Images = new ImagesClass();
