import React from "react";
import "./paymentIntegration.scss";
import { useNavigate } from "react-router";
import {
    ConnectAccountOnboarding,
} from "@stripe/react-connect-js";
import myApi from "./MyApi";

const AccountOnboarding = ({ accountID }) => {
    const navigate = useNavigate();

    const handleOnExit = () => {
        myApi.post(`${process.env.REACT_APP_BASE_API_URL}/api/company/integration/stripe/update_status/`, { stripe_account_id: accountID }).then((result) => {
            console.log("result", result);
            navigate("/integration/StripeInfo");
        });
    };

    return (
        <div>
            <ConnectAccountOnboarding
                onExit={handleOnExit}
            />
        </div>
    );
};

export default AccountOnboarding;
