import React from "react";
import dayjs from "dayjs";
import usePopup from "../../../common/MessageModal/usePopup";
import { UserOutlined, MoreOutlined } from "@ant-design/icons";
import { Typography, Avatar, Button, Dropdown, Space, Col } from "antd";
import { deleteEvent } from "../../../services/events";
import StagesDropdown from "./StagesDropdown";
import StatusDropdown from "./StatusDropdown";
import styles from "./comps.module.scss";
import { Link } from "react-router-dom";

const GridCard = ({ event, fetchEvents, setCreateNewEvent }) => {
  const popup = usePopup();
  const { Text, Title } = Typography;
  const {
    title,
    event_date,
    start_time,
    end_time,
    created_user,
    contacts,
    rooms,
    status,
  } = event;

  const eventDelete = async () => {
    try {
      const res = await deleteEvent(event.id);
      popup.success("Success!", res?.data?.message);
      fetchEvents();
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = "/server-error";
        console.log("Redirecting to server error page...");
      } else {
        popup.error("Oops...", error?.response?.data?.message);
      }
    }
  };

  const items = [
    {
      label: (
        <a onClick={() => setCreateNewEvent({ ...event, type: "edit" })}>
          Edit
        </a>
      ),
      key: 0,
    },
    {
      label: (
        <a
          onClick={() =>
            popup.confirm(
              "Delete " + event?.title,
              "Are you sure? This action is permanent and cannot be undone.",
              eventDelete,
            )
          }
        >
          Delete
        </a>
      ),
      key: 1,
    },
  ];

  return (
    <Col span={24} md={24} lg={12} xl={8}>
      <div className={`${styles.gridCard} ${styles[status]}`}>
        <div className={styles.header}>
          <Link to="/event/details" className={styles.eventTitle} state={event}>
            <Title level={5}>{title}</Title>
          </Link>
          <div className={styles.actions}>
            <StatusDropdown
              eventId={event?.id}
              value={event?.status}
              size="small"
              fetchEvents={fetchEvents}
            />
            <StagesDropdown
              event={event}
              value={event?.stage}
              size="small"
              fetchEvents={fetchEvents}
            />
            <Space>
              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
                placement="bottomRight"
              >
                <Button
                  type="text"
                  shape="circle"
                  icon={<MoreOutlined />}
                  size={24}
                  className={styles.more_button}
                />
              </Dropdown>
            </Space>
          </div>
        </div>

        <Link
          to="/event/details"
          state={event}
          style={{
            marginTop: "auto",
            marginBottom: 0,
            display: "flex",
            flexDirection: "column",
            gap: 24,
          }}
        >
          <div className={styles.details}>
            <div className={styles.labelWrapper}>
              <Text style={{ color: "#667085" }}>Event Date</Text>
              <Text style={{ fontWeight: 500, color: "#4A4C56" }}>
                {dayjs(event_date).format("DD MMM YYYY")}
              </Text>
            </div>
            <div className={styles.labelWrapper}>
              <Text style={{ color: "#667085" }}>Time</Text>
              <Text style={{ fontWeight: 500, color: "#4A4C56" }}>
                {start_time
                  ? dayjs(start_time, "hh:mm:ss").format("h:mm A")
                  : "-"}{" "}
                {start_time && end_time ? "To" : null}{" "}
                {end_time ? dayjs(end_time, "hh:mm:ss").format("h:mm A") : "-"}
              </Text>
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.organizer}>
              <Avatar size="medium" icon={<UserOutlined />} />
              <Text style={{ fontWeight: 500, fontSize: 14, color: "#4A4C56" }}>
                {contacts[0]?.full_name || "-"}
              </Text>
            </div>
            <Text
              style={{
                color: "#777777",
                fontWeight: 500,
                maxWidth: "50%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {contacts[0]?.email}
            </Text>
          </div>
        </Link>
      </div>
    </Col>
  );
};

export default GridCard;
