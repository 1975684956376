import { createContext, useState } from "react";

export const ModalContext = createContext(null);

const MessageModalContext = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(null);
  const [popup, setPopup] = useState(null);

  return (
    <ModalContext.Provider
      value={{
        open,
        setOpen,
        content,
        setContent,
        popup,
        setPopup,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default MessageModalContext;
