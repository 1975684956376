import React from "react";
import "./VenueCard.scss";
import cardImgN from "../../../assets/Image_not_available.png";
import { ReactComponent as RoomIcon } from "../../../assets/RoomIcon.svg";
import { ReactComponent as AddIcon } from "../../../assets/AddIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/deleteIcon.svg";
import ConfirmationModal from "../Modals/ConfirmationModal";
import axios from "axios";
import axiosInstance from "../../../common/axiosInstance";
import EditLocation from "../Modals/EditLocation";
import AddNewRoom from "../Modals/AddNewRoom";

const VenueCard = ({
  setIsVenueDetails,
  venueData,
  getVenues,
  setVenueId,
  setVenue,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [isRoomModalOpen, setIsRoomModalOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const deleteVenue = () => {
    try {
      setIsLoading(true);
      axiosInstance
        .delete(
          `${process.env.REACT_APP_BASE_API_URL}/api/company/location/${venueData?.id}/`,
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem("access_token")}`,
            },
          },
        )
        .then((res) => {
          getVenues();
        });
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = "/server-error";
        console.log("Redirecting to server error page...");
      } else {
        console.log(error);
      }
    }
  };

  return (
    <>
      <div
        className="venue-card-wrapper"
        onClick={() => {
          setVenueId(venueData?.id);
          setVenue(venueData);
        }}
      >
        <div className="card-img">
          <div className="btns">
            <span className="btn" onClick={() => setIsEditModalOpen(true)}>
              <EditIcon />
            </span>
            <span className="btn" onClick={() => setIsModalOpen(true)}>
              <DeleteIcon />
            </span>
          </div>

          <img
            src={
              venueData?.images?.length ? venueData?.images[0]?.image : cardImgN
            }
            alt=""
            srcSet=""
            onClick={() => setIsVenueDetails(true)}
          />
        </div>
        <div className="card-content">
          <div className="heading">
            <h3>{venueData?.name}</h3>
            <span>{venueData?.rooms?.length} ROOMS</span>
          </div>
          <p className="address">{venueData?.address_line1}</p>
          <p className="address">
            {venueData?.zip_code?.city}, {venueData?.zip_code?.state},{" "}
            {venueData?.zip_code?.zip_code}
          </p>
          <div className="rooms-tags">
            {venueData?.rooms?.map((room, index) => (
              <span key={index}>
                <RoomIcon /> &nbsp; {room.name}
              </span>
            ))}

            <span
              onClick={() => {
                setIsRoomModalOpen(true);
              }}
              className="add"
            >
              <AddIcon />
            </span>
          </div>
        </div>
      </div>
      <ConfirmationModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        isLoading={isLoading}
        onConfirm={deleteVenue}
        content={`Are you sure you want to delete ${venueData?.name}?`}
      />
      <EditLocation
        open={isEditModalOpen}
        getVenues={getVenues}
        setOpen={setIsEditModalOpen}
        venueData={venueData}
      />
      <AddNewRoom
        getVenues={getVenues}
        open={isRoomModalOpen}
        setOpen={setIsRoomModalOpen}
        isDirect
        handleLocationModal={() => {}}
        venueId={venueData?.id}
      />
    </>
  );
};

export default VenueCard;
