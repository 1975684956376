import React, { useState, useEffect } from "react";
import { Typography, Card, Row, Col, Form, Input, Switch } from "antd";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-hot-toast";

const PaymentForm = ({
  details,
  cardHolderName,
  setCardHolderName,
  createPaymentId,
  paymentInfo,
  setPaymentInfo,
  form,
  setSubmitLoading,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { Title, Text } = Typography;
  const [nameError, setNameError] = useState(false);

  const [paymentId, setPaymentId] = useState("");
  const [partialAllowed, setPartialAllowed] = useState(false);

  useEffect(() => {
    dataMapping();
  }, []);

  const dataMapping = () => {
    details.payment_terms.map((items) => {
      setPaymentId(items.id);
      setPartialAllowed(items.is_partials_allowed);
    });
  };

  const createPaymentMethod = async () => {
    if (cardHolderName === "" || !elements.getElement(CardElement)) {
      setNameError("Card holder name is required.");
      return;
    }
    setSubmitLoading(true);
    try {
      const card = elements.getElement(CardElement);
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: card,
      });
      setNameError("");
      if (cardHolderName === "") {
        setNameError("Card holder name is required.");
      } else if (error) {
        toast.error(error?.message);
      } else if (!cardHolderName.trim() || !elements.getElement(CardElement)) {
        setNameError("Please fill in all required fields.");
        return;
      } else {
        setPaymentInfo((prev) => ({
          ...prev,
          payment_method_id: paymentId ? paymentId : "",
        }));
        form.submit();
      }
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = "/server-error";
        console.log("Redirecting to server error page...");
      } else {
        console.error(error);
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleEnableInstallment = (checked) => {
    setPaymentInfo((prev) => ({ ...prev, is_partial_allowed: checked }));
    // handlePartialPayment();
  };

  useEffect(() => {
    if (createPaymentId) {
      // createPaymentMethod();
    }
  }, [createPaymentId]);

  return (
    <Card className="booking-card" style={{ marginBottom: 24 }}>
      <Title level={4}>Payment Details</Title>
      <Form layout="vertical">
        <Row gutter={[16, 0]}>
          <Col span={8}>
            <Form.Item label="Card Holder name">
              <Input
                value={cardHolderName}
                onChange={(e) => setCardHolderName(e.target.value)}
                size="large"
                placeholder="Enter card holder name"
                status={nameError ? "error" : ""}
              />
              {nameError ? <Text type="danger">{nameError}</Text> : null}
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item label="Card Info">
              <CardElement
                id="card-element"
                onChange={(e) =>
                  setPaymentInfo((prev) => ({
                    ...prev,
                    payment_method_id: paymentId ? paymentId : "",
                  }))
                }
              />
            </Form.Item>
          </Col>

          {details.payment_terms.length > 1 ? (
            <>
              <Col span={12}>
                <div className="check-schedule">
                  <label>Create Payment Schedule</label>
                  <div className="switch">
                    <Switch
                      value={paymentInfo?.is_partial_allowed}
                      onChange={(checked) => handleEnableInstallment(checked)}
                    />
                    Enable Installment Payment
                  </div>
                </div>
              </Col>
            </>
          ) : null}
        </Row>
      </Form>
    </Card>
  );
};

export default PaymentForm;
