import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { menuItems } from "./MenuItems";
import "./sidebar.scss";

// ant icons and components
import { Menu, Dropdown, Avatar, Modal, Button, Empty } from "antd";
import {
  CaretDownOutlined,
  ArrowRightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { setVenue, setVenues } from "../../../store/VenuesSlice";
// Assets Import
import Logo from "../../../assets/Header/planspace-logo.svg";
import Logos from "../../../assets/Header/logoSmallplanspace.jpg";

// PR for the client 2-11-2023

const Sidebar = ({ collapsed }) => {
  const venues = useSelector((state) => state.venues.venues);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [active, setActive] = useState(["/"]);
  const [open, setOpen] = useState(false);

  const [user, setUser] = useState("");
  const getUser = JSON.parse(localStorage.getItem("planspace_user_obj"));

  useEffect(() => {
    if (getUser?.data) {
      setUser(getUser?.data);
    } else if (getUser) {
      setUser(getUser);
    }
  }, []);

  useEffect(() => {
    setActive([location?.pathname]);
  }, [location]);

  const logoutUser = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("selectedVanue");
    dispatch(setVenue(""));
    dispatch(setVenues([]));
    navigate("/login");
  };
  const UserProfile = () => {
    navigate("/userSettings");
  };

  const items = [
    {
      label: <a onClick={UserProfile}>Profile</a>,
      key: "UserSettings",
      icon: <UserOutlined />,
    },
    {
      label: <a onClick={logoutUser}>Logout</a>,
      key: "logout",
      icon: <ArrowRightOutlined />,
    },
  ];

  const showModal = () => {
    setOpen(true);
  };

  return (
    <div className="scroll-view-two scrollbar-secondary-two">
      <div style={{ margin: "20px", cursor: "pointer" }}>
        <div className="logo-wrapper">
          <img src={collapsed === false ? Logo : Logos} className="logo-main" />
          {collapsed === false ? <p>v1.0.16</p> : null}
        </div>
        <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
        >
          <div className="intro-user">
            <div className="user-container">
              <Avatar icon={<UserOutlined />} className="user-pic" />
              <div className="active-btn" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {collapsed === false ? (
                <div style={{ marginLeft: "10px" }}>
                  <p className="user-name">
                    {user?.full_name}
                    <span style={{ marginLeft: "10px" }}>
                      <CaretDownOutlined style={{ color: "#858D9D" }} />
                    </span>
                  </p>
                  <p className="sub-role">
                    {user?.username?.length > 25
                      ? `${user?.username?.substring(0, 25)}...`
                      : `${user?.username}`}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </Dropdown>
      </div>

      <Menu
        className="planspace-sidebar"
        selectedKeys={active}
        mode="inline"
        items={menuItems}
        onClick={(item) => {
          if (
            !venues?.length &&
            (item?.key === "/company/services" ||
              item?.key === "/company/packages")
          ) {
            showModal();
            return;
          }
          if (item?.key) {
            setActive(item?.keyPath);
            navigate(item?.key);
          }
        }}
      />
      <Modal
        open={open}
        centered
        title={"Access denied"}
        type="confirm"
        onCancel={() => setOpen(false)}
        footer={[
          <Button
            type="primary"
            style={{ marginLeft: "auto" }}
            onClick={() => {
              navigate("/company/venues");
              setOpen(false);
            }}
          >
            Go to Venues
          </Button>,
        ]}
      >
        <Empty
          description={"Please create Venue first to access this page"}
          style={{ marginBottom: 32 }}
        />
      </Modal>
    </div>
  );
};

export default Sidebar;
