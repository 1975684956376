import React from "react";

const setupsidebar = () => {
  return (
    <div style={{ display: "flex", justifyContent: "rightw" }}>
      <div
        style={{
          width: "300px",
          height: "100vh",
          flexShrink: "0",
          backgroundColor: "#2C75D3",
          color: "white",
        }}
      >
        <div>
          <p>Quick & Easy Setup</p>
          <ul>
            <li>Sample Step</li>
            <li>Sample Step</li>
            <li>Sample Step</li>
            <li>Sample Step</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default setupsidebar;
