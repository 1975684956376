import React, { useState, useEffect } from "react";
import { Typography, Card, Space, Checkbox, Grid, Empty, Table, Input, Select, Divider, Row, Col, Avatar } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

import moment from "moment";
import { ReactComponent as Badge } from "../../../assets/icons/badge.svg";
import { serviceIcons } from "./utils";
import bgImage from "../../../assets/background.jpg";
import dummyPhoto from '../Assets/photo.png';
import Loader from "../../../common/Loader";

import "../EndUserStyles.scss";
const { useBreakpoint } = Grid;

const Packages = ({ setFilteredServices, packages, value, setSelectedPackage , pricingModel }) => {
  const screens = useBreakpoint();
  const { Title, Text } = Typography;
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  // const updatedPckages = packages?.length
  //   ? packages.map((item) => ({ label: item.name, value: item.id, price: item.flat_price }))
  //   : [];
  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const hoursText = hours > 0 ? `${hours} hour${hours !== 1 ? "s" : ""}` : "";
    const minutesText =
      remainingMinutes > 0
        ? `${remainingMinutes} minute${remainingMinutes !== 1 ? "s" : ""}`
        : "";

    if (hoursText && minutesText) {
      return `${hoursText} and ${minutesText}`;
    } else {
      return hoursText || minutesText || "-";
    }
  };


  // useEffect(() => {
  //   if (value) {
  //     const found = packages?.find((item) => item.id === value);
  //     setSelected(found);
  //     setFilteredServices(found);
  //   }
  // }, [value]);

  const dataSource = packages?.map((item, index) => ({
    key: index,
    id: item.id,
    name: item?.name,
    description: item.description,
    image: item?.images?.length ? item?.images[0]?.image : dummyPhoto,
    price: pricingModel === "PER_PERSON" ? `${item?.pp_price || "0"}` : `${item?.flat_price || "0"}`,
   
  }));


  const columns = [
    {
      title: '',
      dataIndex: 'image',
      key: 'image',
      width: 40,
      render: (text) => (
        <img
          src={text}
          alt="package"
          style={{ width: "50px", height: "50px", borderRadius: "5px", marginTop: "3px" }}
        />
      ),
    },
    {
      title: '',
      width: "50%",
      key: 'packges',
      render: (text, record) => (
        <div style={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
          <h4 style={{ margin: 0 }}>{record.name}</h4>
          <p style={{ margin: 0 }}>{record.description}</p>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'price',
      key: 'price',
      render: (text) => <h4 style={{ margin: 0 }}>${text || 0}</h4>,
    },
    {
      title: 'Select',
      key: 'select',
      width: 40,
      render: (text, record) => (
        <Checkbox
        checked={value.some((selectedPackage) => selectedPackage.id === record.id)} 
        onChange={(e) => {
          if (e.target.checked) {
            setSelectedPackage((prevSelected) =>
              prevSelected[0]?.id === record.id ? [] : [record]
            ); 
          } else {
            setSelectedPackage([]);
          }
        }}
      />
      
      
      ),
    },


  ];

  return (


    <>
      {/* <Card className="booking-card">
        <Title level={4}>Select Package</Title>
        <Select
          size="large"
          style={{ width: "100%" }}
          placeholder="Select"
          onChange={(value) => handleChange(value)}
          options={updatedPckages}
        />
        <Divider />
        {selected ? (
          <>
            <Title level={4}>Selected Package</Title>
            <div className="package-wrapper">
              <Row gutter={16}>
                <Col span={10}>
                  <img
                    className="thumbnail"
                    src={selected?.images?.length ? selected?.images[0]?.image : bgImage}
                    alt="package"
                  />
                </Col>
                <Col span={14}>
                  <Title level={4}>{selected?.name}</Title>
                  <Text>{selected?.description}</Text>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: 46 }}>
                <Col span={10}>
                            <div className='package-details'>
                                <Avatar icon={<Badge />} size={46} style={{ backgroundColor: '#F0F1F3' }} />
                                <div>
                                    <Title level={5} type='secondary' style={{ marginBlock: 0 }}>Day Availability</Title>
                                    <Title level={5} style={{ marginBlock: 0 }}>{selected?.days?.length ? selected?.days?.join(', ') : '-'}</Title>
                                </div>
                            </div>
                        </Col>
                <Col span={10}>
                  <div className="package-details">
                    <Avatar
                      icon={<Badge />}
                      size={46}
                      style={{ backgroundColor: "#F0F1F3" }}
                    />
                    <div>
                      <Title level={5} style={{ marginBlock: 0 }}>{moment.duration(selected?.duration_minutes, 'hours').asMinutes() || '-'}</Title>
                      <Title level={5} style={{ marginBlock: 0 }}>{selected?.duration_minutes}</Title>
                      <Title level={5} type="secondary" style={{ marginBlock: 0 }}>
                        Package Length
                      </Title>
                      <Title level={5} style={{ marginBlock: 0 }}>
                        {formatDuration(selected?.duration_minutes)}
                      </Title>
                    </div>
                  </div>
                </Col>
              </Row>
              <Divider />
              {selected?.addons?.length ? (
                <>
                  <Title level={4}>Services</Title>
                  <div className="services-wrapper">
                    {selected?.addons?.map((item, i) => (
                      <div key={i} className="package-details">
                        <Avatar
                          icon={serviceIcons[i]?.icon}
                          size={46}
                          style={{ backgroundColor: serviceIcons[i]?.color }}
                        />
                        <Title level={5} style={{ marginBlock: 0 }}>
                          {item?.name}
                        </Title>
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
            </div>
            <div className="total-price">
              <div className="inner">
                <Title level={5} style={{ marginBlock: 0, color: "#0499CB" }}>
                  Total Package Cost
                </Title>
                <Title level={5} style={{ marginBlock: 0 }}>
                  ${selected?.total_price}
                </Title>
              </div>
            </div>
          </>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Package Selected"
          />
        )}
      </Card>  */}

      < div>
        <Card style={{ marginTop: "20px" }}>
          <Space
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4}>Packages</Title>
          </Space>
          <div
            className="events-details"
            style={{ marginTop: screens.xs ? "10px" : "0" }}
          >
            <p
              className="event-head"
              style={{ color: "#667085", pointerEvents: "none" }}
            >
              Select Packages to add
            </p>
          </div>
          <div className="item-details" style={{ height: "140px" }}>
            <Card>
              {loading && !packages?.length ? (
                <Loader minHeight={400} />
              ) : packages?.length ? (
                <Table
                  scroll={{ x: 600 }}
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  showHeader={false}
                />
              ) : !loading ? (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No Packages"
                />
              ) : null}
            </Card>
          </div>

        </Card>

      </div >
    </>

  );
};

export default Packages;