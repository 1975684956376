import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'antd';
import Loader from "../../../common/Loader";

import myApi from "../../../network/axios";
import { Link } from 'react-router-dom';

const CheckStripeInfoModal = ({ checkStripe, setCheckStripe, setStripeAccountStatus, stripeAccountStatus }) => {

    const [loading, setLoading] = useState(false)

    const getStripeAccountInfo = async () => {
        setLoading(true)
        try {
            await myApi.get(
                `${process.env.REACT_APP_BASE_API_URL}/api/company/integration/stripe/`
            );
            setStripeAccountStatus(true)
        } catch (error) {
            console.log("error", error);
            setStripeAccountStatus(false)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getStripeAccountInfo();
    }, []);

    return (
        <div>
            {
                stripeAccountStatus === false ? (
                    <Modal title="Oppsss," centered open={checkStripe} onCancel={setCheckStripe}
                        footer={[
                            <Button style={{ padding: "0px 30px" }} key="submit" type="primary">
                                <Link to='/Integration/LinkStripe'>Setup Stripe</Link>
                            </Button>
                        ]}
                    >
                        <p>We are almost at the end stage. Venue guests need to make payment. I don’t see Stripe linked.</p>
                    </Modal>
                ) : null
                    // (
                    //     <Modal title='Getting Stripe Info...' centered open={checkStripe} onCancel={setCheckStripe}
                    //         footer={null}
                    //     >
                    //         <p>Please hold on a second while we get account information from Stripe</p>
                    //     </Modal>
                    // )
            }
        </div>
    )
}

export default CheckStripeInfoModal