import React, { useState, useEffect } from 'react';
import { Typography, Card, Space, Checkbox, Grid, Empty, Table, Switch } from "antd";

import { getPackages } from "../../../services/packages";
import Loader from "../../../common/Loader";

import templateImage from "../../../assets/background.jpg";

const UserPackages = ({ error, setErrors, venue, selectedPackages, setSelectedPackages, enablePackages, setEnablePackages, pricingModel }) => {
    const { useBreakpoint } = Grid;
    const { Title, Text } = Typography;
    const screens = useBreakpoint();

    const [packages, setPackages] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const filteredPackages = packages.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const fetchPackages = async () => {
        if (!venue?.id) return;
        setLoading(true);
        try {
            const res = await getPackages({
                page: page,
                location: venue?.id,
            });
            const fetchedPackages = res.data.results;
            if (page > 1) {
                setPackages((prev) => [...prev, ...fetchedPackages]);
            } else {
                setPackages(fetchedPackages);
                setSelectedPackages(fetchedPackages.map(pkg => pkg.id)); // Set all package IDs initially
            }
        } catch (error) {
            const errorCode = error?.response?.status || 500;
            if (errorCode === 500 || errorCode === 503) {
                window.location.href = "/server-error";
                console.log("Redirecting to server error page...");
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (venue?.id) {
            fetchPackages();
        }
    }, [venue]);

    useEffect(() => {
        if (venue?.id) {
            fetchPackages();
        }
    }, [pricingModel]);

    const handleCheckboxChange = (item) => {
        const isSelected = selectedPackages.includes(item.id);
        const updatedPackages = isSelected
            ? selectedPackages.filter(id => id !== item.id)
            : [...selectedPackages, item.id];

        setSelectedPackages(updatedPackages);

        if (updatedPackages.length === 0) {
            setErrors(prev => ({ ...prev, packages: "Please select at least one package." }));
        } else {
            setErrors(prev => {
                const { packages, ...rest } = prev;
                return rest;
            });
        }
    };

    const dataSource = filteredPackages.map((item, index) => ({
        key: index,
        name: item.name,
        description: item.description,
        image: item.images?.length ? item.images[0].image : templateImage,
        total_price: item.total_price,
        price: pricingModel === "PER_PERSON" ? `${item?.pp_price || "0"}` : `${item?.flat_price || "0"}`,
        id: item.id
    }));

    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            width: 40,
            render: (text) => (
                <img
                    src={text}
                    alt="Service"
                    style={{ width: "50px", height: "50px", borderRadius: "5px", opacity: enablePackages ? '' : '0.4' }}
                />
            ),
        },
        {
            title: 'Service',
            key: 'service',
            width: "50%",
            render: (text, record) => (
                <div style={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
                    <h4 style={{ margin: 0, color: enablePackages ? 'black' : '#999' }}>{record.name}</h4>
                    <p style={{ margin: 0, color: enablePackages ? 'black' : '#999' }}>{record.description}</p>
                </div>
            ),
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (text) => <h4 style={{ margin: 0, color: enablePackages ? 'black' : '#999' }}>${text || 0}</h4>,
        },
        {
            title: 'Select',
            key: 'select',
            width: 40,
            render: (text, record) => (
                <Checkbox
                    checked={selectedPackages.includes(record.id)}
                    onChange={() => handleCheckboxChange({ id: record.id })}
                    disabled={!enablePackages}
                />
            ),
        },
    ];


   useEffect(() =>{
    if (packages.length === 0) {
        setErrors(prev => ({ ...prev, packages: "Please select at least one package." }));
    }
    else {
        if (selectedPackages.length === 0) {
            setErrors(prev => ({ ...prev, packages: "Please select at least one package." }));
        } else {
            setErrors(prev => {
                const { packages, ...rest } = prev;
                return rest;
            });
        }
    }
   } , [packages, selectedPackages])


    return (
        <div>
            <Card style={{
                marginTop: "20px",
                // borderColor: error && "#ff4d4f",
                backgroundColor: enablePackages ? "white" : "#f0f0f0", // Disabled background
                color: enablePackages ? "black" : "#999", // Disabled text color
            }}>
                <Space
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Title level={4} style={{ color: enablePackages ? "black" : "#999" }}>Packages</Title>
                    <Switch
                        style={{ marginRight: "10px" }}
                        checked={enablePackages}
                        onChange={(checked) => setEnablePackages(checked)}
                    />
                </Space>

                {/* {error ? (
                    <Text style={{ display: "block" }} type="danger">
                        {error}
                    </Text>
                ) : null} */}
                <div
                    className="events-details"
                    style={{ marginTop: screens.xs ? "10px" : "0" }}
                >
                    <p
                        className="event-head"
                        style={{ color: enablePackages ? "#667085" : "#999", pointerEvents: "none" }}
                    >
                        Select Packages to add
                    </p>
                </div>
                <div className="packages-services-card" style={{ height: "140px" }}>
                    <Card>
                        {loading && !filteredPackages?.length ? (
                            <Loader minHeight={100} />
                        ) : filteredPackages?.length ? (
                            <Table
                                scroll={{ x: 600 }}
                                dataSource={dataSource}
                                columns={columns}
                                pagination={false}
                                showHeader={false}
                                rowKey="key"
                                style={{ borderCollapse: 'separate', borderSpacing: '0' }}
                            />
                        ) : !loading ? (
                            // <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Packages"/>
                            <Text type="danger">It seems like there are no active packages. Please select at least one package</Text>
                        ) : null}
                    </Card>
                </div>
            </Card>
        </div>
    );
};

export default UserPackages;