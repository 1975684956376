import { VENUES } from "./endpoints";
import client from "../axios";

// Get Venues
export const getVenues = async (params) => {
  const queryParams = params
    ? Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")
    : "";
  return await client.get(VENUES + (queryParams ? `?${queryParams}` : ""));
};
// Get Venue
export const getVenue = async (id) => {
  return await client.get(VENUES + id + "/");
};

// Create Venue
export const createVenue = async (body) => {
  return await client.post(VENUES, body);
};

// Update Venue
export const updateVenue = async (id, body) => {
  return await client.put(VENUES + id + "/", body);
};

// delete Venue
export const deleteVenue = async (id) => {
  return await client.delete(VENUES + id + "/");
};
