import React, { useState, useEffect } from "react";
import "./roomDetails.scss";
import { ReactComponent as EditIcon } from "./../../../../../assets/Menu/edit.svg";
import { ReactComponent as DelIcon } from "./../../../../../assets/DeleteWithCircle.svg";
import { Tabs, Spin, Empty } from "antd";
import { useSelector } from "react-redux";
import PackageCard from "./../../PackageCard";
import ServiceCard from "../../ServiceCard";
import AddNewPackage from "../../../Modals/AddNewPackage";
import AddNewService from "../../../Modals/AddNewService.js";
import client from "../../../../../axios";

import { Row, Col } from "antd";

const RoomDetails = ({ setIsConfOpen, editRoom, venueData }) => {
  const location = useSelector((state) => state.venues.selectedVenue);
  const [packages, setPackages] = useState([]);
  const [services, setServices] = useState([]);
  const [isPackageLoading, setIsPackageLoading] = useState(false);
  const [isServicesLoading, setIsServicesLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addServices, setAddServices] = useState(false);
  const [roomData, setRoomData] = useState(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleGetPackages = async () => {
    setIsPackageLoading(true);
    try {
      const res = await client.get(
        `/api/company/package/?location=${location?.id}&room=${roomData?.id}`,
      );
      setPackages(res?.data?.results);
    } catch (error) {
      console.error("Packages:", error?.response?.data?.message);
    } finally {
      setIsPackageLoading(false);
    }
  };

  const handleGetServices = async () => {
    setIsServicesLoading(true);
    try {
      const res = await client.get(
        `/api/company/addon/?location=${location?.id}&room=${roomData?.id}`,
      );
      setServices(res?.data?.results);
    } catch (error) {
      console.error("Services:", error?.response?.data?.message);
    } finally {
      setIsServicesLoading(false);
    }
  };

  const Packages = () => {
    return (
      <>
        <div className="packages-wrapper">
          <div className="header">
            <span>Packages</span>
            <button
              disabled={!roomData?.id}
              style={{ cursor: "pointer" }}
              onClick={openModal}
            >
              {" "}
              Add Package
            </button>
          </div>
          <div className="body">
            <Row gutter={[16, 16]}>
              {!isPackageLoading && packages.length ? (
                packages.map((pkg, i) => (
                  <Col span={6}>
                    <PackageCard
                      key={i}
                      packageData={pkg}
                      getPackages={handleGetPackages}
                      activeTab="All"
                    />
                  </Col>
                ))
              ) : (
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: 400,
                  }}
                >
                  {isPackageLoading ? (
                    <Spin size={"large"} />
                  ) : (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="No Pacakges are available"
                    />
                  )}
                </Col>
              )}
            </Row>
          </div>
        </div>
        {isModalOpen ? (
          <AddNewPackage
            open={isModalOpen}
            setOpen={setIsModalOpen}
            getPackages={handleGetPackages}
            selectedRoom={roomData}
          />
        ) : null}
      </>
    );
  };
  const Services = () => {
    return (
      <>
        <div className="packages-wrapper">
          <div className="header">
            <span>Services</span>
            <button
              disabled={!roomData?.id}
              style={{ cursor: "pointer" }}
              onClick={() => setAddServices(true)}
            >
              {" "}
              Add Service
            </button>
          </div>
          <div className="body">
            <Row gutter={[16, 16]}>
              <Col span={6}></Col>
            </Row>
            <Row gutter={[16, 16]}>
              {!isServicesLoading && services.length ? (
                services.map((serv, i) => (
                  <Col key={i} span={6}>
                    <ServiceCard
                      serviceData={serv}
                      getServices={handleGetServices}
                      activeTab="All"
                    />
                  </Col>
                ))
              ) : (
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: 400,
                  }}
                >
                  {isServicesLoading ? (
                    <Spin size={"large"} />
                  ) : (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="No Services are available"
                    />
                  )}
                </Col>
              )}
            </Row>
          </div>
        </div>
        {addServices ? (
          <AddNewService
            open={addServices}
            setOpen={setAddServices}
            getServices={handleGetServices}
            selectedRoom={roomData}
          />
        ) : null}
      </>
    );
  };

  const items = [
    {
      key: "1",
      label: <label className="tab_heading">Packages</label>,
      children: Packages(),
    },
    {
      key: "2",
      label: <label className="tab_heading">Services</label>,
      children: Services(),
    },
  ];

  const onChange = (key) => {
    if (key === "1") {
      handleGetPackages();
    }
    if (key === "2") {
      handleGetServices();
    }
  };

  useEffect(() => {
    setRoomData(JSON.parse(localStorage.getItem("currentRoom")));
  }, [venueData]);

  useEffect(() => {
    if (roomData?.id) {
      handleGetPackages();
      handleGetServices();
    }
  }, [roomData]);

  return (
    <>
      <div className="room-details-wrapper">
        <Row gutter={[16, 16]}>
          <Col sm={24} md={12} lg={8} xl={10} xxl={10} className="c1">
            <img
              src={roomData?.images?.length && roomData?.images[0]?.image}
              alt=""
              srcset=""
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Col>

          <Col sm={24} md={12} lg={8} xl={14} xxl={14} className="c4">
            <dir className="venue-details">
              <span>{roomData?.name}</span>
              <span
                className="edit-icon"
                onClick={() => setIsConfOpen(roomData)}
              >
                <DelIcon />
              </span>
              <span className="edit-icon" onClick={() => editRoom(roomData)}>
                <EditIcon />
              </span>
            </dir>
            <Row gutter={[16, 16]} className="venue-data">
              <Col span={12}>
                <p>Maximum Capacity</p>
                <p>Space Type</p>
                <p>Ceremony Type</p>
              </Col>
              <Col span={12}>
                <p>:&nbsp; {roomData?.max_guests}</p>
                <p>
                  :&nbsp;{" "}
                  {roomData?.spaces?.length
                    ? roomData?.spaces.map((item) => <>{item}, </>)
                    : "No item to display"}
                </p>
                <p>
                  :&nbsp;{" "}
                  {roomData?.ceremony_types?.length
                    ? roomData?.ceremony_types.map((item) => <>{item}, </>)
                    : "No item to display"}
                </p>
              </Col>
            </Row>
            <dir className="venue-offered-amenities">
              <span>Offered Amenities</span>
            </dir>
            <Row gutter={[16, 0]} className="venue-data">
              {roomData?.amenities?.length
                ? roomData?.amenities.map((item, i) => (
                    <Col span={12} key={i}>
                      <p>{item}</p>
                    </Col>
                  ))
                : null}
            </Row>
          </Col>
        </Row>
      </div>

      <div className="packages">
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </>
  );
};

export default RoomDetails;
