import React, { useState } from 'react';
import { Typography, Button, Card, message, Spin, Empty } from "antd";
import { CopyOutlined, ShareAltOutlined, PhoneOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
const { Title } = Typography;

const VenueDetails = ({
    venueInfo,
    StartTime, EndTime, eventGuestCount, selectEventDate
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const title = "Event Venue";
    const text = "Planspace Event Venue";
    const url = window.location.href;
    const ShareURL = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: title,
                    text: text,
                    url: url,
                });
                console.log('Content shared successfully');
            } catch (error) {
                console.error('Error sharing content:', error);
            }
        } else {
            alert('Web Share API is not supported in your browser. Please copy the link manually.');
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        message.success(`${text} copied to clipboard`);
    };



    const formatPhoneNumber = (phone) => {
        if (phone) {
            return `${phone.slice(0, 3)}-${phone.slice(3, 7)}-${phone.slice(7)}`;
        }
        return phone;
    };

    const aboutUsText = venueInfo?.about_us_text || '';
    const wordsArray = aboutUsText.split(' ');
    const wordLimit = 40;

    const formattedPhone = formatPhoneNumber(venueInfo?.location_contact?.phone_number);
    const sliceText = (text, isExpanded) => {
        const sliceLength = isExpanded ? text.length : 120;
        return text.length <= sliceLength ? text : text.slice(0, sliceLength) + '...';
    };
    const formatTime = (time) => {
        return dayjs(time, 'HH,mm').format('hh:mm A');

    }
    return (
        <>
            {
                !venueInfo ?
                    (
                        <div style={{ textAlign: "center", marginTop: "50px" }}>
                            <Spin size="large" tip="Loading venue details..." />
                        </div>
                    )
                    :
                    (
                        <Card style={{ width: "100%" }}>
                            <Title level={3}>
                                {venueInfo ? venueInfo?.location?.name : "Venue"}
                            </Title>
                            {aboutUsText && (
                                <>
                                    <Typography style={{ fontSize: "15px", fontWeight: "700", lineHeight: "23.04px" }}>
                                        About us
                                    </Typography>
                                    <Typography style={{ fontSize: "15px", fontWeight: "400", lineHeight: "23.04px" }}>
                                        {sliceText(aboutUsText, isExpanded)}
                                        {aboutUsText.length > 120 && (
                                            <span onClick={() => setIsExpanded(!isExpanded)} style={{ color: "#1677ff", cursor: 'pointer', marginLeft: "5px" }}>
                                                {isExpanded ? "Show Less" : "Read More"}
                                            </span>
                                        )}
                                    </Typography>
                                </>
                            )}
                            {
                                venueInfo ?
                                    (
                                        <>
                                            <div style={{ marginTop: "15px" }}>
                                                <Typography style={{ fontSize: "15px", fontWeight: "700", lineHeight: "23.04px" }}>
                                                    Contact us
                                                </Typography>

                                                <div style={{ marginTop: "10px" }}>
                                                    <div className="contact-item">
                                                        <Typography style={{ fontSize: "15px", fontWeight: "400", lineHeight: "23.04px" }}>
                                                            Email:&nbsp;
                                                            <a href={`mailto:${venueInfo?.location_contact?.email || ""}`}>
                                                                {venueInfo?.location_contact?.email || ""}
                                                            </a>
                                                        </Typography>
                                                        <Button
                                                            icon={<CopyOutlined />}
                                                            onClick={() => copyToClipboard(`${venueInfo?.location_contact?.email || ''}`)}
                                                            className="copy-btn"
                                                        />
                                                    </div>

                                                    <div className="contact-item">
                                                        <Typography style={{ fontSize: "15px", fontWeight: "400", lineHeight: "23.04px" }}>
                                                            Phone:&nbsp;
                                                            <a href={`tel:${formattedPhone || ''}`}>
                                                                {formattedPhone ? formattedPhone : ''}
                                                            </a>
                                                        </Typography>
                                                        <Button
                                                            icon={<CopyOutlined />}
                                                            onClick={() => copyToClipboard(`${formattedPhone ? formattedPhone : ''}`)}
                                                            className="copy-btn"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{ marginTop: '20px', display: "flex", justifyContent: "flex-start", gap: "10px" }}>
                                                <Button onClick={ShareURL} icon={<ShareAltOutlined />} type="primary" style={{ width: "107px", height: "44px", fontSize: "14px", fontWeight: "600" }}>
                                                    Share
                                                </Button>
                                                <Button style={{ width: "107px", height: "44px", fontSize: "14px", fontWeight: "600", marginLeft: '10px', }} icon={<PhoneOutlined />} type="primary">
                                                    <a href={`tel:${venueInfo?.location?.phone || ''}`}>Call</a>
                                                </Button>
                                            </div>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                        </>
                                    )
                            }

                        </Card>
                    )
            }


            <Card style={{ width: "100%", marginTop: "20px", backgroundColor: "#EBF1FF", border: "1px solid #C2D6FF" }}>
                <Typography style={{ fontSize: "19px", fontWeight: "700", lineHeight: "30.04px", color: "#222222" }}>
                    Event Info
                </Typography>
                {
                    selectEventDate || StartTime && EndTime || eventGuestCount ?
                        (
                            <>
                                <Typography style={{ fontSize: "15px", fontWeight: "400", lineHeight: "23.04px", color: "#222222" }}>
                                    {selectEventDate
                                        ? new Date(selectEventDate).toLocaleDateString('en-US', {
                                            weekday: 'long',
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        })
                                        : ""}
                                </Typography>

                                <Typography style={{ fontSize: "15px", fontWeight: "400", lineHeight: "23.04px", color: "#222222" }}>
                                    {
                                        StartTime && EndTime &&
                                        (
                                            <span>
                                                {StartTime ? formatTime(StartTime) : ""}-{EndTime ? formatTime(EndTime) : ""}
                                            </span>
                                        )
                                    }
                                </Typography>

                                <Typography style={{ fontSize: "15px", fontWeight: "400", lineHeight: "23.04px", color: "#222222" }}>
                                    {eventGuestCount &&
                                        (
                                            <span>{eventGuestCount} People</span>
                                        )
                                    }
                                </Typography>
                            </>
                        )
                        :
                        (
                            <>
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="Please fill the Event Info to view details"
                                />
                            </>
                        )
                }
            </Card>

        </>
    )
}

export default VenueDetails;