import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Row, Col, Result, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { getPackages } from "../../services/packages";
import usePopup from "../../common/MessageModal/usePopup";

import Loader from "../../common/Loader";
import Layout from "./Layout";
import PackageCard from "./comps/PackageCard";
import PackageForm from "./comps/forms/PackageForm";

const Packages = () => {
  const popup = usePopup();
  const current_venue = useSelector(
    (state) => state?.venues?.selectedVenue?.id,
  );
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [packageModal, setPackageModal] = useState(false);
  const [statusFilter, setStatusFilter] = useState("all");

  const fetchPackages = async () => {
    setLoading(true);
    try {
      const res = await getPackages({
        location: current_venue,
        status: statusFilter && statusFilter !== "all" ? statusFilter : "",
      });
      setPackages(res?.data?.results);
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = "/server-error";
        console.log("Redirecting to server error page...");
      } else {
        popup.error("Oops...", error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (current_venue) {
      fetchPackages();
    }
  }, [current_venue]);

  useEffect(() => {
    if (current_venue && statusFilter) {
      fetchPackages();
    }
  }, [statusFilter]);

  const PACKAGE_STATUSES = [
    { value: "all", label: "All Packages" },
    { value: "activated", label: "Active" },
    { value: "deactivated", label: "Inactive" },
    // { value: "draft", label: "Draft" },
  ];

  return (
    <>
      <Layout
        title={"Packages"}
        addButton={
          <Button
            type="primary"
            size="large"
            icon={<PlusOutlined />}
            onClick={() => setPackageModal(true)}
          >
            Add Package
          </Button>
        }
        items={[{ title: "Company Settings" }, { title: "Packages" }]}
      >
        <div className={"filter-switch"}>
          {PACKAGE_STATUSES?.map((item, i) => (
            <>
              <Button
                key={i}
                className={`button ${statusFilter === item?.value && "active"}`}
                type="text"
                onClick={() => setStatusFilter(item?.value)}
              >
                {item?.label}
              </Button>
            </>
          ))}
        </div>
        {loading && !packages?.length ? (
          <Loader minHeight={400} />
        ) : (
          <Spin spinning={loading}>
            <Row gutter={[24, 24]}>
              {packages?.length ? (
                packages.map((item, i) => (
                  <Col key={i} span={24} md={12} xl={8} xxl={6}>
                    <PackageCard
                      data={item}
                      fetchPackages={fetchPackages}
                      status_switch={true}
                      statusFilter={statusFilter}
                    />
                  </Col>
                ))
              ) : (
                <Col span={24}>
                  <Result
                    status="404"
                    title="Oops! No packages are found!"
                    subTitle="Sorry, you don't have any packages yet."
                  />
                </Col>
              )}
            </Row>
          </Spin>
        )}
      </Layout>
      <PackageForm
        open={packageModal}
        handelClose={() => setPackageModal(false)}
        fetchPackages={fetchPackages}
      />
    </>
  );
};

export default Packages;
