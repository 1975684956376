// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.antD-select-custom-popup {
  z-index: 9999;
}
.antD-select-custom-popup .rc-virtual-list {
  max-height: 180px !important;
  overflow: auto !important;
}
.antD-select-custom-popup .rc-virtual-list-holder {
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
  overflow: visible !important;
  overflow-y: visible !important;
}
.antD-select-custom-popup .rc-virtual-list-holder > div {
  height: unset !important;
  overflow: visible !important;
}
.antD-select-custom-popup .rc-virtual-list-holder .rc-virtual-list-holder-inner {
  position: static !important;
  transform: translateY(0) !important;
}
.antD-select-custom-popup .rc-virtual-list-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/common/CityAutoComplete/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EACE,4BAAA;EACA,yBAAA;AAEJ;AAAE;EACE,uCAAA;EAAA,kCAAA;EACA,4BAAA;EACA,8BAAA;AAEJ;AADI;EACE,wBAAA;EACA,4BAAA;AAGN;AADI;EACE,2BAAA;EACA,mCAAA;AAGN;AAAE;EACE,aAAA;AAEJ","sourcesContent":[".antD-select-custom-popup {\n  z-index: 9999;\n  .rc-virtual-list {\n    max-height: 180px !important;\n    overflow: auto !important;\n  }\n  .rc-virtual-list-holder {\n    max-height: fit-content !important;\n    overflow: visible !important;\n    overflow-y: visible !important;\n    & > div {\n      height: unset !important;\n      overflow: visible !important;\n    }\n    .rc-virtual-list-holder-inner {\n      position: static !important;\n      transform: translateY(0) !important;\n    }\n  }\n  .rc-virtual-list-scrollbar {\n    display: none;\n  }\n  // .rc-virtual-list-holder-inner{\n  //     max-height: 180px !important;\n  //     overflow: auto !important;\n  // }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
