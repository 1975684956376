import React, { useState } from "react";
import { Row, Col, Card, Button } from "antd";
import logo2 from "../Assets/logo2.png";
import { Link } from "react-router-dom";
import "./BizType.css";

const BusinessType = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  return (
    <Row className="businesstype-container">
      <Col span={3}>
        <div className="businesstype-logo-container">
          <img src={logo2} alt="Logo" className="businesstype-logo" />
        </div>
      </Col>
      <Col span={21} className="businesstype-Form-container">
        <p className="businesstype-title-container">Welcome to Planspace</p>
        <p className="business-type-heading">What is your business type?</p>
        <p className="business-type-select">Please Select</p>
        <div className="content-container">
          <div className="card-container">
            <div
              className={`businesstype-business-card ${selectedCard === "Venue" ? "selected" : ""}`}
              onClick={() => handleCardClick("Venue")}
              style={{
                backgroundColor: selectedCard === "Venue" ? "#54B4D3" : "",
              }}
            >
              Venue
            </div>
            <div
              className={`businesstype-business-card ${selectedCard === "SPA" ? "selected" : ""}`}
              onClick={() => handleCardClick("SPA")}
              style={{
                backgroundColor: selectedCard === "SPA" ? "#54B4D3" : "",
              }}
            >
              SPA
            </div>
            <div
              className={`businesstype-business-card ${selectedCard === "BARBER" ? "selected" : ""}`}
              onClick={() => handleCardClick("BARBER")}
              style={{
                backgroundColor: selectedCard === "BARBER" ? "#54B4D3" : "",
              }}
            >
              BARBER
            </div>
            <div
              className={`businesstype-business-card ${selectedCard === "HAIR STYLIST" ? "selected" : ""}`}
              onClick={() => handleCardClick("HAIR STYLIST")}
              style={{
                backgroundColor:
                  selectedCard === "HAIR STYLIST" ? "#54B4D3" : "",
              }}
            >
              HAIR STYLIST
            </div>
            <Link to="/businessname">
              <button className="businesstype-next-button">
                Save & go next
              </button>
            </Link>
          </div>
        </div>
        <div className="businesstype-input-container">
          <div className="businesstype-input-group">
            <p className="businesstype-business-name">Business Name</p>
            <input
              type="text"
              id="businessName"
              placeholder="Your business name"
              name="businessName"
              disabled
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default BusinessType;
