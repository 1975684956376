import React from "react";
import { Spin } from "antd";

const Loader = ({ minHeight, size }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: minHeight ? minHeight : "200px",
      }}
    >
      <Spin size={size ? size : "large"} />
    </div>
  );
};

export default Loader;
