import React, { useState } from "react";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import toast from "react-hot-toast";

const PasswordCheckList = ({ passwordErrors }) => {
  const [generatedPassword, setGeneratedPassword] = useState("");
  const [copySuccess, setCopySuccess] = useState(false);
  const generateRandomPassword = () => {
    const upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowerChars = "abcdefghijklmnopqrstuvwxyz";
    const specialChars = "!@#$%^&*()_";
    const numericalChars = "0123456789";

    const getRandomChar = (charSet) => {
      const randomIndex = Math.floor(Math.random() * charSet.length);
      return charSet[randomIndex];
    };

    let password = "";

    password += getRandomChar(upperChars);
    password += getRandomChar(lowerChars);
    password += getRandomChar(specialChars);
    password += getRandomChar(numericalChars);

    while (password.length < 8) {
      const charSet = upperChars + lowerChars + specialChars + numericalChars;
      password += getRandomChar(charSet);
    }
    password = password
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");

    setGeneratedPassword(password);
  };
  //to copy random genrated password
  const copyToClipboard = () => {
    if (generatedPassword) {
      const textArea = document.createElement("textarea");
      textArea.value = generatedPassword;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);

      setCopySuccess(true);
      toast.success("Password copied to clipboard");
    }
  };
  return (
    <div>
      <ul style={{ listStyle: "none", padding: 0 }}>
        <li style={{ display: "flex", alignItems: "center" }}>
          {passwordErrors.length ? (
            <CheckCircleOutlined
              style={{ color: "green", marginRight: "5px" }}
            />
          ) : (
            <CloseCircleOutlined style={{ color: "red", marginRight: "5px" }} />
          )}
          Atleast 8 character(s).
        </li>
        <li style={{ display: "flex", alignItems: "center" }}>
          {passwordErrors.hasDigit ? (
            <CheckCircleOutlined
              style={{ color: "green", marginRight: "5px" }}
            />
          ) : (
            <CloseCircleOutlined style={{ color: "red", marginRight: "5px" }} />
          )}
          Atleast 1 numeric character(s)
        </li>
        <li style={{ display: "flex", alignItems: "center" }}>
          {passwordErrors.hasUpperCase ? (
            <CheckCircleOutlined
              style={{ color: "green", marginRight: "5px" }}
            />
          ) : (
            <CloseCircleOutlined style={{ color: "red", marginRight: "5px" }} />
          )}
          Atleast 1 upper case character(s)
        </li>
      </ul>
      <Button
        style={{ marginTop: "10px" }}
        type="primary"
        onClick={(e) => {
          e.stopPropagation();
          generateRandomPassword();
        }}
      >
        Generate A Strong Password
      </Button>
      {generatedPassword && (
        <div style={{ textAlign: "center", fontSize: "15px" }}>
          <p>
            {generatedPassword}
            <Button
              type="text"
              icon={<CopyOutlined />}
              onClick={copyToClipboard}
              style={{ color: copySuccess ? "green" : "dark" }}
            ></Button>
          </p>
        </div>
      )}
    </div>
  );
};

export default PasswordCheckList;
