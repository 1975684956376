import { ReactComponent as Database } from "../../../assets/icons/database.svg";
import { ReactComponent as Apps } from "../../../assets/icons/apps.svg";
import { ReactComponent as Headphones } from "../../../assets/icons/headphones.svg";
import { ReactComponent as User } from "../../../assets/icons/user.svg";
import { ReactComponent as Dollar } from "../../../assets/icons/dollar.svg";

export const serviceIcons = [
  {
    color: "#F4ECFB",
    icon: <Database />,
  },
  {
    color: "#FFF0EA",
    icon: <Apps />,
  },
  {
    color: "#E9FAF7",
    icon: <Headphones />,
  },
  {
    color: "#FEECEE",
    icon: <User />,
  },
  {
    color: "#EAF8FF",
    icon: <Dollar />,
  },
];
