import React from "react";
import { Typography, Card, Form, Input, Row, Col,Grid } from "antd";
import InputMask from "react-input-mask";
import "../EndUserStyles.scss";
const { useBreakpoint } = Grid;

const GenerelInfoform = () => {
  const screens = useBreakpoint();
  const { Title } = Typography;

  return (
    <Card className="booking-card" style={{ marginBottom: 24 }}>
      <Title level={4}>General information</Title>
      <Row gutter={[16, 0]}>
        <Col span={screens.xs ? 24 : 11}>
          <Form.Item
            name="first_name"
            label={<span>First Name <span style={{ color: 'red' }}>*</span></span>}
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <Input size="large" placeholder="Ex: John Alexander" />
          </Form.Item>
        </Col>
        <Col span={screens.xs ? 24 : 11}>
          <Form.Item
            name="last_name"
            label={<span>Last Name <span style={{ color: 'red' }}>*</span></span>}

            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <Input size="large" placeholder="Ex: Smith" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
      <Col span={screens.xs ? 24 : 11}>
          <Form.Item
            name={"phone_number"}
            label={<span>Phone <span style={{ color: 'red' }}>*</span></span>}

            rules={[
              {
                required: true,
                message: "Phone number is required!",
              },
              {
                pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
                message: "Please enter valid phone!",
              },
            ]}
          >
            <InputMask mask="(999) 999-9999" maskChar="">
              {() => (
                <Input size="large" type="text" placeholder="(999) 999-9999" />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col span={screens.xs ? 24 : 11}>
          <Form.Item
            name={"email"}
            label={<span>Email <span style={{ color: 'red' }}>*</span></span>}

            rules={[
              {
                required: true,
                type: "email",
              },
            ]}
          >
            <Input size="large" type="email" placeholder="Type Email here..." />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default GenerelInfoform;
