import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const InactivityRedirect = ({ timeout, redirectUrl }) => {
  const navigate = useNavigate();

  useEffect(() => {
    let inactivityTimeout;

    const resetInactivityTimeout = () => {
      clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(() => {
        navigate(redirectUrl);
        localStorage.setItem("access_token", "");
      }, timeout);
    };

    const handleUserActivity = () => {
      resetInactivityTimeout();
    };

    // Attach event listeners to detect user activity
    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keydown", handleUserActivity);
    document.addEventListener("wheel", handleUserActivity);

    // Set up initial inactivity timeout
    resetInactivityTimeout();

    // Clean up event listeners on component unmount
    return () => {
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
      document.addEventListener("wheel", handleUserActivity);
      clearTimeout(inactivityTimeout);
    };
  }, [timeout, redirectUrl]);

  return "";
};

export default InactivityRedirect;
