import React, { useState, useEffect } from "react";
// UI's
import {
  Spin,
  Form,
  Space,
  Button,
  Card,
  Select,
  Upload,
  Input,
  Typography,
} from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import axios from "axios";
import { jsPDF } from "jspdf";
import { saveAs } from "file-saver";
import usePopup from "../../../../../common/MessageModal/usePopup";

import {
  addContract,
  getContract,
  updateContract,
  getContractTemplates,
  deleteFile,
  downloadFile,
} from "../../../../../services/events";
import ShareModal from "./ShareModal";
import { ReactComponent as PdfFile } from "../../../../../assets/icons/pdf.svg";
import { ReactComponent as DownloadIcon } from "../../../../../assets/icons/download.svg";
import { ReactComponent as ShareIcon } from "../../../../../assets/icons/share.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/icons/trash.svg";
import { ReactComponent as PencilIcon } from "../../../../../assets/icons/pencil.svg";

import emptyScreen from "../../../../../assets/empty.svg";
import "./styles.scss";
import client from "../../../../../axios";

const Contract = ({ event }) => {
  const popup = usePopup();
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Title } = Typography;
  const [addNew, setAddNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tempLoading, setTempLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [contract, setContract] = useState([]);
  const [fileUploading, setFileUploading] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [share, setShare] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectValue, setSelectValue] = useState(null);

  const fetchContract = async () => {
    setEdit(false);
    setSelectValue(null);
    setLoading(true);
    try {
      const res = await getContract(event?.id);
      setContract(res?.data?.results);
    } catch (error) {
      popup.error("Opss!", error.response.data.message, error);
    } finally {
      setLoading(false);
    }
  };

  const fetchContractTemplates = async () => {
    setTempLoading(true);
    try {
      const res = await getContractTemplates();
      setTemplates(res?.data?.data);
    } catch (error) {
      popup.error("Opss!", error?.response?.data?.message, error);
    } finally {
      setTempLoading(false);
    }
  };

  useEffect(() => {
    fetchContract();
    fetchContractTemplates();
  }, []);

  useEffect(() => {
    if (contract.length) {
      form.setFieldValue("title", contract[0].title);
      form.setFieldValue("terms_html", contract[0]?.terms_html);
    }
  }, [contract]);

  // File Upload
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const contractUploadAction = (file) => {
    if (file) {
      setUploadFile(file.file.originFileObj);
      addFileToTerm();
    } else {
      setUploadFile(null);
    }
  };

  const addFileToTerm = async () => {
    if (!uploadFile) return;
    setFileUploading(true);
    let formData = new FormData();
    formData.append("files", uploadFile);
    try {
      await updateContract(contract[0]?.id, formData);
      setUploadFile(null);
      fetchContract();
    } catch (error) {
      popup.error("Opss!", error.response.data.message);
    } finally {
      setFileUploading(false);
    }
  };

  const handleDownloadPdf = async () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    const text = contract[0].terms_html;

    const textLines = doc.splitTextToSize(text, 190);

    let offsetY = 10; // Initial Y position
    let page = 1;

    textLines.forEach((line, index) => {
      if (index > 0 && index % 25 === 0) {
        // Start a new page
        doc.addPage();
        page++;
        offsetY = 10;
      }

      doc.text(10, offsetY, line);
      offsetY += 10;
    });

    doc.save(`planspace-contract.pdf`);
  };

  const handleSubmit = async (values) => {
    setSaveLoading(true);
    const payload = { ...values, event: event?.id };
    try {
      if (edit) {
        const res = await updateContract(contract[0]?.id, values);
        popup.success("Success!", res?.data?.message);
      } else {
        const res = await addContract(payload);
        popup.success("Success!", res?.data?.message);
      }
      fetchContract();
      setEdit(false);
      form.resetFields();
      setAddNew(false);
    } catch (error) {
      popup.error("Opss!", error?.response?.data?.message);
    } finally {
      setSaveLoading(false);
    }
  };

  const deleteAFile = async (id) => {
    try {
      const res = await deleteFile(id);
      fetchContract();
      popup.success("Success!", res?.data?.message);
    } catch (error) {
      popup.error("Opss!", error?.response?.data?.message);
    }
  };

  const handleDownloadClick = async (file) => {
    try {
      const response = await client.get(
        `/api/event/terms/file/${file?.id}/download/`,
        { responseType: "blob" },
      );
      const blob = new Blob([response.data]);
      const blobUrl = window.URL.createObjectURL(blob);
      const aElement = document.createElement("a");
      aElement.href = blobUrl;
      aElement.setAttribute("download", file?.name);
      document.body.appendChild(aElement);
      aElement.click();
      document.body.removeChild(aElement);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const handleSetTempateValue = () => {
    const title = form.getFieldValue("title");
    const terms_html = form.getFieldValue("terms_html");
    const find = templates?.length
      ? templates.find(
          (item) => item.title === title && item.terms_html === terms_html,
        )
      : null;
    setSelectValue(
      find?.title ? { label: find.title, value: find.title, data: find } : null,
    );
  };

  useEffect(() => {
    if (edit) {
      handleSetTempateValue();
    }
  }, [edit]);

  if (loading) {
    return (
      <Card
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "400px",
        }}
      >
        <Spin size={"large"} />
      </Card>
    );
  }

  return (
    <div>
      {contract?.length || addNew ? (
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          onFinish={handleSubmit}
        >
          <Card>
            <Space
              align="center"
              style={{
                marginBottom: "0.5em",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Title level={4} style={{ marginBottom: 0 }}>
                Contract
              </Title>
              {contract.length ? (
                <div className="actions">
                  <Button
                    onClick={handleDownloadPdf}
                    icon={<DownloadIcon />}
                    type="text"
                  />
                  <Button
                    icon={<ShareIcon />}
                    type="text"
                    onClick={() => setShare(true)}
                  />
                  <Button
                    icon={<PencilIcon />}
                    type="text"
                    onClick={() => setEdit(true)}
                  />
                </div>
              ) : null}
            </Space>
            <div>
              {!contract.length || edit ? (
                <Form.Item label="Select Template">
                  <Select
                    className="text-area"
                    placeholder="Select"
                    size="large"
                    value={selectValue}
                    style={{ width: "100%" }}
                    loading={tempLoading}
                    options={
                      templates?.length
                        ? templates.map((item, i) => ({
                            label: item.title,
                            value: item.title + i,
                            data: item,
                          }))
                        : []
                    }
                    onDropdownVisibleChange={(open) => {
                      if (open) {
                        fetchContractTemplates();
                      }
                    }}
                    onChange={(e, obj) => {
                      setSelectValue({
                        label: obj.label,
                        value: obj.value,
                        data: obj.data,
                      });
                      form.setFieldValue("title", obj?.data?.title);
                      form.setFieldValue("terms_html", obj?.data?.terms_html);
                    }}
                  />
                </Form.Item>
              ) : null}

              <Form.Item
                name="title"
                label="Title"
                rules={[
                  {
                    required: true,
                    message: "Contract Title is required!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter contract title"
                  size="large"
                  readOnly={contract.length && !edit}
                  onBlur={() => {
                    handleSetTempateValue();
                  }}
                />
              </Form.Item>
              <Form.Item
                name="terms_html"
                label="Contract"
                rules={[
                  {
                    required: true,
                    message: "Contract is required!",
                  },
                ]}
              >
                <TextArea
                  autoSize={{ minRows: 5, maxRows: 12 }}
                  placeholder="Template description"
                  size="large"
                  readOnly={contract.length && !edit}
                  onBlur={() => {
                    handleSetTempateValue();
                  }}
                />
              </Form.Item>
              {edit ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 20,
                  }}
                >
                  <Button
                    size="large"
                    style={{
                      marginRight: 10,
                      marginLeft: "auto",
                    }}
                    onClick={() => {
                      if (contract.length) {
                        form.setFieldValue("title", contract[0].title);
                        form.setFieldValue(
                          "terms_html",
                          contract[0]?.terms_html,
                        );
                      }
                      setEdit(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={saveLoading}
                  >
                    Update Contract
                  </Button>
                </div>
              ) : null}
            </div>
          </Card>
          {contract?.length ? (
            <Card style={{ width: "100%", marginTop: "20px" }}>
              <Space>
                <Title level={4}>Files Attachment</Title>
              </Space>

              <p
                style={{
                  marginTop: "7px",
                  fontSize: "14px",
                  color: "#777980",
                  marginBottom: 8,
                }}
              >
                Files
              </p>
              {contract[0]?.files?.length ? (
                <div className="uploaded-files-wrapper">
                  {contract[0].files.map((item, i) => (
                    <div className="file" key={i}>
                      <PdfFile />
                      <div>
                        <h5>{item?.name}</h5>
                        <p>{item?.size}</p>
                      </div>
                      <div className="actions">
                        <Button
                          icon={<DownloadIcon />}
                          type="text"
                          onClick={() => handleDownloadClick(item)}
                        />
                        {/* <Button icon={<ShareIcon />} type="text" onClick={() => setShare(true)} /> */}
                        <Button
                          icon={<DeleteIcon />}
                          onClick={() => {
                            popup.confirm(
                              "Delete File?",
                              "Are you sure? This action is permanent and cannot be undone.",
                              () => deleteAFile(item?.id),
                            );
                          }}
                          type="text"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}

              <Upload
                name="file"
                multiple={false}
                customRequest={dummyRequest}
                onChange={contractUploadAction}
                accept=".doc, .docx, .pdf"
                showUploadList={false}
                disabled={fileUploading}
              >
                <Button loading={fileUploading} className="add-files-btn">
                  Add Files
                </Button>
              </Upload>
            </Card>
          ) : null}
          {!contract.length ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={saveLoading}
                style={{
                  marginTop: 20,
                  marginLeft: "auto",
                }}
              >
                Save Contract
              </Button>
            </div>
          ) : null}
        </Form>
      ) : (
        <Card>
          <div className="no-data">
            <img src={emptyScreen} alt="no contracts" />
            <h3>Oops! Its Empty Here...</h3>
            <p>Add new contract by clicking below button.</p>
            <Button
              icon={<PlusCircleFilled />}
              onClick={() => setAddNew(true)}
              type="primary"
            >
              Add New Contract
            </Button>
          </div>
        </Card>
      )}
      <ShareModal
        open={share}
        handelClose={() => setShare(false)}
        eventId={event?.id}
      />
    </div>
  );
};

export default Contract;
