import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, Card, Row, Col, Spin, Typography, Avatar, Modal } from "antd";
import {
  EnvironmentFilled,
  AlipayCircleFilled,
  DeleteFilled,
} from "@ant-design/icons";

import VenueForm from "./comps/forms/VenueForm";
import Rooms from "./Rooms";
import Loader from "../../common/Loader";
import { getVenue, deleteVenue } from "../../services/venues";
import usePopup from "../../common/MessageModal/usePopup";

import { ReactComponent as EditIcon } from "../../assets/icons/fill-pencil.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as BuildingIcon } from "../../assets/icons/building.svg";
import { ReactComponent as EmailIcon } from "../../assets/icons/fill-envelope.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/smartphone.svg";
import bgImage from "../../assets/background.jpg";
import "./styles/venue-details.scss";
import Layout from "./Layout";

const VenueDetails = () => {
  const popup = usePopup();
  const navigate = useNavigate();
  const { id } = useParams();
  const { Title } = Typography;
  const { confirm } = Modal;
  const [venue, setVenue] = useState(null);
  const [edit, setEdit] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchVenue = async () => {
    setLoading(true);
    try {
      const res = await getVenue(id);
      setVenue(res?.data);
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = "/server-error";
        console.log("Redirecting to server error page...");
      } else {
        popup.error("Oops...", error?.response?.data?.detail, error);
        navigate("/company/venues");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchVenue();
    }
  }, [id]);

  function formatPhoneNumber(phoneNumber) {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  }

  const get_formatted_value = (value) => {
    if (!value || value === "null" || value === "undefined") {
      return "-";
    } else {
      return value;
    }
  };

  const details = [
    {
      title: "Company",
      value: get_formatted_value(venue?.company),
      icon: <BuildingIcon />,
    },
    {
      title: "Email",
      value: get_formatted_value(venue?.email),
      icon: <EmailIcon />,
    },
    {
      title: "Phone",
      value: formatPhoneNumber(venue?.phone),
      icon: <PhoneIcon />,
    },
    {
      title: "Address",
      value: get_formatted_value(venue?.address_line1),
      icon: <EnvironmentFilled />,
    },
    {
      title: "City",
      value: get_formatted_value(venue?.zip_code?.city),
      icon: <AlipayCircleFilled />,
    },
  ];

  const deleteThisVenue = async () => {
    try {
      const res = await deleteVenue(venue?.id);
      popup.success("Success!", res?.data?.message);
      navigate("/company/venues");
    } catch (error) {
      popup.error(venue?.name, error?.response?.data?.message);
    }
  };

  return (
    <Layout
      title={"Venue Details"}
      addButton={
        <div style={{ display: "flex", gap: 12 }}>
          {venue?.id ? (
            <>
              <Button
                size="large"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                icon={<DeleteIcon />}
                onClick={() =>
                  popup.confirm(
                    venue?.name,
                    "Are you sure to delete this venue?",
                    deleteThisVenue,
                  )
                }
              />
              <Button
                type="primary"
                size="large"
                icon={<EditIcon />}
                onClick={() => setEdit(true)}
              >
                Edit
              </Button>
            </>
          ) : null}
        </div>
      }
      items={[
        { title: "Company Settings" },
        { title: <Link to={"/company/venues"}>Venues</Link> },
        { title: "Venue Details" },
      ]}
    >
      {!venue && loading ? (
        <Loader minHeight={400} />
      ) : (
        <Spin spinning={loading}>
          <Card className="sub-section-card">
            <Row gutter={[36, 36]}>
              <Col span={24} xs={24} xl={10}>
                {venue?.images?.length ? (
                  <Row gutter={[12, 12]}>
                    {venue?.images.map((img, i) => (
                      <Col key={i} span={24} xs={!i ? 24 : 12}>
                        <div className="venue-image">
                          <img src={img?.image} alt="venue" />
                        </div>
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <Row gutter={[12, 12]}>
                    <Col span={24} xs={24}>
                      <div className="venue-image">
                        <img src={bgImage} alt="venue" />
                      </div>
                    </Col>
                    <Col span={24} md={12}>
                      <div className="venue-image">
                        <img src={bgImage} alt="venue" />
                      </div>
                    </Col>
                    <Col span={24} md={12}>
                      <div className="venue-image">
                        <img src={bgImage} alt="venue" />
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col span={24} xs={24} xl={14}>
                <Title level={3}>{venue?.name}</Title>
                <Row style={{ marginTop: 42 }} gutter={[16, 24]}>
                  {details?.map((item, i) =>
                    item?.value ? (
                      <Col key={i} span={24} lg={12}>
                        <div className="wrapper">
                          <Avatar
                            icon={item?.icon}
                            size={40}
                            style={{ backgroundColor: "#F0F1F3" }}
                          />
                          <div>
                            <Title level={5} type="secondary">
                              {item?.title}
                            </Title>
                            <Title level={5}>{item?.value || "-"}</Title>
                          </div>
                        </div>
                      </Col>
                    ) : null,
                  )}
                </Row>
              </Col>
            </Row>
          </Card>
          <Rooms venue_id={venue?.id} />
        </Spin>
      )}

      <VenueForm
        fetchVenues={fetchVenue}
        open={edit}
        handelClose={() => setEdit(false)}
        venue={{ ...venue, type: "edit" }}
      />
    </Layout>
  );
};

export default VenueDetails;
