import React from "react";
import { Typography, Button, Progress, Breadcrumb } from "antd";
import { CaretRightFilled } from "@ant-design/icons";

import Company from "./Company";

import "./style.scss";

const Layout = ({ title, addButton, items, children }) => {
  const { Title } = Typography;

  return (
    <div className="company-settings-layout">
      <Company />
      <div className="page-header">
        <div>
          <Title level={3}>{title || "Untitled"}</Title>
          <Breadcrumb separator={<CaretRightFilled />} items={items} />
        </div>
        {addButton}
      </div>
      {children}
    </div>
  );
};

export default Layout;
