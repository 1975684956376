// import React, { useState } from 'react';
// import { Button, Modal, Card, Space } from 'antd';
// import styles from '../../../events.module.scss';
// import { ReactComponent as Uncheck } from '../../../../../assets/icons/uncheck.svg';
// import { ReactComponent as Checked } from '../../../../../assets/icons/checked.svg';
// import './billings.scss';
// import { createPayment } from '../../../../../services/events';

// const AddPayments = ({ open, handelClose, totalPrice, fetchAllPayments, eventId }) => {
//     const [loading, setLoading] = useState(false);
//     const [selected, setSelected] = useState('');

//     const handleCancel = () => {
//         handelClose(false);
//     }

//     const options = [
//         {
//             title: 'Single Payment',
//             price: totalPrice,
//             description: 'Full Invoice Payment will be taken at the checkout.',
//             value: 1,
//         },
//         {
//             title: '2 Payments',
//             price: parseFloat((totalPrice / 2).toFixed(2)),
//             description: 'Payment will be split into two equal Payments. One payment will be accepted now another 2nd will be scheduled.',
//             value: 2,
//         },
//         {
//             title: '3 Payments',
//             price: parseFloat((totalPrice / 3).toFixed(2)),
//             description: 'Payment will be split into three equal Payments. One payment will be accepted now another other will be scheduled.',
//             value: 3,
//         },
//     ];

//     const addNewpayment = async () => {
//         setLoading(true);
//         try {
//             await createPayment({
//                 event: eventId,
//                 status: "sched_installment",
//                 payment_expected: totalPrice,
//                 no_of_installments: selected,
//             });
//             fetchAllPayments();
//             handleCancel();
//         } catch (err) {
//             console.error('Error', err);
//         } finally {
//             setLoading(false);
//         }
//     }

//     const renderdButtons = () => {
//         return [
//             <Button size={'large'} key="cancel" onClick={handleCancel}>
//                 Cancel
//             </Button>,
//             <Button
//                 loading={loading}
//                 onClick={addNewpayment}
//                 size={'large'}
//                 key="create"
//                 type="primary"
//                 disabled={!selected}
//             >
//                 Create
//             </Button>
//         ]
//     }

//     return (
//         <Modal
//             className={styles.createModal}
//             centered
//             title="Select Payment Plan"
//             open={open}
//             onCancel={handleCancel}
//             footer={renderdButtons()}
//         >
//             <Space direction="vertical" size={16} style={{ width: '100%' }}>
//                 {options.map((item, i) => (
//                     <Card
//                         key={i}
//                         title={item.title}
//                         extra={selected === item.value ? <Checked /> : <Uncheck />}
//                         className={`payment-modal-card ${selected === item.value && 'active'}`}
//                         style={{
//                             width: '100%',
//                             cursor: 'pointer'
//                         }}
//                         onClick={() => setSelected(item.value)}
//                     >
//                         <h4>${item.price}</h4>
//                         <p>{item.description}</p>
//                     </Card>
//                 ))}
//             </Space>
//         </Modal>
//     )
// }

// export default AddPayments

import React, { useState } from "react";
import { Button, Modal, Space, Select, message } from "antd";
import styles from "../../../events.module.scss";
import { createPayment } from "../../../../../services/events";
import "./billings.scss";

const AddPayments = ({
  open,
  handelClose,
  totalPrice,
  fetchAllPayments,
  eventId,
}) => {
  const [loading, setLoading] = useState(false);
  const [customPayments, setCustomPayments] = useState([
    { amount: totalPrice },
  ]);
  const [numInstallments, setNumInstallments] = useState("");

  const handleCancel = () => {
    setCustomPayments([{ amount: totalPrice }]);
    handelClose(false);
  };

  const handleNumInstallmentsChange = (value) => {
    setNumInstallments(value);
    const newCustomPayments = Array.from({ length: value }, (_, index) => ({
      amount: index < customPayments.length ? customPayments[index].amount : 0,
    }));
    setCustomPayments(newCustomPayments);
  };

  const addNewPayment = async () => {
    if (!numInstallments) {
      message.error("Please select a payment plan");
      return;
    }
    setLoading(true);
    try {
      for (const payment of customPayments) {
        if (payment.amount > 0) {
          await createPayment({
            event: eventId,
            status: "sched_installment",
            payment_expected: payment.amount,
            no_of_installments: numInstallments,
          });
        }
      }
      fetchAllPayments();
      handleCancel();
    } catch (err) {
      console.error("Error creating payment:", err);
    } finally {
      setLoading(false);
    }
  };

  const renderButtons = () => (
    <>
      <Button size="large" key="cancel" onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        loading={loading}
        onClick={addNewPayment}
        size="large"
        key="create"
        type="primary"
      >
        Create
      </Button>
    </>
  );

  return (
    <Modal
      className={styles.createModal}
      centered
      title="Create Custom Payment Plan"
      open={open}
      onCancel={handleCancel}
      footer={renderButtons()}
    >
      <Space direction="vertical" size={16} style={{ width: "100%" }}>
        <Select
          onChange={handleNumInstallmentsChange}
          style={{ width: "100%" }}
          placeholder="Choose installment"
        >
          {[...Array(5)].map((_, i) => (
            <Select.Option key={i + 1} value={i + 1}>
              {i + 1} Installments
            </Select.Option>
          ))}
        </Select>
        {numInstallments ? (
          <>
            <p>Payments will be split in {numInstallments} Installments</p>
          </>
        ) : (
          <></>
        )}{" "}
      </Space>
    </Modal>
  );
};

export default AddPayments;
