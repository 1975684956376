import {
  WEBSITE_INTEGRATIONS,
  WEBSITE_INTEGRATIONS_DETAILS,
  END_USER_EVENT_CREATION,
  END_USER_EVENT_TYPES,
} from "./endpoints";
import client from "../axios";

export const generateCode = async (payload) => {
  return await client.post(WEBSITE_INTEGRATIONS, payload);
};

export const getIntigrationDetails = async (url) => {
  return await client.get(
    WEBSITE_INTEGRATIONS_DETAILS + `?generated_url=${url}`,
  );
};
export const getIntigrationEventType = async (url) => {
  return await client.get(
    END_USER_EVENT_TYPES +`?generated_url=${url}`,
  );
};

export const createEndUserEvent = async (payload) => {
  return await client.post(END_USER_EVENT_CREATION, payload);
};

export const deleteEventAsGuest = async (id) => {
  return await client.delete(END_USER_EVENT_CREATION + id + "/");
};