import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import styles from "../events.module.scss";
import usePopup from "../../../common/MessageModal/usePopup";
import "./styles.css";
// UI's
import { Typography, Space, Row, Col, Button, Card, Table, Spin } from "antd";
import { CaretRightOutlined, EditOutlined } from "@ant-design/icons";

// static Images and icons from sourcefiles Folder
import MenuHeader from "./MenuHeader";
import Tasks from "./comps/Tasks";
import Appointments from "./comps/Appointments";
import StagesDropdown from "../../Events/comps/StagesDropdown";
import StatusDropdown from "../../Events/comps/StatusDropdown";
import { getEvent } from "../../../services/events";
import CreateEvent from "../CreateEvent";

const EventInfo = () => {
  const popup = usePopup();
  const { state: statEvent } = useLocation();
  const navigate = useNavigate();
  const { Title } = Typography;
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState(null);
  const [updateEvent, setUpdateEvent] = useState(false);

  const fetchEvent = async (id) => {
    setLoading(true);
    try {
      if (id === "no_loading") {
        id = event?.id;
      }
      const res = await getEvent(id ? id : event?.id);
      setEvent(res?.data);
    } catch (error) {
      popup.error("Oops!", error?.response?.data?.message, error);
    } finally {
      setLoading(false);
    }
  };

  // if state object is empty from location
  useEffect(() => {
    if (statEvent?.title) {
      setEvent(statEvent);
    } else if (statEvent?.id && !statEvent?.title) {
      fetchEvent(statEvent?.id);
    } else {
      navigate("/events");
    }
  }, [statEvent]);

  const columns = [
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
    },
    {
      title: "Stage",
      dataIndex: "Stage",
      key: "Stage",
    },
    {
      title: "Event Date",
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "Event Time",
      dataIndex: "Time",
      key: "Time",
    },
    {
      title: "Organizer",
      dataIndex: "Organizer",
      key: "Organizer",
    },
    {
      title: "Email",
      key: "Email",
      dataIndex: "Email",
    },
    {
      title: "Phone",
      key: "Phone",
      dataIndex: "Phone",
    },
  ];

  const dataSource = [
    {
      key: "1",
      Status: (
        <StatusDropdown
          eventId={event?.id}
          value={event?.status}
          size="small"
          fetchEvents={fetchEvent}
        />
      ),
      Stage: (
        <StagesDropdown
          event={event}
          value={event?.stage}
          size="small"
          fetchEvents={fetchEvent}
        />
      ),
      Date: moment(event?.event_date).format("DD MMM, YYYY"),
      Time: `${moment(event?.start_time, "HH:mm:ss").format("LT")} to ${moment(event?.end_time, "HH:mm:ss").format("LT")}`,
      Organizer: event?.contacts[0]?.full_name || "-",
      Email: event?.contacts[0]?.email || "-",
      Phone: event?.contacts[0]?.phone_number || "-",
    },
  ];

  return (
    <Spin style={{ minHeight: 200 }} spinning={loading}>
      {event ? (
        <>
          <Space className={styles.header}>
            <Title level={2}>Events</Title>
          </Space>

          <div className="events-details">
            <p
              className="event-head"
              style={{ color: "#0B98D2" }}
              onClick={() => navigate("/events")}
            >
              Events{" "}
            </p>
            <p
              className="event-head"
              style={{ color: "#667085", pointerEvents: "none" }}
            >
              <CaretRightOutlined />
              &nbsp;Events Details
            </p>
          </div>

          <Card>
            <Space className="navigationBar">
              <Title level={5}>{event?.title}</Title>
              <Button
                icon={<EditOutlined />}
                size={"medium"}
                onClick={() =>
                  setUpdateEvent({ ...event, type: "edit", from: "workspace" })
                }
              />
            </Space>
            <Table
              bordered={false}
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              className="table"
            />
          </Card>

          <div>
            <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Col span={16}>
                <MenuHeader event={event} setEvent={setEvent} />
              </Col>
              <Col span={8} style={{ marginTop: "67px" }}>
                <div style={{ marginLeft: "20px" }}>
                  <Tasks eventId={event?.id} />
                  <Appointments eventId={event?.id} />
                </div>
              </Col>
            </Row>
          </div>
          {updateEvent ? (
            <CreateEvent
              open={updateEvent}
              handelClose={(val) => setUpdateEvent(val)}
              refreshEvents={fetchEvent}
            />
          ) : null}
        </>
      ) : null}
    </Spin>
  );
};

export default EventInfo;
