import {
  EVENT,
  GET_EVENTS_TYPES,
  GET_EVENTS_STAGES,
  GET_EVENTS_AVAILABILITY,
  PAYMENTS,
  PAYMENT_ACTIONS,
  CONTRACTS,
  TERMS_TEMPLATES,
  DELETE_FILE,
  GET_UPDATES,
  TASKS,
  APPOINTMENTS,
  SHARE,
  DOWNLOAD_FILE,
  CREATE_CHECKOUT_SESSION,
  CREATE_SETUP_INTENT,
  SAVE_PAYMENT_METHOD,
  PAYMENT_METHOD_LIST,
  CREATE_CHECKOUT_SESSION_AS_GUEST,
} from "./endpoints";
import client from "../axios";

// Create new Events
export const createNewEvent = async (payload) => {
  return await client.post(EVENT, payload);
};

// Update Event by ID
export const updateEvent = async (id, payload) => {
  return await client.put(EVENT + id + "/", payload);
};

// Delete Event by ID
export const deleteEvent = async (id) => {
  return await client.delete(EVENT + id + "/");
};

// Get all Events
export const getEvents = async (params) => {
  const queryParams = params
    ? Object.entries(params)
        ?.filter(([key, value]) => value)
        ?.map(([key, value]) => `${key}=${value}`)
        .join("&")
    : "";
  return await client.get(EVENT + "?" + queryParams);
};

// Get Event
export const getEvent = async (id) => {
  return await client.get(EVENT + id);
};

// Get Event Types List
export const getEventTypes = async () => {
  return await client.get(GET_EVENTS_TYPES);
};

// Get Event Stages List
export const getEventStages = async (status) => {
  return await client.get(
    GET_EVENTS_STAGES +
      "?page_size=50" +
      (status ? `&event_status=${status}` : ""),
  );
};

// Get Event Availability
export const getEventAvailability = async (id) => {
  return await client.get(GET_EVENTS_AVAILABILITY + "?location=" + id);
};

// Create new Payments
export const createPayment = async (payload) => {
  return await client.post(PAYMENTS, payload);
};

// Made new Payments
export const madePayment = async (payload) => {
  return await client.post(PAYMENT_ACTIONS, payload);
};
// Update Payments
export const updatePayment = async (id, payload) => {
  return await client.put(PAYMENTS + id + "/", payload);
};

// Get Payments
export const getPayments = async (id) => {
  return await client.get(PAYMENTS + "?event=" + id);
};

// Get Payments
export const refundPayment = async (id) => {
  return await client.post(PAYMENTS + "refund/", { payment_id: id });
};

// Get Contracts
export const getContract = async (event_id) => {
  return await client.get(CONTRACTS + "?event=" + event_id);
};

// Add New Contract
export const addContract = async (payload) => {
  return await client.post(CONTRACTS, payload);
};

// Update Contract
export const updateContract = async (contract_id, payload) => {
  return await client.put(CONTRACTS + contract_id + "/", payload);
};

// Get Templates
export const getContractTemplates = async () => {
  return await client.get(TERMS_TEMPLATES + "?source=event");
};

// Delete File
export const deleteFile = async (id) => {
  return await client.delete(DELETE_FILE + id);
};

// Donwload Term File
export const downloadFile = async (id) => {
  return await client.get(DOWNLOAD_FILE + id);
};

// Get Updates
export const getUpdates = async (id) => {
  return await client.get(GET_UPDATES + "?event=" + id);
};

// Get Tasks
export const getTasks = async (id) => {
  return await client.get(TASKS + "?event=" + id);
};

// Add New Tasks
export const addNewTask = async (payload) => {
  return await client.post(TASKS, payload);
};
// Add New Tasks
export const updateTask = async (id, payload) => {
  return await client.put(TASKS + id + "/", payload);
};

// Get Appointments
export const getAppointments = async (id) => {
  return await client.get(APPOINTMENTS + "?event=" + id);
};

// Add New Appointments
export const addNewAppointment = async (payload) => {
  return await client.post(APPOINTMENTS, payload);
};

// Add New Appointments
export const updateAppointment = async (id, payload) => {
  return await client.put(APPOINTMENTS + id + "/", payload);
};

// Share on Email
export const shareOnEmail = async (payload) => {
  return await client.post(SHARE, payload);
};

// Create Checkout session
export const createCheckoutSession = async (payload) => {
  return await client.post(CREATE_CHECKOUT_SESSION, payload);
};

// Create Checkout session as guest
export const createCheckoutSessionAsGuest = async (payload) => {
  return await client.post(CREATE_CHECKOUT_SESSION_AS_GUEST, payload);
};

// Create setup intent
export const createSetupIntent = async (payload) => {
  return await client.post(CREATE_SETUP_INTENT, payload);
};

// Save payment method
export const savePaymentMethod = async (payload) => {
  return await client.post(SAVE_PAYMENT_METHOD, payload);
};

// Get payment methods
export const getPaymentMethods = async (eventID) => {
  return await client.get(PAYMENT_METHOD_LIST + "?event=" + eventID);
};
