import React from "react";
import { Card, Collapse } from "antd";

const { Panel } = Collapse;

const Notification = () => {
  return (
    <div className="Profile-Container">
      <Card title="Notifications" style={{ width: 800 }}>
        <Collapse accordion bordered={false} style={{ background: "white" }}>
          <Panel
            header={
              <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                Notification Preferences
              </span>
            }
            key="1"
          >
            <p>This is the content of Notification Preferences.</p>
          </Panel>
          <Panel
            header={
              <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                Leads & Events
              </span>
            }
            key="2"
          >
            <p>This is the content of Leads & Events.</p>
          </Panel>
          <Panel
            header={
              <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                Payments
              </span>
            }
            key="3"
          >
            <p>This is the content of Payments.</p>
          </Panel>
          <Panel
            header={
              <span style={{ fontWeight: "bold", fontSize: "16px" }}>Misc</span>
            }
            key="4"
          >
            <p>This is the content of Misc.</p>
          </Panel>
        </Collapse>
      </Card>
    </div>
  );
};

export default Notification;
