import { Modal } from "antd";
import React, { useState, useEffect } from "react";
import "./EditLocation.scss";

import { ReactComponent as CrossIcon } from "../../../../assets/Modals/CrossIcon.svg";
import { ReactComponent as BackArrow } from "../../../../assets/Modals/backArrow.svg";
import { Input, Row, Col } from "antd";
import AddNewRoom from "../AddNewRoom";
import AddImage from "../AddImage";
import ImageUploader from "../../../../components/ImageUploader";
import axios from "axios";
import { Spin } from "antd";
import { Upload } from "antd";
import PhoneMask from "../../../../common/PhoneMask";
import ZipCodeMask from "../../../../common/ZipCodeMask";
import MessageModal from "../MessageModal";

const options = [];
for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}

const EditLocation = ({
  open,
  setOpen,
  getVenues,
  venueData,
  // setIsVenueDetails,
}) => {
  const [isVenueDetails, setIsVenueDetails] = useState(true);
  const [isfirstScreen, setIsFirstScreen] = useState(true);
  // eslint-disable-next-line
  const [isSecondScreen, setIsSecondScreen] = useState(false);
  const [isAddNewRoom, setIsAddNewRoom] = useState(false);
  const [isAddImage, setIsAddImage] = useState(false);
  const [locationName, setLocationName] = useState(venueData?.name);
  const [locationAddress, setLocationAddress] = useState(
    venueData?.address_line1,
  );
  const [locationCity, setLocationCity] = useState(venueData?.zip_code?.city);
  const [locationState, setLocationState] = useState(
    venueData?.zip_code?.state,
  );
  const [isSuccessModalOpen, setIsSuccessModelOpen] = useState(false);
  const [autoCloseModal, setAutoCloseModal] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);

  const [locationZipCode, setLocationZipCode] = useState(
    venueData?.zip_code?.zip_code,
  );
  const [phone, setPhone] = useState(venueData?.phone);
  const [email, setEmail] = useState(venueData?.email);
  const [isSpin, setIsSpin] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadImageURL, setUploadImageURL] = useState("");

  const [isEmailError, setEmailError] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [images, setImages] = useState(venueData?.images);

  useEffect(() => {
    if (isSuccessModalOpen) {
      const timer = setTimeout(() => {
        setIsSuccessModelOpen(false);
        setAutoCloseModal(true);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isSuccessModalOpen]);

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const updateLocation = () => {
    try {
      setIsSpin(true);
      const formData = new FormData();
      if (file) {
        formData.append("image", file);
      }
      formData.append("name", locationName);
      formData.append("address_line1", locationAddress);
      formData.append("city", locationCity);
      formData.append("state", locationState);
      formData.append("zip_code", locationZipCode);
      formData.append("phone", phone);
      formData.append("email", email);
      if (images.length > 0) {
        for (let i = 0; i < images.length; i++) {
          formData.append("images", images[i].originFileObj);
        }
      }
      axios
        .put(
          `${process.env.REACT_APP_BASE_API_URL}/api/company/location/${venueData?.id}/`,
          formData,
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem("access_token")}`,
            },
          },
        )
        .then((res) => {
          setIsSuccessModelOpen(true);
          setUploadImageURL(null);
          setOpen(false);
          setLocationName("");
          setLocationAddress("");
          setLocationCity("");
          setLocationState("");
          setLocationZipCode("");
          setPhone("");
          setEmail("");
          setIsSpin(false);
          getVenues();
          setIsVenueDetails(false);
          setImages([]);
        });
    } catch (error) {
      setIsSpin(false);
    }
  };

  const dummyRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    customRequest: dummyRequest,
    onRemove: (file) => {
      // setFile(null);
      // setUploadImageURL("");
    },

    // beforeUpload(file, fileList) {
    //   const isJpgOrPng =
    //     file.type === "image/jpeg" || file.type === "image/png";
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   if (!isJpgOrPng) {
    //     setMsgModalData({
    //       title: "Image Upload",
    //       data: "You can only upload <b>JPG/PNG</b> file!",
    //       error: true,
    //     });
    //   }
    //   if (!isLt2M) {
    //     setMsgModalData({
    //       title: "Image Upload",
    //       data: "Image must smaller than <b>2MB</b>!",
    //       error: true,
    //     });
    //   }
    //   return isJpgOrPng && isLt2M;
    // },
    onChange(info) {
      const { status } = info.file;

      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
        setFile(info.file.originFileObj);
        setUploadImageURL(URL.createObjectURL(info.file.originFileObj));
      } else if (status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },

    onDrop(e) {
      console.log("Dropped files", e);
    },
  };

  return (
    <>
      <Modal
        title={null}
        centered
        open={open}
        onOk={() => setOpen(false)}
        footer={null}
        className="add-new-location"
        closable={false}
        onCancel={() => {
          setOpen(false);
          setEmailError(false);
          setImages([]);
        }}
      >
        <div className="header">
          <div className="header-left">
            <BackArrow
              onClick={() => {
                if (isfirstScreen) {
                  setOpen(false);
                } else {
                  setIsFirstScreen(true);
                  setIsSecondScreen(false);
                }
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
          <div className="header-center">Edit Venue</div>
          <div className="header-right">
            <CrossIcon
              onClick={() => {
                setEmailError(false);
                setOpen(false);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </div>

        {isfirstScreen && (
          <Spin spinning={isSpin} delay={500}>
            <div className="content-edit-company-2">
              <div className="content-edit-company-input-wrapper">
                <Input
                  placeholder="Enter Location Name"
                  size="large"
                  value={locationName}
                  onChange={(e) => {
                    setLocationName(e.target.value);
                  }}
                />

                {isSubmit && !locationName && (
                  <span style={{ color: "red" }}>
                    Please enter location name
                  </span>
                )}
              </div>
              <div className="content-edit-company-input-wrapper">
                <Input
                  placeholder="Enter Address"
                  size="large"
                  value={locationAddress}
                  onChange={(e) => setLocationAddress(e.target.value)}
                />
              </div>

              <Row gutter={[16, 16]}>
                <Col md={8}>
                  <div className="content-edit-company-input-wrapper">
                    {/* <Select
                    defaultValue="City"
                    style={{
                      width: "100%",
                    }}
                    size="large" // onChange={handleChange}
                    options={[
                      {
                        value: "jack",
                        label: "Jack",
                      },
                      {
                        value: "city",
                        label: "City",
                      },
                      {
                        value: "Yiminghe",
                        label: "yiminghe",
                      },
                    ]}
                  /> */}
                    <Input
                      placeholder="Enter City"
                      size="large"
                      value={locationCity}
                      onChange={(e) => setLocationCity(e.target.value)}
                    />
                    {isSubmit && !locationCity && (
                      <span style={{ color: "red" }}>Please enter city</span>
                    )}
                  </div>
                </Col>
                <Col md={8}>
                  <div className="content-edit-company-input-wrapper">
                    {/* <Select
                    defaultValue="State"
                    style={{
                      width: "100%",
                    }}
                    size="large" // onChange={handleChange}
                    options={[
                      {
                        value: "jack",
                        label: "Jack",
                      },
                      {
                        value: "State",
                        label: "State",
                      },
                      {
                        value: "Yiminghe",
                        label: "yiminghe",
                      },
                    ]}
                  /> */}

                    <Input
                      placeholder="Enter State"
                      size="large"
                      value={locationState}
                      onChange={(e) => setLocationState(e.target.value)}
                    />
                    {isSubmit && !locationState && (
                      <span style={{ color: "red" }}>Please enter state</span>
                    )}
                  </div>
                </Col>

                <Col md={8}>
                  <div className="content-edit-company-input-wrapper">
                    {/* <Select
                    defaultValue="Zip Code"
                    style={{
                      width: "100%",
                    }}
                    size="large" // onChange={handleChange}
                    options={[
                      {
                        value: "jack",
                        label: "Jack",
                      },
                      {
                        value: "Zip Code",
                        label: "Zip Code",
                      },
                      {
                        value: "Yiminghe",
                        label: "yiminghe",
                      },
                    ]}
                  /> */}
                    <ZipCodeMask
                      value={locationZipCode}
                      onChange={(e) => setLocationZipCode(e.target.value)}
                    >
                      {() => {
                        <Input
                          placeholder="Enter Zip Code"
                          size="large"
                          value={locationZipCode}
                          onChange={(e) => setLocationZipCode(e.target.value)}
                        />;
                      }}
                    </ZipCodeMask>
                    {isSubmit && !locationZipCode && (
                      <span style={{ color: "red" }}>
                        Please enter zip code
                      </span>
                    )}
                  </div>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col md={12}>
                  <div className="content-edit-company-input-wrapper">
                    <PhoneMask
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    >
                      {() => {
                        <Input
                          size="large"
                          placeholder="(123) 456-7890"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />;
                      }}
                    </PhoneMask>
                    {isSubmit && !phone && (
                      <span style={{ color: "red" }}>
                        Please enter phone number
                      </span>
                    )}
                  </div>
                </Col>
                <Col md={12}>
                  <div className="content-edit-company-input-wrapper">
                    <Input
                      placeholder="Email"
                      size="large"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {isEmailError && (
                      <span style={{ color: "red" }}>
                        Please enter valid email
                      </span>
                    )}
                  </div>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                {images.length
                  ? images.map((file, i) => (
                      <Col key={i} sm={12}>
                        <div className="uploaded-image-wrapper">
                          <img
                            src={
                              file?.originFileObj
                                ? URL.createObjectURL(file.originFileObj)
                                : file
                            }
                            alt={"Uploaded image"}
                          />
                        </div>
                      </Col>
                    ))
                  : null}

                <Col sm={12}>
                  <div
                    className="add-new-img"
                    onClick={() => setImageModal(true)}
                  >
                    <span>Add Image</span>
                    <button>Add</button>
                  </div>
                  {imageModal ? (
                    <ImageUploader
                      open={imageModal}
                      setOpen={setImageModal}
                      images={images}
                      setImages={(images) => {
                        setImages(images);
                      }}
                    />
                  ) : null}
                </Col>
              </Row>
            </div>
          </Spin>
        )}

        <div className="footer">
          <div className="footer-left"></div>
          <div className="footer-center"></div>
          <div className="footer-right">
            {/* <div className="next-btn">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  if (isValidEmail(email)) {
                    setIsAddNewRoom(true);
                    setEmailError(false);
                    setOpen(false);
                  } else {
                    setEmailError(true);
                  }
                }}
              >
                Add Rooms
              </button>
            </div> */}
            <div className="back-btn">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  if (
                    locationName &&
                    locationCity &&
                    locationState &&
                    locationZipCode &&
                    phone
                  ) {
                    setIsSubmit(false);
                    if (email) {
                      if (isValidEmail(email)) {
                        updateLocation();
                      } else {
                        setEmailError(true);
                      }
                    } else {
                      updateLocation();
                    }
                  } else {
                    setIsSubmit(true);
                  }
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <AddNewRoom
        open={isAddNewRoom}
        setOpen={setIsAddNewRoom}
        handleLocationModal={setOpen}
        file={file}
        locationAddress={locationAddress}
        locationName={locationName}
        setUploadImageURL={setUploadImageURL}
        setLocationName={setLocationName}
        setLocationAddress={setLocationAddress}
        locationCity={locationCity}
        setLocationCity={setLocationCity}
        locationState={locationState}
        setLocationState={setLocationState}
        getVenues={getVenues}
        locationZipCode={locationZipCode}
        setLocationZipCode={setLocationZipCode}
        phone={phone}
        setPhone={setPhone}
        email={email}
        setEmail={setEmail}
      />
      <AddImage
        open={isAddImage}
        setOpen={setIsAddImage}
        openLocationModal={open}
        setOpenLocationModal={setOpen}
      />
      <MessageModal
        open={isSuccessModalOpen}
        setOpen={setIsSuccessModelOpen}
        content="Your Location has been added successfully"
      />
    </>
  );
};
export default EditLocation;
