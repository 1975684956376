import React from "react";
import { Row, Col, Form, Input, Button } from "antd";
import { Link } from "react-router-dom";
import logo2 from "../Assets/logo2.png";
import "./BizEmail.css";

const BusinessEmail = () => {
  return (
    <Row className="email-container">
      <Col span={3}>
        <div className="email-logo-container">
          <img src={logo2} alt="Logo" className="email-logo" />
        </div>
      </Col>
      <Col span={21} className="email-form-container">
        <div className="email-setup-title">
          <p>Base Setup</p>
        </div>
        <div className="email-setup-content">
          <div className="email-card-container">
            <div className="email-input-group-active">
              <p className="email-active-setup-email">Enter Email Address</p>
              <input
                style={{
                  backgroundColor: "#F8F8F8",
                  color: "#B6B6B6",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 100,
                  lineHeight: "15px",
                }}
                type="email"
                id="email-businessemail"
                placeholder="Enter email address"
                name="businessemail"
              />
            </div>
            <Link to="/businessphone">
              <button type="primary" className="email-setup-next-button">
                Save & go next
              </button>
            </Link>
          </div>
        </div>
        <div className="email-setup-input-container">
          <div className="email-setup-contact-group">
            <p className="email-setup-contact">Enter Phone Number</p>
            <input
              type="text"
              id="businessphone"
              placeholder="Enter Phone Number"
              name="businessphone"
              disabled
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default BusinessEmail;
