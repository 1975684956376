// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-container {
  background-color: #fff;
}

.password-strength-modal {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  max-width: 500px;
  position: absolute;
  right: 1%;
  z-index: 1000;
}`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/components/PaswordChange.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;;AAEA;EACE,uBAAA;EACA,sBAAA;EACA,kBAAA;EACA,wCAAA;EACA,aAAA;EACA,gBAAA;EACA,kBAAA;EACA,SAAA;EACA,aAAA;AACF","sourcesContent":[".password-container {\n  background-color: #fff;\n}\n\n.password-strength-modal {\n  background-color: white;\n  border: 1px solid #ccc;\n  border-radius: 8px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  padding: 16px;\n  max-width: 500px;\n  position: absolute;\n  right: 1%;\n  z-index: 1000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
