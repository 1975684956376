import { Button, Card, Col, Row, Tooltip } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

import noPayment from '../../../../../assets/no-payments.svg';


const StripeAccountError = () => {
    const navigate = useNavigate();

    return (
        <>
            <Row style={{ marginTop: '24px', justifyContent: 'center', height: 'auto' }}>
                <Col>
                    <Tooltip title="Payment processor for your business." placement="topLeft">
                        <Card
                            style={{
                                borderColor: '#52c41a',
                                // cursor: 'pointer',
                                height: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                padding: '40px'
                            }}
                        >
                            <div style={{ textAlign: 'center' }}>
                                <img
                                    src={noPayment}
                                    alt="no payments"
                                    style={{ maxWidth: '100px' }}
                                />
                                <h3>No linked Bank account is found</h3>
                                <p>To receive funds from your event, add a bank account.</p>
                                <Button onClick={() => navigate('/Integration/LinkStripe')} type="primary" style={{ marginTop: '30px' }}>Link Stripe Account</Button>
                            </div>
                        </Card>
                    </Tooltip>
                </Col>
            </Row>
        </>
    )
}

export default StripeAccountError