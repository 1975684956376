import React, { useState } from "react";
import toast from "react-hot-toast";
// UI's
import { Button, Modal, Typography, Tooltip } from "antd";
import { Form, Input, Result } from "antd";
import "../integrationStyles.scss";
import styles from "../../Events/events.module.scss";
import axios from "../../../network/axios";

const ShowCode = ({ open, handelClose, generatedCode, setOpen }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [copyMessage, setCopyMessage] = useState("Click to copy");
  const [successMessage, setSuccessMessage] = useState("");
  const iframeUrl = `‹frame sr="${generatedCode?.generated_url}" style="border:Opx #ffffff none;" name="myiFrame" frameborder="1" marginheight="Opx"
    marginwidth="Opx" height="400px" width="600px" allowfullscreen></iframe>`;

  const renderdButtons = () => {
    // return only if open is Send by Email
    if (open !== "Send by Email" || successMessage) return null;

    return [
      <Button
        size={"large"}
        onClick={() => {
          handelClose();
          setSuccessMessage("");
        }}
      >
        Cancel
      </Button>,
      <Button
        size={"large"}
        type="primary"
        loading={loading}
        onClick={() => {
          if (open !== "Send by Email") {
            handelClose();
            setSuccessMessage("");
          } else {
            form.submit();
          }
        }}
      >
        Send
      </Button>,
    ];
  };

  const handleSubmit = async (values) => {
    const { emails } = values;

    if (!emails) {
      toast.error("please fill all fields");
    } else {
      const userObj = {
        email: emails,
        code: `${generatedCode?.generated_url}`,
      };
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_API_URL}/api/company/integration/website/send_code/`,
          userObj,
        )

        .then((res) => {
          setLoading(false);
          setSuccessMessage("Code has been shared");
          setOpen("shared");
          form.resetFields();
        })
        .catch((err) => {
          const errorCode = err?.response?.status || 500;
          if (errorCode === 500 || errorCode === 503) {
            window.location.href = "/server-error";
            console.log("Redirecting to server error page...");
          } else {
            toast.error(err?.response?.data?.message);
            setLoading(false);
            console.log(err);
          }
        });
    }
  };

  const CustomEmailValidator = (rule, value, callback) => {
    if (!value) {
      callback("Email is required");
    } else {
      const emails = value.split(",").map((email) => email.trim());
      const isInvalid = emails.some((email) => !/^\S+@\S+\.\S+$/.test(email));

      if (isInvalid) {
        callback("Invalid email format");
      } else {
        callback(); // All emails are valid
      }
    }
  };

  const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setCopyMessage("Copied!");
    setTimeout(() => {
      setCopyMessage("Click to copy");
    }, 500);
  };

  return (
    <Modal
      centered
      title={open ? open : "iFrame Code"}
      open={open}
      onCancel={() => {
        handelClose();
        setSuccessMessage("");
      }}
      footer={renderdButtons()}
      confirmLoading={loading}
    >
      {successMessage ? (
        <Result
          status="success"
          title={successMessage}
          extra={[
            <Button
              size={"large"}
              onClick={() => {
                handelClose();
                setSuccessMessage("");
              }}
            >
              Close
            </Button>,
          ]}
        />
      ) : (
        <>
          <Typography.Text
            type="secondary"
            style={{ fontWeight: 500, marginBottom: "5px", display: "block" }}
          >
            Code
          </Typography.Text>
          <div className="code-wrapper">
            {iframeUrl}
            {open !== "Send by Email" ? (
              <Tooltip placement="top" title={copyMessage} arrow={true}>
                <Button
                  type="link"
                  style={{ fontWeight: 600 }}
                  onClick={() => copyToClipboard(generatedCode?.generated_url)}
                >
                  Copy HTTPS code
                </Button>
              </Tooltip>
            ) : null}
          </div>
          {open === "Send by Email" ? (
            <>
              <Form
                className={styles.eventForm}
                form={form}
                name="validateOnly"
                layout="vertical"
                autoComplete="off"
                onFinish={handleSubmit}
                style={{ marginTop: "24px" }}
              >
                <Form.Item
                  name="emails"
                  label="Email*"
                  rules={[
                    {
                      required: true,
                      validator: CustomEmailValidator,
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter your email" />
                </Form.Item>
              </Form>
            </>
          ) : null}
        </>
      )}
    </Modal>
  );
};

export default ShowCode;
