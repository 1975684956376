import React from 'react';
import "../EndUserStyles.scss"; // or import './CartDetails.scss';
import { Typography, Button, Card, Checkbox, Divider } from "antd";

const CartDetails = ({ createSession }) => {
    const { Title, Text } = Typography;

    return (
        <Card className="cart-details-card">
            <Title level={4}>Details</Title>

            <div className="cart-item">
                <Checkbox>
                    <Typography className="cart-item-title">Room 1</Typography>
                </Checkbox>
                <Text>$100</Text>
            </div>

            <div className="cart-item">
                <Checkbox>
                    <Typography className="cart-item-title">Packages</Typography>
                </Checkbox>
                <Text>$30</Text>
            </div>

            <div className="cart-item">
                <Checkbox>
                    <Typography className="cart-item-title">Service</Typography>
                </Checkbox>
                <Text>$20</Text>
            </div>

            <Divider />

            <div className="cart-total">
                <Title className="total-title" level={5}>Total</Title>
                <Text>$250</Text>
            </div>

            <Divider />

            <div className="cart-total">
                <Title className="total-title" level={5}>Discount</Title>
                <Text>-20</Text>
            </div>

            <div className="cart-total">
                <Title className="total-title" level={5}>Tax Total</Title>
                <Text>$4</Text>
            </div>

            <div className="cart-total">
                <Title level={5}>Sub Total</Title>
                <Text>$850</Text>
            </div>

            <div>
                <Button
                    onClick={createSession}
                    type="primary"
                    size="large"
                    className="pay-button"
                >
                    Pay
                </Button>
            </div>
        </Card>
    );
};

export default CartDetails;
