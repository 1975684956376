import React, { useState, useEffect } from 'react';
import { Typography, Card, Space, Row, Col, Form, Input, Grid } from "antd";
import Loader from "../../../common/Loader";
import { getRooms } from "../../../services/rooms";
import usePopup from "../../../common/MessageModal/usePopup";

const { useBreakpoint } = Grid;

const RoomDetails = ({ venue, setRoomsDetails, setErrors, error, pricingModel }) => {
    const screens = useBreakpoint();
    const { Title, Text } = Typography;
    const popup = usePopup();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [rooms, setRooms] = useState([]);
    const [roomLength, setRoomLength] = useState([]);

    const fetchRooms = async () => {
        setLoading(true);
        try {
            const res = await getRooms(venue?.id);
            const roomData = res?.data?.results;
            setRoomLength(res?.data?.results)
            if (roomData.length === 0) {
                const newRoom = {
                    id: Date.now().toString(),
                    name: venue?.name || '',
                    max_guests: '',
                    PriceCheck: '',
                };
                setRooms([newRoom]);
                const initialValues = {
                    roomName_0: newRoom.name,
                    guestCount_0: newRoom.max_guests,
                    Price_0: newRoom.PriceCheck,
                };
                form.setFieldsValue(initialValues);
                setRoomsDetails(initialValues); // Set initial form data
            } else {
                const formValues = roomData.reduce((acc, room, index) => {
                    acc[`roomName_${index}`] = room.name;
                    acc[`guestCount_${index}`] = room.max_guests;
                    acc[`Price_${index}`] = pricingModel === "PER_PERSON" ? room.pp_price : room.flat_price;
                    return acc;
                }, {});

                form.setFieldsValue(formValues);
                setRoomsDetails(formValues); // Set initial form data
                setRooms(roomData);
            }
        } catch (error) {
            const errorCode = error?.response?.status || 500;
            if (errorCode === 500 || errorCode === 503) {
                window.location.href = "/server-error";
                console.log("Redirecting to server error page...");
            } else {
                popup.error("Oops...", error?.response?.data?.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (venue?.id) {
            fetchRooms();
        }
    }, [venue?.id]);

    useEffect(() => {
        fetchRooms();
    }, [pricingModel]);

    const handleFormChange = (changedValues, allValues) => {
        if (!allValues.guestCount_0 || !allValues.Price_0 || !allValues.roomName_0) {
            setErrors(prev => ({ ...prev, roomDetails: "Please fill in the required fields" }));
        } else {
            setErrors(null);
        }
        setRoomsDetails(allValues);
    };

    return (
        <div>
            <Card style={{ borderColor: error && "#ff4d4f", marginTop: "20px" }}>
                <Space>
                    <Title level={4}>Rooms Details</Title>
                </Space>

                {error ? (
                    <Text style={{ display: "block" }} type="danger">
                        {error}
                    </Text>
                ) : null}
                <Form
                    form={form}
                    name="validateOnly"
                    layout="vertical"
                    autoComplete="off"
                    onValuesChange={handleFormChange}
                >
                    {loading ? (
                        <Loader minHeight={200} />
                    ) : rooms?.length ? (
                        rooms.map((item, i) => (
                            <Row gutter={[8, 0]} align="middle" key={item.id}>
                                <Col span={9}>
                                    <Form.Item
                                        name={`roomName_${i}`}
                                        label={
                                            <span>
                                                Room Name<span style={{ color: "red" }}>*</span>
                                            </span>
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Room Name is required!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled={rooms[0]?.name !== ''}
                                            maxLength={50}
                                            size="large"
                                            placeholder="Enter Room Name"
                                            style={{ minWidth: "200px" }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={9}>
                                    <Form.Item
                                        name={`guestCount_${i}`}
                                        label={
                                            <span>
                                                Guest Capacity<span style={{ color: "red" }}>*</span>
                                            </span>
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Guest Count is required!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled={roomLength.length !== 0}
                                            maxLength={50}
                                            size="large"
                                            type="number"
                                            placeholder="Enter Guest Count"
                                            style={{ minWidth: "200px" }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        name={`Price_${i}`}
                                        label={
                                            <span>
                                                Amount<span style={{ color: "red" }}>*</span>
                                            </span>
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Price is required!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={<>$</>}
                                            disabled={roomLength.length !== 0}
                                            size="large"
                                            placeholder="Please Enter your room price"
                                            maxLength={50}
                                            type="number"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ))
                    ) : null}
                </Form>
            </Card>
        </div>
    );
};

export default RoomDetails;
