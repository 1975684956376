import React, { useState, useEffect } from 'react';
import { Typography, Card, Space, Row, Col, Form, Input, Grid, Button } from 'antd';
import InputMask from 'react-input-mask';
import { DeleteOutlined } from '@ant-design/icons';
import { getVenue } from '../../../services/venues';

const { useBreakpoint } = Grid;

const VenueDetails = ({ setVenueDetails, error, setErrors, venue, setVenue, venueInfo, venueMissingModal }) => {
  const { Title, Text } = Typography;
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const screens = useBreakpoint();
  const [loading, setLoading] = useState(false);

  // updating the files
  const fetchVenue = async () => {
    setLoading(true);
    try {
      const res = await getVenue(venueInfo?.id);
      setVenue(res?.data);
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = '/server-error';
        console.log('Redirecting to server error page...');
      } else {
        // toast.error('Oops...', error?.response?.data?.detail);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (venueInfo) {
      fetchVenue();
    }
  }, [venueInfo]);

  useEffect(() => {
    if (venue) {
      form.setFieldsValue({
        phone: venue.phone ? `(${venue.phone.slice(0, 3)}) ${venue.phone.slice(3, 6)}-${venue.phone.slice(6)}` : '',
        email: venue.email || '',
        about_us_text: venue.about_us_text || '',
      });

      const initialValues = form.getFieldsValue();
      setVenueDetails(initialValues);
    }
  }, [venue]);

  const deleteData = () => {
    form.setFieldsValue({
      phone: '',
      email: '',
    });
  };

  const deleteDescription = () => {
    form.setFieldsValue({
      about_us_text: '',
    });
  };

  const handleFormChange = (changedValues, allValues) => {
    const phoneNumber = allValues.phone;
    const email = allValues.email;
    if (!email || phoneNumber?.length <= 13) {
      setErrors(prev => ({ ...prev, venue: "Please fill in the required fields *" }));
    }
    else {
      setErrors(null);
    }
    setVenueDetails(allValues);
  };

  return (
    <div>
      <Card style={{ borderColor: error && "#ff4d4f", marginTop: "92px" }}>
        <Space>
          <Title level={4}>Venue Details</Title>
        </Space>

        {error ? (
          <Text style={{ display: "block" }} type="danger">
            {error}
          </Text>
        ) : null}

        {venue.length === 0 && venueMissingModal === false ? (
          <Text style={{ display: "block" }} type="danger">
            No venue is found, please go to the venue menu option under company setting and create a venue.
          </Text>
        ) : null}

        <Form
          form={form}
          name="venueDetailsForm"
          layout="vertical"
          onValuesChange={handleFormChange} // This will trigger on any form field change
        >
          <Row gutter={[8, 0]} align="middle">
            <Col span={screens.xs ? 24 : 11}>
              <Form.Item
                name="phone"
                label="Phone"
                rules={[
                  {
                    required: true,
                    message: 'Phone number is required!',
                  },
                  {
                    pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
                    message: 'Please enter a valid phone!',
                  },
                ]}
                validateStatus={error?.phone ? 'error' : ''}
                help={error?.phone}
              >
                <InputMask mask="(999) 999-9999" maskChar="">
                  {() => (
                    <Input
                      maxLength={50}
                      size="large"
                      placeholder="Type Phone here..."
                      type="text"
                    />
                  )}
                </InputMask>
              </Form.Item>
            </Col>
            <Col span={screens.xs ? 20 : 11}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: 'Email is required!',
                  },
                ]}
                validateStatus={error?.email ? 'error' : ''}
                help={error?.email}
              >
                <Input
                  maxLength={50}
                  size="large"
                  placeholder="Enter Email..."
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Button className="delete-item" onClick={deleteData}>
                <DeleteOutlined style={{ color: '#EB3D4D' }} />
              </Button>
            </Col>
          </Row>
          <Row gutter={[8, 0]} align="middle">
            <Col span={screens.xs ? 20 : 11}>
              <Form.Item
                name="about_us_text"
                label="About us"
              >
                <TextArea
                  size="large"
                  placeholder="Type description here..."
                  rows={1}
                />
              </Form.Item>
            </Col>
            <Col span={screens.xs ? 0 : 11}></Col>
            <Col span={2}>
              <Button className="delete-item" onClick={deleteDescription}>
                <DeleteOutlined style={{ color: '#EB3D4D' }} />
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default VenueDetails;