import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import InputMask from "react-input-mask";
import usePopup from "../../common/MessageModal/usePopup";

// UI's
import { Button, Modal } from "antd";
import { Form, Input, DatePicker, TimePicker } from "antd";
import { Select } from "antd";
// utils
import {
  getEventTypes,
  createNewEvent,
  updateEvent,
  getEventAvailability,
} from "../../services/events";
import { getRooms } from "../../services/rooms";
import { getPackages } from "../../services/packages";
import { getServices } from "../../services/services";

// comps
import RenderdDetails from "./comps/RenderdDetails";
import styles from "./events.module.scss";

const CreateEvent = ({ open, handelClose, refreshEvents }) => {
  const popup = usePopup();
  const [form] = Form.useForm();
  const location = useSelector((state) => state.venues.selectedVenue);
  const [loading, setLoading] = useState(false);
  const [actions, setActions] = useState(true);
  const [createLoading, setCreateLoading] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [eventTypes, setEventTypes] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [packages, setPackages] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [fullPackages, setFullPackages] = useState([]);
  const [fullServices, setFullServices] = useState([]);
  const [optinoalFields, setOptinoalFields] = useState({});
  const [eventAvailability, setEventAvailability] = useState(null);
  const [roomdata, setRoomData] = useState([]);
  const removeServiceOnPkg = (newVal, edit) => {
    const selected = fullPackages.length ? fullPackages?.find(item => item.id === newVal) : null
    if (selected && selected?.addons?.length) {
      const haveIds = selected?.addons.map(item => (item.id));
      const filtered = services?.filter(item => !haveIds.includes(item.value));
      setServices(filtered);
      if (!edit) {
        setSelectedServices([]);
      }
    } else {
      const obj = fullServices?.map(item => ({ label: item?.name, value: item?.id }));
      setServices(obj);
    }
  };

  // required data from server
  const fetchEventTypes = async () => {
    setLoading('eventTypes');
    try {
      const res = await getEventTypes();
      const data = res?.data?.data?.map(type => ({ label: type.name, value: type.id }));
      setEventTypes(data);
    } finally {
      setLoading(false);
    }
  }
  const fetchRooms = async () => {
    setLoading('rooms');
    try {
      const res = await getRooms(location?.id);
      setRoomData(res?.data?.results)
      const data = res?.data?.results?.map(room => ({ label: room.name, value: room.id }));
      setRooms(data);
    } finally {
      setLoading(false);
    }
  }
  const fetchPackages = async () => {
    setLoading('packages');
    try {
      const res = await getPackages({ location: location?.id, status: 'activated' });
      const data = res?.data?.results?.map(pkg => ({ label: pkg.name, value: pkg.id }));
      setPackages(data);
      setFullPackages(res?.data?.results);
    } finally {
      setLoading(false);
    }
  }
  const fetchServices = async () => {
    setLoading('services');
    try {
      const res = await getServices({ location: location?.id, is_active: true });
      const data = await res?.data?.results?.map(service => ({ label: service.name, value: service.id }));
      const pkg = form.getFieldValue('chosen_package');
      if (pkg && fullPackages.length) {
        const selected = fullPackages?.find(item => item.id === pkg);
        if (selected.addons.length) {
          const haveIds = selected?.addons.map(item => (item.id));
          const filtered = data?.filter(item => !haveIds.includes(item.value));
          setServices(filtered);
          const fullFiltered = res?.data?.results?.filter(item => !haveIds.includes(item.id));
          setFullServices(fullFiltered);
        }
      } else {
        setServices(data);
        setFullServices(res?.data?.results);
      }
    } finally {
      setLoading(false);
    }
  }

  const updateAvailabilityInEdit = (data) => {
    if (data?.booked_events?.length && open) {
      const event_date = open?.event_date;
      const start_time = open?.start_time;
      const filtered = data?.booked_events?.filter(
        (item) => item.date !== event_date && item.start_time !== start_time,
      );
      return { ...data, booked_events: filtered };
    }
  };

  const checkEventAvailability = async () => {
    try {
      const res = await getEventAvailability(location?.id);
      const data =
        open?.type === "edit"
          ? updateAvailabilityInEdit(res?.data?.data)
          : res?.data?.data;
      setEventAvailability(data);
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  useEffect(() => {
    if (open && location?.id) {
      fetchEventTypes();
      fetchRooms();
      fetchPackages();
      fetchServices();
      checkEventAvailability();
    }
  }, [open, location]);

  const handleCancel = () => {
    handelClose(false);
  };

  const moveNext = () => {
    form.validateFields().then((values) => {
      setViewDetails(values);
    });
  };

  const renderdButtons = () => {
    return viewDetails
      ? [
        <Button
          size={"large"}
          key="back"
          onClick={() => setViewDetails(false)}
        >
          Back
        </Button>,
        <Button
          size={"large"}
          key="next"
          type="primary"
          loading={createLoading}
          onClick={() => form.submit()}
        >
          {open?.type === "edit" ? "Update" : "Create"} Event
        </Button>,
      ]
      : [
        <Button size={"large"} key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        actions ? (
          <Button
            size={"large"}
            key="next"
            type="primary"
            loading={createLoading}
            onClick={() => form.submit()}
          >
            {open?.type === "edit" ? "Update" : "Create"} Event
          </Button>
        ) : (
          <Button size={"large"} key="next" type="primary" onClick={moveNext}>
            Next
          </Button>
        ),
      ];
  };

  useEffect(() => {
    if (optinoalFields?.package || optinoalFields?.services?.length) {
      setActions(false);
    } else {
      setActions(true);
    }
  }, [optinoalFields]);

  function formatPhoneNumber(phoneNumber) {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  }

  // Setting initial values for Update mode ==============================
  useEffect(() => {
    if (open?.type === "edit") {
      const { type, from, ...event } = open;
      const editData = {
        title: event?.title,
        event_type: event?.event_type?.id ? event?.event_type?.id : "",
        event_date: dayjs(event?.event_date, "YYYY-MM-DD"),
        start_time: dayjs(event?.start_time, "HH:mm:ss"),
        end_time: dayjs(event?.end_time, "HH:mm:ss"),
        contacts: [
          {
            ...event?.contacts[0],
            phone_number: formatPhoneNumber(event?.contacts[0]?.phone_number),
          },
        ],
        rooms: event?.rooms?.length ? event?.rooms[0]?.id : "",
        chosen_package: event?.chosen_package ? event?.chosen_package?.id : "",
      };
      if (editData?.chosen_package?.id) {
        removeServiceOnPkg(editData?.chosen_package?.id);
      }
      if (event?.addons?.length) {
        const update = event?.addons?.map((item) => item.id);
        setSelectedServices(update);
      }
      if ((editData?.chosen_package || editData?.addons) && !from) {
        const { chosen_package, addons } = editData;
        setOptinoalFields({ package: chosen_package, services: addons });
        removeServiceOnPkg(chosen_package?.id, "edit");
      }
      if (from) {
        const { event_type, chosen_package, addons, ...rest } = editData;
        form.setFieldsValue(rest);
      } else {
        form.setFieldsValue(editData);
      }
    }

    // from Calendar
    if (open?.type === "calendar_date") {
      const { clickedDate } = open;
      const date = dayjs(clickedDate).format("YYYY-MM-DD");
      const startTime = dayjs(clickedDate).format("HH:mm:ss");
      const dateTime = {
        event_date: dayjs(date, "YYYY-MM-DD"),
        start_time: dayjs(startTime, "HH:mm:ss"),
      };
      form.setFieldsValue(dateTime);
    }
  }, [open]);
  // =============================================

  const handleSubmit = async () => {
    const values = viewDetails ? viewDetails : await form.getFieldsValue();
    let contact = values?.contacts[0];
    if (contact?.full_name?.includes(' ')) {
      let name = contact?.full_name?.replace(/\s{2,}/g, " ").trim();
      name = name?.split(' ');
      let firstName
      let middleName
      let lastName
      if (name?.length === 3) {
        firstName = name[0];
        middleName = name[1];
        lastName = name[2];
      } else if (name?.length === 2) {
        firstName = name[0];
        lastName = name[1];
      } else if (name?.length === 1) {
        firstName = name[0];
      }
      contact = {
        ...contact,
        first_name: firstName || '',
        middle_name: middleName || '',
        last_name: lastName || '',
      }
    } else {
      contact = {
        ...contact,
        first_name: contact?.full_name || '',
        middle_name: '',
        last_name: '',
      }
    }
    values.contacts = [contact];
    const payload = {
      ...values,
      location: location?.id,
      event_date: dayjs(values?.event_date).format('YYYY-MM-DD'),
      start_time: dayjs(values?.start_time).format('HH:mm:ss'),
      end_time: dayjs(values?.end_time).format('HH:mm:ss'),
      status: (open?.type === 'edit' && open?.status) ? open?.status : 'open',
      addons: selectedServices
    };
    if (!open?.from) {
      payload.rooms = values?.rooms ? [values?.rooms] : []
    }
    setCreateLoading(true);
    try {
      if (open?.type === 'edit') {
        const res = await updateEvent(open?.id, payload);
        popup.success('Success!', res?.data?.message);
      } else {
        const res = await createNewEvent(payload);
        popup.success('Success!', res?.data?.message);
      }
      handleCancel();
      refreshEvents();
      form.resetFields();
    } catch (error) {

      if (typeof error?.response?.data?.message === 'object' || 'array') {
        popup.error('Oops!', error?.response?.data?.message[0]?.title)
        const errors = error?.response?.data?.message[0];
        Object.entries(errors).map(([field, message]) => {
          form.setFields([
            {
              name: field,
              errors: message,
            },
          ]);
        });
      } else {
        popup.error('Oops!', JSON.stringify(error?.response?.data?.message));
      }
    } finally {
      setCreateLoading(false);
    }
  }

  const disabledPastDate = (current) => {
    return (
      eventAvailability?.disable?.includes(
        dayjs(current).format("YYYY-MM-DD"),
      ) || current < dayjs().startOf("day")
    );
  };

  const checkStartTime = (rule, value, callback) => {
    const selectedDate = form.getFieldValue("event_date");
    const bookedEvent = eventAvailability?.booked_events?.find(
      (item) => selectedDate && item.date === selectedDate.format("YYYY-MM-DD"),
    );
    const startTime = dayjs(bookedEvent?.start_time, "HH:mm:ss");
    const endTime = dayjs(bookedEvent?.end_time, "HH:mm:ss");
    const currentTime = dayjs(value);

    const oneHourBefore = startTime.subtract(1, "hour");
    const oneHourAfter = endTime.add(29, "minute");
    const isWithinOneHour =
      currentTime.isAfter(oneHourBefore) && currentTime.isBefore(oneHourAfter);

    if (!value) {
      callback("Event end time is required!");
    } else if (isWithinOneHour) {
      callback("This time slot is not avaliable!");
    } else {
      callback();
    }
  };

  const checkEndTime = (rule, value, callback) => {
    // console.log('called', rule);
    const currentStartTime = form.getFieldValue("start_time");
    if (!Boolean(currentStartTime)) {
      callback();
      return;
    }
    const selectedDate = form.getFieldValue("event_date");
    const bookedEvent = eventAvailability?.booked_events?.find(
      (item) => selectedDate && item.date === selectedDate.format("YYYY-MM-DD"),
    );
    const startTime = dayjs(bookedEvent?.start_time, "HH:mm:ss");
    const endTime = dayjs(bookedEvent?.end_time, "HH:mm:ss");
    const currentTimeWidthSec = dayjs(value);
    const currentTime = currentTimeWidthSec.startOf("minute");

    const oneHourBefore = startTime?.add(1, "minute");
    const oneHourAfter = endTime?.add(29, "minute");
    const isWithinOneHour =
      (currentTime.isAfter(oneHourBefore) &&
        currentTime.isBefore(oneHourAfter)) ||
      (!currentStartTime.isAfter(oneHourAfter) &&
        !currentTime.isSame(startTime) &&
        currentTime.isAfter(startTime));

    const isHourBehind = dayjs(value).isAfter(
      dayjs(currentStartTime).add(59, "minute"),
    );

    if (!value) {
      callback("Event end time is required!");
    } else if (isWithinOneHour) {
      callback("This time slot is not avaliable!");
    } else if (!isHourBehind) {
      callback("End time must be at least 1 hour ahead!");
    } else {
      callback();
    }
  };

  const customCellRender = (date) => {
    const dateValue = date.date();
    const formatedDate = dayjs(date).format("YYYY-MM-DD");
    const isPartialDate =
      eventAvailability?.partial_disable?.includes(formatedDate) &&
      !eventAvailability?.disable?.includes(formatedDate);

    return isPartialDate ? (
      <div className={"partial-date"}>{dateValue}</div>
    ) : (
      dateValue
    );
  };

  return (
    <Modal
      className={styles.createModal}
      centered
      title={open?.type === "edit" ? "Update Event" : "Create New Event"}
      open={open}
      onCancel={handleCancel}
      footer={renderdButtons()}
    >
      <Form
        className={styles.eventForm}
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
      >
        {!viewDetails ? (
          <>
            <Form.Item
              name="title"
              label="Event Name*"
              rules={[
                {
                  required: true,
                  message: "Event name is required!",
                },
              ]}
            >
              <Input
                maxLength={50}
                size="large"
                placeholder="Type Name here..."
              />
            </Form.Item>
            {!open?.from ? (
              <Form.Item
                name="event_type"
                label="Event Type*"
                rules={[
                  {
                    required: true,
                    message: "Event type is required!",
                  },
                ]}
              >
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  options={eventTypes}
                  loading={loading === "eventTypes"}
                  onDropdownVisibleChange={(open) => {
                    if (open) {
                      !eventTypes?.length && fetchEventTypes();
                    }
                  }}
                />
              </Form.Item>
            ) : null}
            <Form.Item
              name="event_date"
              label="Select Date*"
              defaultValue
              rules={[
                {
                  required: true,
                  message: "Event date is required!",
                },
              ]}
            >
              {/* date picker */}
              <DatePicker
                disabledDate={disabledPastDate}
                cellRender={customCellRender}
                popupClassName={styles.customDatePicker}
                size="large"
                format="DD-MM-YYYY"  
              />
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 24,
              }}
            >
              <Form.Item
                style={{ width: "100%" }}
                name="start_time"
                label="Start Time*"
                rules={[
                  {
                    validator: checkStartTime,
                  },
                ]}
              >
                <TimePicker
                  size="large"
                  showNow={false}
                  style={{
                    width: "100%",
                  }}
                  format="hh:mm A"
                />
              </Form.Item>

              <Form.Item
                style={{ width: "100%" }}
                name="end_time"
                label="End Time*"
                rules={[
                  {
                    validator: (prm1, prm2, prm3) =>
                      checkEndTime(prm1, prm2, prm3),
                  },
                ]}
              >
                <TimePicker
                  size="large"
                  showNow={false}
                  style={{
                    width: "100%",
                  }}
                  format="hh:mm A"
                />
              </Form.Item>
            </div>
            <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>
              Host Info
            </h3>
            {open?.type === "edit" ? (
              <Form.Item
                style={{ display: "none" }}
                name={["contacts", 0, "id"]}
              >
                <Input />
              </Form.Item>
            ) : null}
            <Form.Item
              name={["contacts", 0, "full_name"]}
              label="Name*"
              rules={[
                {
                  required: true,
                  message: "Name is required!",
                },
              ]}
            >
              <Input
                maxLength={100}
                size="large"
                type="text"
                placeholder="Ex: John Alexander Smith"
              />
            </Form.Item>
            <Form.Item
              name={["contacts", 0, "email"]}
              label="Email*"
              rules={[
                {
                  required: true,
                  type: "email",
                },
              ]}
            >
              <Input
                size="large"
                type="email"
                placeholder="Type Email here..."
              />
            </Form.Item>

            <Form.Item
              name={['contacts', 0, 'phone_number']}
              label="Phone*"
              rules={[
                {
                  required: true,
                  message: 'Phone number is required!'
                },
                {
                  pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
                  message: 'Please enter a valid Phone Number!'
                }
              ]}
            >
              <InputMask
                mask="(999) 999-9999"
                maskChar=""
              >
                {() => <Input size="large" type="text" placeholder="(000) 999-9999" />}
              </InputMask>
            </Form.Item>
            {!open?.from ? (<>
              <h3 style={{ marginTop: '20px', marginBottom: '10px' }}>Additional</h3>
              <Form.Item
                name="rooms"
                label="Select Room"
              >
                <Select
                  size='large'
                  style={{ width: '100%' }}
                  options={rooms}
                  loading={loading === 'rooms'}
                  allowClear
                  onDropdownVisibleChange={(open) => {
                    if (open) {
                      !rooms?.length && fetchRooms();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name="chosen_package"
                label="Select Package"
              >
                <Select
                  size='large'
                  style={{ width: '100%' }}
                  options={packages}
                  loading={loading === 'packages'}
                  allowClear
                  onChange={(newVal) => {
                    setOptinoalFields(prev => ({ ...prev, package: newVal }));
                    removeServiceOnPkg(newVal);
                  }}
                  onDropdownVisibleChange={(open) => {
                    if (open) {
                      !packages?.length && fetchPackages();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                // name="addons"
                label="Select Services"
              >
                <Select
                  mode="multiple"
                  allowClear
                  size='large'
                  style={{ width: '100%' }}
                  value={selectedServices}
                  options={services}
                  loading={loading === 'services'}
                  onChange={(newVal) => {
                    setOptinoalFields(prev => ({ ...prev, services: newVal }));
                    setSelectedServices(newVal);
                  }}
                  onDropdownVisibleChange={(open) => {
                    if (open) {
                      if (services?.length) {
                        const pkg = form.getFieldValue('chosen_package');
                        if (pkg && fullPackages.length) {
                          removeServiceOnPkg(pkg);
                        }
                      } else {
                        fetchServices();
                      }
                    }
                  }}
                />
              </Form.Item>
            </>) : null}
          </>) : (
          <RenderdDetails
            selectedPkgId={form.getFieldValue("chosen_package")}
            selectedServicesIds={selectedServices}
            packages={fullPackages}
            services={fullServices}
            roomId={form.getFieldValue("rooms")}
            rooms={roomdata}

          />
        )}
      </Form>
    </Modal>
  )
}

export default CreateEvent;
