import React, { useState, useEffect } from "react";
// UI's
import { Typography, Card, Button, Input, Row, Col, Switch, Select, DatePicker } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const Payments = ({ createdPayments }) => {
  const { Title } = Typography;
  const [installment, setInstallment] = useState(false);
  const [payments, setPayments] = useState(null);

  useEffect(() => {
    const defaultPyament = [
      {
        title: "Payment 1",
        type: "credit-card",
        payment_date: new Date().toISOString().split('T')[0],
      },
    ];
    setPayments(defaultPyament);
  }, []);

  useEffect(() => {
    if (payments?.length) {
      const updated = payments?.map((item) => {
        const { title, type, ...rest } = item;
        return {
          ...rest,
          percentage: payments?.length
            ? (100 / payments?.length).toFixed(2)
            : 100,
          is_partials_allowed: installment,
        };
      });
      createdPayments(updated);
    }
  }, [payments, installment]);

  const addNewPayment = () => {
    const lastPayment = payments[payments.length - 1];
    const newPayment = {
      title: `Payment ${payments.length + 1}`,
      type: lastPayment.type,
      payment_date: lastPayment.payment_date === 0 ? 1 : lastPayment.payment_date,
    };
    setPayments((prev) => [...prev, newPayment]);
  };

  const updatePaymentAtIndex = (index, key, value) => {
    const newData = [...payments];
    newData[index] = { ...newData[index], [key]: value };
    setPayments(newData);
  };

  const deletePaymentAtIndex = (index) => {
    const newData = [...payments];
    newData.splice(index, 1);
    setPayments(newData);
  };

  // Disable past dates in the DatePicker
  const disabledDate = (current) => {
    return current && current < dayjs().startOf('day');
  };

  return (
    <Card style={{ marginTop: "20px" }}>
      <div className="payment-header">
        <Title level={4}>Payment terms</Title>
        <p>
          <Switch
            checked={installment}
            onChange={(val) => {
              if (!val) {
                setPayments([
                  {
                    title: "Payment 1",
                    payment_date: "",
                  },
                ]);
              }
              setInstallment(val);
            }}
          />{" "}
          Enable Installment Payments
        </p>
      </div>

      <Row style={{ marginTop: "10px" }} gutter={[16, 16]}>
        {payments?.map((pay, i) => (
          <React.Fragment key={i}>
            <Col span={7}>
              <p className="payment-type" style={{color:"#777980"}}>Payment Description</p>
              <Input
                size="large"
                style={{ backgroundColor: "#f9f9fc", width: "100%" }}
                value={pay?.title}
                onChange={(e) =>
                  updatePaymentAtIndex(i, "title", e.target.value)
                }
              />
            </Col>
            <Col span={7}>
              <p className="payment-type "  style={{color:"#777980"}}>Payment Method</p>
              <Select
                size="large"
                style={{ width: "100%", backgroundColor: "#f9f9fc" }}
                value={pay.type}
                onChange={(val) => updatePaymentAtIndex(i, "type", val)}
                options={[
                  { value: "debit-card", label: "Debit Card" },
                  { value: "credit-card", label: "Credit Card" },
                ]}
              />
            </Col>
            <Col span={7}>
              <p className="payment-type "  style={{color:"#777980"}}>Date of Payment</p>
              {i === 0 ? (
                <Input
                  size="large"
                  style={{ width: "100%", backgroundColor: "#f9f9fc" }}
                  value={pay.payment_date}
                  options={[{ value: 0, label: "Booking Day" }]}
                  onChange={(val) => updatePaymentAtIndex(0, "payment_date", "")}
                  disabled={i === 0}
                />
              ) : (
                <DatePicker
                  disabledDate={disabledDate}
                  size="large"
                  onChange={(date) =>
                    updatePaymentAtIndex(i, "payment_date", dayjs(date).format('YYYY-MM-DD'))
                  }
                  style={{ backgroundColor: "#f9f9fc", width: '100%' }}
                />
              )}
            </Col>
            <Col span={2}>
              {i ? (
                <Button
                  className="delete-button"
                  onClick={() => deletePaymentAtIndex(i)}
                >
                  <DeleteOutlined style={{ color: "#EB3D4D" }} />
                </Button>
              ) : null}
            </Col>
          </React.Fragment>
        ))}
      </Row>

      {installment && payments.length < 3 ? (
        <Button className="button-terms" onClick={addNewPayment} size="large">
          <PlusOutlined /> Add Payment Terms
        </Button>
      ) : null}
    </Card>
  );
};

export default Payments;