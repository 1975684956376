import React, { useState, useEffect } from "react";
import { Button, Card, Spin, Tooltip } from "antd";
import "./paymentIntegration.scss";
import { useNavigate } from "react-router";
import myApi from "../../../network/axios";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectComponentsProvider,
  ConnectAccountManagement
} from "@stripe/react-connect-js";
import { CheckCircleTwoTone } from "@mui/icons-material";
import toast from "react-hot-toast";

const StripeAccountInfo = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState(null);
  const [stripeConnectInstance, setStripeConnectInstance] = useState(null);

  const removeAccount = async () => {
    setLoading(true);
    await myApi
      .post(`${process.env.REACT_APP_BASE_API_URL}/api/company/integration/stripe/delink/`)
      .then((result) => {
        setLoading(false);
        toast.success("Account Removed Successfully!", {
          position: "top-right",
        });
        getStripeAccountInfo();
      })
      .catch((error) => {
        setLoading(false);
        toast.success("Error while removing account!", {
          position: "top-right",
        });
      });
  }

  const getStripeAccountInfo = async () => {
    try {
      const response = await myApi.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/company/integration/stripe/?source=account_management`
      );
      const accountData = response.data.data;
      setAccount(accountData);

      const stripeInstance = await loadConnectAndInitialize({
        publishableKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
        fetchClientSecret: async () => accountData.client_secret,
      });

      setStripeConnectInstance(stripeInstance);
    } catch (error) {
      console.log("error", error);
      navigate("/Integration/LinkStripe");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStripeAccountInfo();
  }, []);

  return (
    <div>
      {loading ? (
        <Spin tip="Fetching Account Details" size="large">
          <div
            style={{
              height: "400px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "50px",
              marginBottom: "50px",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          />
        </Spin>
      ) : (
        stripeConnectInstance && (
          <Card className='account-info-wrapper'>
            <div className='account-header'>
              <div>
                <h1>Welcome! {account?.individual?.first_name} {account?.individual?.last_name}
                  {account?.status === 'active' ? (
                    <span className="status" style={{ marginLeft: 10, marginTop: 5 }}>
                      <Tooltip title={account?.status} placement="top">
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                      </Tooltip>
                    </span>
                  ) : ""} </h1>
                <p>Your Stripe account is all set to receive payments.</p>
                {/* {account?.status === 'active' ? (
                  <p>Your Stripe account is all set to receive payments.</p>
                ) : account?.disabled_reason === 'requirements.pending_verification' ? (
                  <p style={{ color: '#d32f2f' }}>Stripe is currently verifying information that you submitted.</p>
                ) : ""} */}
              </div>
              <Button danger loading={loading} onClick={() => removeAccount()}>Remove Account</Button>
            </div>
            <div style={{ marginTop: 20 }}>
              <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                <ConnectAccountManagement
                  // Optional:
                  collectionOptions={{
                    fields: 'eventually_due',
                    futureRequirements: 'include',
                  }}
                />
              </ConnectComponentsProvider>
            </div>
          </Card>
        )
      )}
    </div>
  );
};

export default StripeAccountInfo;
