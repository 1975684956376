import React, { useState, useEffect, useMemo } from 'react'
import { Typography, Card, Space, Checkbox, Grid, Empty, Table, Input, Select } from "antd";
import Loader from "../../../common/Loader";
import { toast } from "react-hot-toast";
import { TagOutlined } from "@ant-design/icons";
import { getServices } from "../../../services/services";
import { serviceIcons } from "./utils";
// import { ReactComponent as PackagesIcon } from "../../../assets/icons/packages.svg";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

import "../EndUserStyles.scss";
import axios from "../../../network/axios";
import dummyPhoto from '../Assets/photo.png';
const { useBreakpoint } = Grid;

const Services = ({
  AllServices,
  selectedPackage,
  filteredServices,
  selected,
  setSelected,
  location,
  pricingModel
}) => {
  const { Title } = Typography;
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);

  const screens = useBreakpoint();


  const dataSource = AllServices?.map((item, index) => ({
    id: item.id,
    key: index,
    name: item?.name,
    description: item.description,
    image: item?.images?.length ? item?.images[0]?.image : dummyPhoto,
    price:  pricingModel === "PER_PERSON" ? `${item?.pp_price || "0"}` : `${item?.flat_price || "0"}`,

  }));


  const columns = useMemo(() => [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      width: 40,
      render: (text) => (
        <img
          src={text}
          alt="Service"
          style={{ width: "50px", height: "50px", borderRadius: "5px", marginTop: "3px" }}
        />
      ),
    },
    {
      title: '',
      width: "50%",
      key: 'service',
      render: (text, record) => (
        <div style={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
          <h4 style={{ margin: 0 }}>{record.name}</h4>
          <p style={{ margin: 0 }}>{record.description}</p>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'price',
      key: 'price',
      render: (text) => <h4 style={{ margin: 0 }}>${text || 0}</h4>,
    },
    {
      title: 'Select',
      key: 'select',
      width: 40,
      render: (text, record) => (
        <Checkbox
        checked={selected.includes(record.id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelected((prevSelected) => [...prevSelected, record]);
            } else {
              setSelected((prevSelected) =>
                prevSelected.filter((service) => service.id !== record.id)
              );
            }
          }}
        />
      ),
    }
    
  ]);

  return (

    <>
      {/* <div className="services">
        <Card style={{ marginBottom: 24 }}  >
          <Title level={4}>Services</Title>
          <p
            className="event-head"
            style={{ color: "#667085", pointerEvents: "none" }}
          >
            Select Services to add
          </p>
          <div className="item-details" style={{ height: "140px" }}>
            <Card>
              <Checkbox.Group
                style={{ display: "flex", gap: 1, }}
                value={selected}
                onChange={(value) => {
                  const find = services?.filter((item) => value.includes(item.id));
                  setSelected(find);
                }}
              >
                {services?.length ? (
                  services.map((item, i) => (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent:"space-between"
                      }}
                    >
                      <img
                        src={dummyPhoto}
                        alt="Service"
                        style={{ width: "50px", height: "50px" }}
                      />
                      <div style={{ marginLeft: screens.xs ? "5px" : "10px", width: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <div>
                            <Title
                              level={5}
                              style={{
                                fontWeight: 700,
                                marginBottom: "0px",
                                color: "#1D1F2C"
                              }}
                            >
                              {item.name}
                            </Title>
                          </div>
                          <div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                              <Typography style={{ fontSize: "15px", marginTop: "5px", marginRight: screens.xs ? "10px" : "20px", }}>
                                Price:&nbsp; $
                                {item?.total_price}
                              </Typography>
                              <Checkbox
                                value={item.id}
                                checked={selected.includes(item.id)}
                              />
                            </div>
                          </div>
                        </div>
                        <Title
                          style={{
                            fontSize: "14px",
                            color: "#667085",
                            fontWeight: "400"
                          }}
                        >
                          {item?.description}
                        </Title>
                      </div>
                    </div>
                  ))
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Services"
                  />
                )}
              </Checkbox.Group>
            </Card>
          </div>
        </Card>
      </div> */}

      <div>
        <Card style={{ marginTop: "20px" }}>
          <Space
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4}>Services</Title>
          </Space>

          <div
            className="events-details"
            style={{ marginTop: screens.xs ? "10px" : "0" }}
          >
            <p
              className="event-head"
              style={{ color: "#667085", pointerEvents: "none" }}
            >
              Select Services to add
            </p>
          </div>

          <div className="item-details" style={{ height: "140px" }}>
            <Card>
              {loading && !AllServices?.length ? (
                <Loader minHeight={400} />
              ) : AllServices?.length ? (
                <Table
                  scroll={{ x: 600 }}
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  showHeader={false}
                />
              ) : !loading ? (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No Services"
                />
              ) : null}
            </Card>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Services;