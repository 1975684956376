import React, { useState, useEffect } from "react";
import {
  CardElement,
  Elements,
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import usePopup from "../../../../../common/MessageModal/usePopup";
import {
  createCheckoutSession,
  createSetupIntent,
  madePayment,
  savePaymentMethod,
  getPaymentMethods,
} from "../../../../../services/events";

// UI's
import { Button, Modal, message, Card, Spin } from "antd";
import { Form, Input } from "antd";

import { ReactComponent as CardIcon } from "../../../../../assets/icons/credit-card.svg";
import { ReactComponent as CheckIcon } from "../../../../../assets/icons/check.svg";
import { ReactComponent as CashIcon } from "../../../../../assets/icons/cash.svg";

import ImageUploader from "../../../../../components/ImageUploader/";
import myApi from "../../../../Integrations/PaymentIntegration/UserData";

const AddCard = ({
  open,
  handelClose,
  fetchAllPayments,
  event,
  stripePromise,
  getPaymentMethodsList,
  deleteCreditCard,
  handleAddCard,
}) => {
  const popup = usePopup();
  const [form] = Form.useForm();
  const [paymentType, setPaymentType] = useState("credit");
  const [chequeImage, setChequeImage] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openCheckout, setOpenCheckout] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const elements = useElements();
  const stripe = useStripe();
  const [paymentMethod, setPaymentMethod] = useState([]);

  // const elements = useElements()

  const handleCancel = () => {
    handelClose();
    form.resetFields();
    setChequeImage(null);
    setPaymentType("credit");
  };

  const handleCreateSetupIntent = async () => {
    const response = await createSetupIntent();
    setClientSecret(response.data.data);
  };

  const handleFetchPaymentMethod = async () => {
    try {
      const response = await getPaymentMethods(event.id);
      setPaymentMethod(response.data.data);
    } catch (error) {
      console.error("Error fetching payment methods:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   handleFetchPaymentMethod();
  //   handleCreateSetupIntent();
  // }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    const cardElement = elements.getElement(CardElement);

    try {
      const { error, setupIntent } = await stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
          },
        },
      );

      if (error) {
        console.error(error);
        message.error(error.message);
        setLoading(false);
        return;
      }

      try {
        await savePaymentMethod({
          setup_intent_id: setupIntent.id,
          event_id: event.id,
        });
        fetchAllPayments();
        getPaymentMethodsList();
        form.resetFields();
        message.success("Payment method added successfully");
        form.resetFields();
        handelClose();
        handleAddCard();
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.error("Unexpected error:", err);
      message.error("An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  const renderButtons = () => (
    <>
      <Button size="large" onClick={handleCancel}>
        Cancel
      </Button>
      {paymentMethod.length <= 0 && (
        <Button
          style={{ marginLeft: "auto" }}
          size="large"
          type="primary"
          onClick={() => form.submit()}
          loading={loading}
        >
          Save
        </Button>
      )}
      {paymentMethod.length > 0 && (
        <Button
          style={{ marginLeft: "auto", color: "red" }}
          size="large"
          loading={loading}
          onClick={() => deleteCreditCard(paymentMethod[0].id, event.id)}
        >
          Delete
        </Button>
      )}
    </>
  );

  return (
    // <Elements stripe={stripePromise}>
    <Modal
      centered
      title="Add payment method"
      open={open}
      onCancel={handleCancel}
      footer={renderButtons()}
    >
      {isLoading ? (
        <div style={{ textAlign: "center", alignItems: "center" }}>
          <Spin
            size="large"
            style={{ textAlign: "center", alignItems: "center" }}
          />
        </div>
      ) : (
        <Form
          style={{ marginTop: 24 }}
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          className="modal-form payments-modal"
          onFinish={handleSubmit}
        >
          {paymentMethod.length > 0 ? (
            <>
              <Card style={{ marginBottom: 24 }}>
                <p>
                  <strong>Card:</strong> **** **** ****{" "}
                  {paymentMethod[0]?.last4}
                </p>
                <p>
                  <strong>Brand:</strong> {paymentMethod[0]?.brand}
                </p>
                <p>
                  <strong>Expires:</strong> {paymentMethod[0]?.exp_month}/
                  {paymentMethod[0]?.exp_year}
                </p>
              </Card>
            </>
          ) : (
            <>
              <Form.Item
                name="name"
                label={
                  <>
                    Name on card <span style={{ color: "red" }}>*</span>
                  </>
                }
                rules={[{ required: true, message: "Name is required!" }]}
              >
                <Input size="large" placeholder="Type here..." />
              </Form.Item>
              <Form.Item label="Card Info">
                <CardElement id="card-element" />
              </Form.Item>
            </>
          )}
        </Form>
      )}
    </Modal>
    // </Elements>
  );
};

export default AddCard;
