// Venues
export const VENUES = "/api/company/location/";

// Events
export const EVENT = "/api/event/";
export const GET_EVENTS_TYPES = "/api/event/type/";
export const GET_EVENTS_STAGES = "/api/event/stage/";
export const GET_EVENTS_AVAILABILITY = "/api/event/available/";
export const PAYMENTS = "/api/event/payment/";
export const PAYMENT_ACTIONS = "/api/company/payment/";
export const CONTRACTS = "/api/event/terms/";
export const TERMS_TEMPLATES = "/api/company/terms/template/";
export const DELETE_FILE = "/api/event/terms/file/";
export const DOWNLOAD_FILE = "/api/event/terms/file/download/";
export const GET_UPDATES = "/api/event/activity/";
export const TASKS = "/api/event/task/";
export const APPOINTMENTS = "/api/event/appointment/";
export const SHARE = "/api/event/share/";

// Rooms
export const ROOM_API_ENDPOINT = "/api/company/room/";

// Packages
export const PACKAGES = "/api/company/package/";

// Services
export const SERVICES = "/api/company/addon/";

// Integrations
export const WEBSITE_INTEGRATIONS = "/api/company/integration/website/";
export const WEBSITE_INTEGRATIONS_DETAILS =
  "/api/company/integration/website/details/";
export const END_USER_EVENT_CREATION = "/api/event/guest/";
export const END_USER_EVENT_TYPES = "api/event/type/guest/";
// Dashboard

export const DASHBOARD_DATA = "/api/event/dashboard/";
export const COMPANY = "/api/company/";
export const CHART = "/api/event/dashboard/chart/";

// Images
export const IMAGES = "/api/company/image/";

// Others
export const SEARCH = "/api/company/search/";
export const CREATE_CHECKOUT_SESSION = "/api/auth/create_checkout_session/";
export const CREATE_CHECKOUT_SESSION_AS_GUEST = "/api/auth/create_checkout_session_as_guest/";
export const CREATE_SETUP_INTENT = "/api/event/payment/create_setup_intent/";
export const SAVE_PAYMENT_METHOD = "/api/event/payment/save_payment_method/";
export const PAYMENT_METHOD_LIST = "/api/event/payment/method/list/";
