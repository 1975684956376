import React, { useEffect, useState } from 'react';
import { Typography, Card, Space, Row, Col, Form, Select, Grid, Tooltip } from "antd";
import { InfoOutlined } from "@ant-design/icons";

const { useBreakpoint } = Grid;

const PricingModel = ({ setPricingModel, setErrors, error }) => {
    const screens = useBreakpoint();
    const { Title } = Typography;
    const [form] = Form.useForm();

    useEffect(() => {
        // Set initial form values when component mounts
        form.setFieldsValue({
            rooms: "PER_PERSON",
            services: "PER_PERSON",
            packages: "PER_PERSON",
        });

        // Also update the parent state with the initial values
        setPricingModel({
            rooms: "PER_PERSON",
            services: "PER_PERSON",
            packages: "PER_PERSON",
        });
    }, [form, setPricingModel]);

    const handleFormChange = (changedValues, allValues) => {
        // Update error state if necessary
        if (!allValues?.rooms || !allValues?.services || !allValues?.packages) {
            setErrors(prev => ({ ...prev, pricingModel: "Please select all the pricing model details" }));
        } else {
            setErrors(null);
        }
        // Update pricingModel with the new form values
        setPricingModel(allValues);
    };

    return (
        <div>
            <Card style={{ borderColor: error && "#ff4d4f", marginTop: "20px" }}>
                <Space>
                    <Title level={4}>
                        Pricing Model
                        &nbsp;<Tooltip placement="top" title={"Choose pricing Option. Per Person allows you to charge based on guest count (guest capacity multiply by price). Fixed Price does not consider guest count."}>
                            <InfoOutlined
                                className='add-input-field'
                                style={{ marginBottom: '10px', cursor: 'pointer' }}
                            />
                        </Tooltip>
                    </Title>
                </Space>
                <Form
                    form={form}
                    name="validateOnly"
                    layout="vertical"
                    autoComplete="off"
                    onValuesChange={handleFormChange}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={screens.xs ? 24 : 11}>
                            <Title style={{ fontSize: "16px" }}>Room<span style={{ color: 'red' }}>*</span></Title>
                            <Form.Item
                                name="rooms"
                                rules={[
                                    {
                                        required: true,
                                        message: "Room name is required!",
                                    },
                                ]}
                            >
                                <Select size="large" style={{ width: "100%" }}>
                                    <Select.Option value="PER_PERSON">Price Per Person</Select.Option>
                                    <Select.Option value="FLAT_RATE">Flat Price</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={screens.xs ? 24 : 11}>
                            <Title style={{ fontSize: "16px" }}>Services<span style={{ color: 'red' }}>*</span></Title>
                            <Form.Item
                                name="services"
                                rules={[
                                    {
                                        required: true,
                                        message: "Service name is required!",
                                    },
                                ]}
                            >
                                <Select size="large" style={{ width: "100%" }}>
                                    <Select.Option value="PER_PERSON">Price Per Person</Select.Option>
                                    <Select.Option value="FLAT_RATE">Flat Price</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={screens.xs ? 24 : 11}>
                            <Title style={{ fontSize: "16px" }}>Packages<span style={{ color: 'red' }}>*</span></Title>
                            <Form.Item
                                name="packages"
                                rules={[
                                    {
                                        required: true,
                                        message: "Package name is required!",
                                    },
                                ]}
                            >
                                <Select size="large" style={{ width: "100%" }}>
                                    <Select.Option value="PER_PERSON">Price Per Person</Select.Option>
                                    <Select.Option value="FLAT_RATE">Flat Price</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );
}

export default PricingModel;