import React, { useState } from "react";
// UI's
import { Typography, Space, Button, Card, Menu } from "antd";
import {
  CalendarOutlined,
  BarsOutlined,
  DeleteOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
// importing layouts
import Billings from "./comps/Billings";
import Contract from "./comps/Contract";
import infobutton from "./SourceFiles/info.svg";
import no_image from "../../../assets/background.jpg";
import pencil from "./SourceFiles/pencil.png";
import ModifyPackage from "../Modals/EditPackage/ModifyPackage";
import Updates from "./comps/Updates";
import usePopup from "../../../common/MessageModal/usePopup";
import { updateEvent } from "../../../services/events";
import empty from "../../../assets/empty.svg";

import "./comps/Billings/billings.scss";

const MenuHeader = ({ event, setEvent }) => {
  const popup = usePopup();
  const { Title, Text } = Typography;

  const [current, setCurrent] = useState("packages");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [packageCount, setPackageCount] = useState(0);

  const showModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const menuItems = [
    {
      key: "packages",
      label: (
        <a
          style={{ color: current === "packages" ? "#0B98D2" : "#667085" }}
          className="menu-heading"
          onClick={() => setCurrent("packages")}
        >
          Packages & Services
        </a>
      ),
    },
    {
      key: "contract",
      label: (
        <a
          style={{ color: current === "contact" ? "#0B98D2" : "#667085" }}
          className="menu-heading"
          onClick={() => setCurrent("contact")}
        >
          Contract
        </a>
      ),
    },
    {
      key: "billing",
      label: (
        <a
          style={{ color: current === "billing" ? "#0B98D2" : "#667085" }}
          className="menu-heading"
          onClick={() => setCurrent("billing")}
        >
          Billing
        </a>
      ),
    },
    {
      key: "history",
      label: (
        <a
          style={{ color: current === "history" ? "#0B98D2" : "#667085" }}
          className="menu-heading"
          onClick={() => setCurrent("history")}
        >
          {/* Activity updates */}
          History & Updates
        </a>
      ),
    },
  ];

  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    let formattedTime = "";

    if (hours > 0) {
      formattedTime += `${hours} hour${hours > 1 ? "s" : ""}`;
    }

    if (remainingMinutes > 0) {
      formattedTime += ` ${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}`;
    }
    return formattedTime;
  };

  const deleteService = async (id) => {
    const newServices = await event?.addons.filter((item) => item.id !== id);
    const payload = newServices?.length
      ? await newServices?.map((item) => item.id)
      : [];
    try {
      const res = await updateEvent(event.id, {
        addons: payload,
      });
      popup.success("Success!", "Service deleted successfully!");
      setEvent(res?.data?.data);
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = "/server-error";
        console.log("Redirecting to server error page...");
      } else {
        popup.error("Oops...", error?.response?.data?.message);
      }
    }
  };

  return (
    <div>
      <Menu
        selectedKeys={[current]}
        mode="horizontal"
        items={menuItems}
        className="menu-items"
      />

      <div>
        {
          // && event?.chosen_package
          current === "packages" ? (
            <>
              <div>
                {event?.chosen_package ? (
                  <>
                    <Card
                      style={{
                        width: "100%",
                      }}
                    >
                      <Space className="navigationBar">
                        <Title level={4}>Package</Title>
                        <Button
                          onClick={showModal}
                          type="primary"
                          size={"medium"}
                        >
                          <img src={pencil} alt="" style={{ height: "12px" }} />{" "}
                          &nbsp;Modify
                        </Button>
                      </Space>
                      <div style={{ display: "flex", marginTop: "10px" }}>
                        <img
                          src={
                            event?.chosen_package?.images[0]?.image || no_image
                          }
                          alt={event?.chosen_package?.name}
                          className="img-fluid event-image"
                        />
                        <div className="event-info">
                          <Title level={4}>
                            {event?.chosen_package?.name || "-"}
                          </Title>
                          <p className="description-event">
                            {event?.chosen_package?.description || "-"}
                          </p>
                        </div>
                      </div>
                      <div className="event-availability row">
                        <div className="col-lg-6">
                          <div style={{ display: "flex" }}>
                            <div className="icon-circle">
                              <CalendarOutlined
                                style={{ fontSize: "16px" }}
                                theme="outlined"
                              />
                            </div>
                            <div style={{ marginLeft: "10px" }}>
                              <p className="event-col">Package Length</p>
                              <p className="event-days">
                                {formatTime(
                                  event?.chosen_package?.duration_minutes,
                                ) || "-"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr
                        style={{
                          background: "#F0F1F3",
                          height: "1px",
                          border: "0",
                        }}
                      />

                      <Title style={{ marginTop: "20px" }} level={4}>
                        Services
                      </Title>

                      {event?.chosen_package?.addons.length
                        ? event?.chosen_package?.addons.map((item, i) => (
                            <div className="main-service" key={i}>
                              <div className="col-lg-6">
                                <div className="services-items">
                                  <div className="services-icon-circle">
                                    <BarsOutlined
                                      style={{
                                        color: "#0B98D2",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </div>
                                  <div className="title">
                                    <h2 className="service-name">
                                      {item?.name}
                                    </h2>
                                    <img
                                      src={infobutton}
                                      className="info-button"
                                      alt="icon"
                                    />
                                    <Text type="secondary">
                                      Pre-Included Service
                                    </Text>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div
                                  className="services-items"
                                  style={{ textAlign: "left" }}
                                >
                                  <p style={{ marginTop: "5px" }}>Free</p>
                                  {/* <div className='delete-service'>
                            <DeleteOutlined style={{ color: "#EB3D4D", fontSize: '20px' }} />
                          </div> */}
                                </div>
                              </div>
                            </div>
                          ))
                        : null}

                      {event?.addons?.length
                        ? event?.addons.map((item, i) => (
                            <div className="main-service" key={i}>
                              <div className="col-lg-6">
                                <div className="services-items">
                                  <div className="services-icon-circle">
                                    <BarsOutlined
                                      style={{
                                        color: "#0B98D2",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </div>
                                  <div className="title">
                                    <h2 className="service-name">
                                      {item?.name}
                                    </h2>
                                    <img
                                      src={infobutton}
                                      className="info-button"
                                      alt="icon"
                                    />
                                    <Text type="secondary">Extra Service</Text>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div
                                  className="services-items"
                                  style={{ textAlign: "left" }}
                                >
                                  <p style={{ marginTop: "5px" }}>
                                    {item?.total_price
                                      ? "$" + item?.total_price
                                      : 0}
                                  </p>
                                  <div
                                    className="delete-service"
                                    onClick={() =>
                                      popup.confirm(
                                        `Delete ${item?.name}`,
                                        "Are you sure? This action is permanent and cannot be undone.",
                                        () => deleteService(item.id),
                                      )
                                    }
                                  >
                                    <DeleteOutlined
                                      style={{
                                        color: "#EB3D4D",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        : null}
                    </Card>

                    <div className="card card-outer" style={{ width: "100%" }}>
                      <Card className="card-inner">
                        <div className="pricing-event">
                          <h2 className="total" style={{ color: "#0499CB" }}>
                            Total Package & Services Cost
                          </h2>
                          <h2 className="total">
                            $
                            {event?.total_price
                              ? event?.total_price
                              : (event?.chosen_package?.total_price || 0) +
                                (event?.addons?.length
                                  ? event?.addons.reduce(
                                      (sum, item) => sum + item.total_price,
                                      0,
                                    )
                                  : 0)}
                          </h2>
                        </div>
                        <div className="payment-action-btn">
                          <Button
                            className="paynow-button"
                            type="primary"
                            size={"medium"}
                            onClick={() => setCurrent("billing")}
                          >
                            Pay Now
                          </Button>
                        </div>
                      </Card>
                    </div>
                  </>
                ) : (
                  <Card>
                    <div className="no-payments">
                      <img src={empty} alt="no package" />
                      <h3>Oops! Its Empty Here...</h3>
                      <p>
                        You have{" "}
                        {packageCount > 1
                          ? packageCount + " packages"
                          : packageCount + " package"}{" "}
                        in the system. start adding one of them and you can
                        customize it for the event.
                      </p>
                      <Button
                        icon={<PlusCircleFilled />}
                        onClick={showModal}
                        type="primary"
                      >
                        ADD A PACKAGE
                      </Button>
                    </div>
                  </Card>
                )}
              </div>
            </>
          ) : null
        }
      </div>

      <div>
        {current === "billing" ? (
          <Billings
            totalPrice={
              event?.total_price
                ? event?.total_price
                : (event?.chosen_package?.total_price || 0) +
                  (event?.addons?.length
                    ? event?.addons.reduce(
                        (sum, item) => sum + item.total_price,
                        0,
                      )
                    : 0)
            }
            eventId={event?.id}
            event={event}
            setCurrent={setCurrent}
          />
        ) : null}
      </div>

      <div>{current === "contact" ? <Contract event={event} /> : null}</div>

      <div>{current === "history" ? <Updates event={event} /> : null}</div>
      <ModifyPackage
        isModalOpen={isModalOpen}
        showModal={showModal}
        currentEvent={event}
        setCurrentEvent={setEvent}
        setPackageCount={setPackageCount}
      />
    </div>
  );
};

export default MenuHeader;
