// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ps-message-modal {
  max-width: 400px;
  padding: 24px;
  border-radius: 12px;
}
.ps-message-modal .ant-modal-body > svg {
  width: 56px;
  height: 56px;
}
.ps-message-modal .ant-modal-body h5 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 8px;
  font-weight: bold;
  color: #101828;
}
.ps-message-modal .ant-modal-body span.ant-typography {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.ps-message-modal .footer {
  margin-top: 32px;
  display: flex;
  gap: 12px;
}
.ps-message-modal .footer .ant-btn span {
  color: #344054;
  font-weight: 600;
}
.ps-message-modal .footer .ant-btn.ant-btn-primary span {
  color: #fff;
}
.ps-message-modal .footer .ant-btn.ant-btn-dangerous {
  background-color: #d92d20;
}`, "",{"version":3,"sources":["webpack://./src/common/MessageModal/styles.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;AACF;AACI;EACE,WAAA;EACA,YAAA;AACN;AACI;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,cAAA;AACN;AACI;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACN;AAEE;EACE,gBAAA;EACA,aAAA;EACA,SAAA;AAAJ;AAEM;EACE,cAAA;EACA,gBAAA;AAAR;AAGQ;EACE,WAAA;AADV;AAIM;EACE,yBAAA;AAFR","sourcesContent":[".ps-message-modal {\n  max-width: 400px;\n  padding: 24px;\n  border-radius: 12px;\n  .ant-modal-body {\n    & > svg {\n      width: 56px;\n      height: 56px;\n    }\n    h5 {\n      font-size: 18px;\n      margin-top: 10px;\n      margin-bottom: 8px;\n      font-weight: bold;\n      color: #101828;\n    }\n    span.ant-typography {\n      display: block;\n      font-size: 14px;\n      font-weight: 500;\n      line-height: 20px;\n    }\n  }\n  .footer {\n    margin-top: 32px;\n    display: flex;\n    gap: 12px;\n    .ant-btn {\n      span {\n        color: #344054;\n        font-weight: 600;\n      }\n      &.ant-btn-primary {\n        span {\n          color: #fff;\n        }\n      }\n      &.ant-btn-dangerous {\n        background-color: #d92d20;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
