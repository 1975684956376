// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outer-wrapper {
  margin-top: 10px;
  max-height: 204px;
  min-height: 50px;
  overflow: auto;
  scroll-behavior: smooth;
  scrollbar-color: #0499cb #e6e9ef;
  scrollbar-width: thin;
}
.outer-wrapper.services {
  max-height: 204px;
}
.outer-wrapper .ant-radio-group {
  padding-right: 5px;
}
.outer-wrapper .ant-radio-group .item .search-packages {
  margin-block: 16px;
}
.outer-wrapper .ant-radio-group .item:first-of-type .search-packages {
  margin-top: 8px;
}
.outer-wrapper .ant-checkbox-group {
  padding-right: 10px;
}
.outer-wrapper .ant-checkbox-group .item .search-packages {
  margin-block: 16px;
}
.outer-wrapper .ant-checkbox-group .item:first-of-type .search-packages {
  margin-top: 8px;
}
.outer-wrapper::-webkit-scrollbar {
  width: 5px;
}
.outer-wrapper::-webkit-scrollbar-track {
  background-color: #e6e9ef;
}
.outer-wrapper::-webkit-scrollbar-thumb {
  background-color: #0499cb;
}`, "",{"version":3,"sources":["webpack://./src/pages/Events/Modals/EditPackage/styles.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,uBAAA;EA0CA,gCAAA;EACA,qBAAA;AAxCF;AAFE;EACE,iBAAA;AAIJ;AAFE;EACE,kBAAA;AAIJ;AAFM;EACE,kBAAA;AAIR;AADQ;EACE,eAAA;AAGV;AAEE;EACE,mBAAA;AAAJ;AAEM;EACE,kBAAA;AAAR;AAGQ;EACE,eAAA;AADV;AAME;EACE,UAAA;AAJJ;AAOE;EACE,yBAAA;AALJ;AAQE;EACE,yBAAA;AANJ","sourcesContent":[".outer-wrapper {\n  margin-top: 10px;\n  max-height: 204px;\n  min-height: 50px;\n  overflow: auto;\n  scroll-behavior: smooth;\n  &.services {\n    max-height: 204px;\n  }\n  .ant-radio-group {\n    padding-right: 5px;\n    .item {\n      .search-packages {\n        margin-block: 16px;\n      }\n      &:first-of-type {\n        .search-packages {\n          margin-top: 8px;\n        }\n      }\n    }\n  }\n  .ant-checkbox-group {\n    padding-right: 10px;\n    .item {\n      .search-packages {\n        margin-block: 16px;\n      }\n      &:first-of-type {\n        .search-packages {\n          margin-top: 8px;\n        }\n      }\n    }\n  }\n  &::-webkit-scrollbar {\n    width: 5px;\n  }\n\n  &::-webkit-scrollbar-track {\n    background-color: #e6e9ef;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: #0499cb;\n  }\n\n  scrollbar-color: #0499cb #e6e9ef;\n  scrollbar-width: thin;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
