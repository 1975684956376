import React, { useState } from "react";
import { Row, Col, Card, Button } from "antd";
import logo2 from "../Assets/logo2.png";
import { Link } from "react-router-dom";
import "./BizWebsite.css";

const BusinessAddress = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };
  return (
    <Row className="businesswebsite-type-container">
      <Col span={3}>
        <div className="businesswebsite-logo-container">
          <img src={logo2} alt="Logo" className="logo" />
        </div>
      </Col>
      <Col span={21} className="businesswebsite-Form-container">
        <div className="businesswebsite-setup-title">
          <p>Base Setup</p>
        </div>
        <div>
          <p className="businesswebsite-business-type">
            Do your business have a website?
          </p>
        </div>
        <div className="businesswebsite-content-container">
          <div className="businesswebsite-card-container">
            <Card
              className={`businesswebsite-business-card ${selectedCard === "Venue" ? "selected" : ""}`}
              onClick={() => handleCardClick("Yes")}
            >
              Yes
            </Card>
            <Card
              className={`businesswebsite-business-card ${selectedCard === "SPA" ? "selected" : ""}`}
              onClick={() => handleCardClick("No")}
            >
              No
            </Card>
            <Link to="/businessemail">
              <button className="businesswebsite-next-button">
                Save & go next
              </button>
            </Link>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default BusinessAddress;
