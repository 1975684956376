import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Input, Popover, Empty, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LinkIcon } from "../../../assets/icons/arrow-small-right.svg";
import { globalSearch } from "../../../services/dashboard";
import "./Header.scss";

const Search = () => {
  const venue_id = useSelector((state) => state?.venues?.selectedVenue?.id);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (!open) {
      setResults([]);
      setSearchValue("");
    }
  }, [open]);

  const links = [
    {
      type: "Events",
      link: (id) => navigate("/event/details", { state: { id: id } }),
    },
    {
      type: "Rooms",
      link: (id) => navigate(`/company/venues/${venue_id}/rooms/${id}`),
    },
    {
      type: "Packages",
      link: () => navigate("/company/packages"),
    },
    {
      type: "Services",
      link: () => navigate("/company/services"),
    },
  ];

  const searchContent = async (search) => {
    setLoading(true);
    try {
      const res = await globalSearch(venue_id, search);
      setOpen(true);
      setResults(res?.data?.data);
    } finally {
      setLoading(false);
    }
  };

  const SearchContent = () => {
    return (
      <Spin spinning={loading}>
        <div className="searched-wrapper">
          {results?.length ? (
            results?.map((res, i) => {
              const type = links?.find((item) => item?.type === res?.module);
              return (
                <div key={i} className="item">
                  <label>{res?.value}</label>
                  <div
                    className="link"
                    onClick={() => {
                      type?.link(res?.id);
                      setOpen(false);
                    }}
                  >
                    {type?.type} <LinkIcon />
                  </div>
                </div>
              );
            })
          ) : (
            <div style={{ marginBlock: "24px" }}>
              <Empty description="" />
            </div>
          )}
        </div>
      </Spin>
    );
  };

  return (
    <>
      <Popover
        trigger="click"
        open={open}
        content={<SearchContent />}
        title={`Search Results: (${results.length})`}
        onOpenChange={(val) => setOpen(val)}
        placement="bottomLeft"
      >
        <Input
          style={{
            backgroundColor: "rgb(249, 253, 255)",
            border: "none",
            width: 400,
            marginRight: "auto",
            marginLeft: "20px",
          }}
          loading={loading}
          size="large"
          placeholder="Search"
          className="input-search"
          value={searchValue}
          onChange={(e) => {
            const val = e.target.value;
            setSearchValue(val);
            if (val.length > 2) {
              searchContent(val);
            } else {
              setResults([]);
            }
          }}
          // onFocus={() => {
          //     if (!open) {
          //         setOpen(true)
          //     }
          // }}
          prefix={<SearchOutlined />}
        />
      </Popover>
    </>
  );
};

export default Search;
