import React,{useState, useEffect} from 'react'
import { Button, Modal } from 'antd';

const WelcomeMessageModal = ({ closeWelcomeModal, welcomeModal }) => {

  const [userInfo, setUserInfo] = useState("");

  useEffect(() => {
    const getUser = JSON.parse(localStorage.getItem("planspace_user_obj"));
    setUserInfo(getUser.data || getUser);
  }, [localStorage.getItem("planspace_user_obj")]);

  return (
    <div>
      <Modal title={`Hey there, ${userInfo?.full_name || ""} I am PlanBot`} centered onCancel={closeWelcomeModal} open={welcomeModal}
        footer={[
          <Button style={{ padding: "0px 30px" }} key="submit" type="primary" onClick={closeWelcomeModal}>
            Let's Start
          </Button>
        ]}
      >
        <p>In this space we can create a Booking Tool so that you can accept online bookings. <br/> I will guide you to create the booking tool.</p>
      </Modal>
    </div>
  )
}

export default WelcomeMessageModal