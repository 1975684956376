import React, { useState, useEffect } from "react";
import moment from "moment";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Popover, Button, Typography, Avatar, Modal } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { deleteEvent } from "../../../services/events";
import usePopup from "../../../common/MessageModal/usePopup";
import StagesDropdown from "./StagesDropdown";
import StatusDropdown from "./StatusDropdown";

// icons
import { ReactComponent as EditIcon } from "../../../assets/icons/pencil.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/trash.svg";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";
import { ReactComponent as EventIcon } from "../../../assets/icons/event.svg";
import { ReactComponent as RoomsIcon } from "../../../assets/icons/badge.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/user-icon.svg";
import { ReactComponent as EnvelopeIcon } from "../../../assets/icons/envelope.svg";

import "./calendar.scss";

const CalendarView = ({ events, fetchEvents, setCreateNewEvent }) => {
  const popup = usePopup();
  const { confirm } = Modal;
  const { Text } = Typography;
  const [open, setOpen] = useState(false);
  const [calenderHeight, setCalenderHeight] = useState(500);

  useEffect(() => {
    var planSpaceFullCalendar = document.getElementById(
      "planSpaceFullCalendar",
    );
    setCalenderHeight(planSpaceFullCalendar.scrollHeight);
  }, []);

  const calendarEvents = events?.map((event) => ({
    id: event.id,
    eventId: event?.id,
    title: event?.title,
    start: event?.event_date + " " + event?.start_time,
    end: event?.event_date + " " + event?.end_time,
    host: event.contacts[0]?.full_name,
    status: event?.status,
    startETime: event?.start_time,
    endETime: event?.end_time,
    editable: false,
    overlap: false,
    stage: event?.stage,
    eventData: event,
  }));

  const deleteThisEvent = async (event) => {
    try {
      const res = await deleteEvent(event.id);
      popup.success("Success!", res?.data?.message);
      fetchEvents();
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = "/server-error";
        console.log("Redirecting to server error page...");
      } else {
        popup.error("Oops...", error?.response?.data?.message);
      }
    }
  };

  const Card = ({ event }) => {
    return (
      <div className="calendar-event-card">
        <div className="card-header">
          <StatusDropdown
            style={{ minWidth: 100 }}
            eventId={event?.id}
            value={event?.status}
            size="small"
            fetchEvents={fetchEvents}
          />
          <StagesDropdown
            style={{ minWidth: 100 }}
            event={event}
            value={event?.stage}
            size="small"
            fetchEvents={fetchEvents}
          />
          <Button
            size="small"
            type="text"
            shape="circle"
            icon={<EditIcon width={14} height={14} />}
            onClick={() => {
              setCreateNewEvent({ ...event, type: "edit" });
              setOpen(false);
            }}
          />
          <Button
            size="small"
            type="text"
            shape="circle"
            icon={<DeleteIcon width={14} height={14} />}
            onClick={() => {
              popup.confirm(
                `Delete "${event.title}"?`,
                `Are you sure you want to delete this event?`,
                () => deleteThisEvent(event),
              );
              setOpen(false);
            }}
          />
          <Button
            size="small"
            type="text"
            shape="circle"
            icon={<CrossIcon width={14} height={14} />}
            onClick={() => setOpen(false)}
          />
        </div>
        <div className="details-item">
          <EventIcon className={event?.status} style={{ marginTop: "2px" }} />
          <div className="details">
            <Text
              style={{
                color: "#1D1F2C",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: 1,
              }}
            >
              {event?.title}
            </Text>
            <Text style={{ fontSize: 14, fontWeight: 400, color: "#667085" }}>
              {moment(event?.event_date).format("MMMM DD, YYYY")} •{" "}
              {event?.start_time
                ? moment(event?.start_time, "HH:mm:ss").format("LT")
                : null}{" "}
              -{" "}
              {event?.end_time
                ? moment(event?.end_time, "HH:mm:ss").format("LT")
                : null}
            </Text>
          </div>
        </div>
        <div className="details-item">
          <RoomsIcon />
          <div className="details">
            <Text
              style={{
                color: "#1D1F2C",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: 1,
              }}
            >
              Room
            </Text>
            <Text style={{ fontSize: 14, fontWeight: 400, color: "#667085" }}>
              {event?.rooms[0]?.name}
            </Text>
          </div>
        </div>
        <div className="details-item">
          <UserIcon />
          <div className="details">
            <Text
              style={{
                color: "#1D1F2C",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: 1,
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              Organizer
              <EnvelopeIcon style={{ marginLeft: "auto" }} />
            </Text>
            <Text style={{ fontSize: 14, fontWeight: 400, color: "#667085" }}>
              <Avatar
                size="small"
                icon={<UserOutlined />}
                style={{ marginBlock: 6, marginRight: 5 }}
              />
              {event?.contacts[0]?.email}
            </Text>
          </div>
        </div>
      </div>
    );
  };

  const eventContent = (eventInfo) => {
    return (
      <Popover
        placement="rightTop"
        content={<Card event={eventInfo?.event?.extendedProps?.eventData} />}
        trigger="click"
        open={open === eventInfo?.event?.id}
        onOpenChange={(val) => setOpen(val ? eventInfo?.event?.id : val)}
      >
        <div
          className={`calender-event ${eventInfo?.event?.extendedProps?.status}`}
        >
          <h4>{eventInfo?.event?.title}</h4>
          <div className="time">
            {moment(
              eventInfo?.event?.extendedProps?.startETime,
              "HH:mm:ss",
            ).format("h:mm a")}{" "}
            -{" "}
            {moment(
              eventInfo?.event?.extendedProps?.endETime,
              "HH:mm:ss",
            ).format("h:mm a")}
          </div>
        </div>
      </Popover>
    );
  };

  const handleDateClick = (arg) => {
    const clickedDate = arg.date;
    const targetDate = moment(clickedDate);
    const currentDate = moment();
    if (targetDate.isAfter(currentDate)) {
      setCreateNewEvent({ clickedDate, type: "calendar_date" });
    } else {
      popup.error("Sorry!", "You can't create an event in the past.");
    }
  };

  return (
    <div className="planSpace-fullCalendar" id="planSpaceFullCalendar">
      <FullCalendar
        plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin]}
        initialView="timeGridWeek"
        height={calenderHeight}
        allDaySlot={false}
        customButtons={{
          showTitle: {
            text: "My Schedule",
            className: "test",
          },
        }}
        headerToolbar={{
          left: "showTitle,today",
          center: "prev title next",
          right: "timeGridDay,timeGridWeek,dayGridMonth",
        }}
        titleFormat={{
          month: "long",
          year: "numeric",
        }}
        buttonText={{
          today: "Today",
          day: "Day",
          week: "Week",
          month: "Month",
          year: "Year",
        }}
        displayEventTime={true}
        eventContent={eventContent}
        dayHeaderContent={(args) => {
          return (
            <>
              <span>{moment(args.date).format("ddd")}</span>
              {moment(args.date).format("DD")}
            </>
          );
        }}
        events={calendarEvents}
        dateClick={handleDateClick}
      />
    </div>
  );
};

export default CalendarView;
