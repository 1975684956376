import React, { useState, useEffect } from "react";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import usePopup from "../../../../../common/MessageModal/usePopup";
import {
  createCheckoutSession,
  madePayment,
  getPaymentMethods,
} from "../../../../../services/events";

// UI's
import { Button, Modal, Spin } from "antd";
import { Form, Input } from "antd";

import { ReactComponent as CardIcon } from "../../../../../assets/icons/credit-card.svg";
import { ReactComponent as CashIcon } from "../../../../../assets/icons/cash.svg";


import AddCard from "./AddCard";
import StripeAccountError from "./StripeAccountError";
import toast from "react-hot-toast";

const PaymentMode = ({
  open,
  handelClose,
  payment,
  setPayButtonLoading,
  fetchAllPayments,
  event,
  stripePromise,
  deleteCreditCard,
  accountID
}) => {
  const popup = usePopup();
  const [form] = Form.useForm();
  const [paymentType, setPaymentType] = useState("credit");
  const [chequeImage, setChequeImage] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openCheckout, setOpenCheckout] = useState(false);

  const [cards, setCards] = useState([]);
  const [checkCreditCardinfo, setCheckCreditinfo] = useState(true);


  const handleCancel = () => {
    handelClose();
    form.resetFields();
    setChequeImage(null);
    setPaymentType("credit");
  };

  const madePaymentOnServer = async (data) => {
    console.log(data.notes);

    let formData = new FormData();
    if (paymentType === "cheque" && chequeImage.length > 0) {
      for (let i = 0; i < chequeImage.length; i++) {
        formData.append("files", chequeImage[i].originFileObj);
      }
    }
    formData.append("payment_id", payment?.id);
    formData.append("type", paymentType);
    formData.append("paid_by_email", event?.contacts[0]?.email);
    formData.append("notes", data?.notes || "");
    setLoading(true);
    try {
      const res = await madePayment(formData);
      popup.success("Success!", res.data.message);
      fetchAllPayments();
      handelClose();
      form.resetFields();
    } catch (error) {
      popup.error("Opss!", error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const createSession = async () => {
    await createCheckoutSession({ payment_id: payment?.id }).then((response) => {
      setClientSecret(response.data.data.client_secret);
      setOpenCheckout(true);
    }).catch(error => {
      toast.error(error?.response?.data?.message);
      setOpenCheckout(false);
    });
  };

  // useEffect(() => {
  //     createSession();
  // }, [paymentType, open])

  // const handleSubmit = async (values) => {
  //   if (
  //     paymentType === "cash" ||
  //     paymentType === "cheque" ||
  //     paymentType === "other"
  //   ) {
  //     madePaymentOnServer(payment?.id);
  //     return;
  //   }
  //   else {
  //     createSession()
  //   }

  //   setLoading(true);
  //   // get payment intent client secret from backend
  //   // let clientSecret;
  //   // await myApi
  //   //   .post(
  //   //     `${process.env.REACT_APP_BASE_API_URL}/api/company/payment/intent/`,
  //   //     { payment_id: payment?.id }
  //   //   )
  //   //   .then(async (result) => {
  //   //     clientSecret = result.data.data;
  //   //     const card = elements.getElement(CardElement);
  //   //     console.log(card)
  //   //     await stripe
  //   //       .confirmCardPayment(
  //   //         clientSecret?.client_secret
  //   //           ? clientSecret?.client_secret
  //   //           : clientSecret,
  //   //         {
  //   //           payment_method: {
  //   //             card: card,
  //   //           },
  //   //         }
  //   //       )
  //   //       .then((result) => {
  //   //         if (result.error) {
  //   //           popup.error("Opss!", result.error.message);
  //   //           console.log(result.error)
  //   //           setLoading(false);
  //   //         } else {
  //   //           setPayButtonLoading(payment?.id);
  //   //           setLoading(true);
  //   //           myApi
  //   //             .post(
  //   //               `${process.env.REACT_APP_BASE_API_URL}/api/company/payment/confirm/`,
  //   //               {
  //   //                 payment_id: payment?.id,
  //   //                 payment_intent_id: result.paymentIntent.id,
  //   //               }
  //   //             )
  //   //             .then((result) => {
  //   //               popup.success("Success!", result.data.message);
  //   //               console.log(result)
  //   //               handelClose();
  //   //               form.resetFields();
  //   //               fetchAllPayments();
  //   //             })
  //   //             .catch((error) => {
  //   //               popup.error("Opss!", error?.response?.data?.message);
  //   //               console.log(error);
  //   //             })
  //   //             .finally(() => {
  //   //               setLoading(true);
  //   //               setPayButtonLoading(false);
  //   //             });
  //   //         }
  //   //       })
  //   //       .finally(() => {
  //   //         setLoading(false);
  //   //       });
  //   //   })
  //   //   .catch((error) => {
  //   //     console.log(error)
  //   //     let message = error?.response?.data?.message;
  //   //     let errorMessage = "";
  //   //     if (Array.isArray(message)) {
  //   //       for (let i = 0; i < message.length; i++) {
  //   //         let msg = message[i];
  //   //         // if msg is an object
  //   //         if (typeof msg === "object") {
  //   //           for (let key in msg) {
  //   //             errorMessage += `${msg[key][0]}`;
  //   //           }
  //   //         } else if (typeof msg === "string") {
  //   //           errorMessage += `${msg}`;
  //   //         }
  //   //       }
  //   //     }
  //   //     console.log(errorMessage)
  //   //     popup.error("Opss!", errorMessage);
  //   //   })
  //   //   .finally(() => {
  //   //     setLoading(false);
  //   //   });
  // };

  //   useEffect(() => {
  //     if (cards?.length == 0) {
  //         createSession();
  //     }
  // }, [paymentType, open])
  // useEffect(() => {
    // getPaymentMethodsList();
  // }, [getPaymentMethods]);

  const getPaymentMethodsList = async () => {
    try {
      const res = await getPaymentMethods(event.id);
      setCards(res?.data?.data);
    } catch (error) {
      console.error("Error fetching payment methods:", error);
    } finally {
      setCheckCreditinfo(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const data = {
      paymentid: payment.id,
      notes: values.notes,
    };
    if (
      (paymentType == "credit" && cards.length > 0) ||
      paymentType == "other"
    ) {
      await madePaymentOnServer(data).then(() => {
        setLoading(false);
        // fetchAllPayments();
        // getPaymentMethodsList();
        return;
      });
    } else {
      await createSession();
    }
    setLoading(false);

    // get payment intent client secret from backend
    // let clientSecret;
    // await myApi.post(${process.env.REACT_APP_BASE_API_URL}/api/company/payment/intent/, { payment_id: payment?.id }).then(async (result) => {
    //     clientSecret = result.data.data
    //     const card = elements.getElement(CardElement);
    //     await stripe.confirmCardPayment(clientSecret?.client_secret ? clientSecret?.client_secret : clientSecret, {
    //         payment_method: {
    //             card: card,
    //         },
    //     }).then((result) => {
    //         if (result.error) {
    //             popup.error('Opss!', result.error.message);
    //             setLoading(false);
    //         } else {
    //             setPayButtonLoading(payment?.id);
    //             setLoading(true);
    //             myApi.post(${process.env.REACT_APP_BASE_API_URL}/api/company/payment/confirm/, { payment_id: payment?.id, payment_intent_id: result.paymentIntent.id }).then((result) => {
    //                 popup.success('Success!', result.data.message);
    //                 handelClose();
    //                 form.resetFields();
    //                 fetchAllPayments();
    //             }).catch((error) => {
    //                 popup.error('Opss!', error?.response?.data?.message);
    //             }).finally(() => {
    //                 setLoading(true);
    //                 setPayButtonLoading(false);
    //             })
    //         }
    //     }).finally(() => {
    //         setLoading(false);
    //     });
    // }).catch((error) => {
    //     let message = error?.response?.data?.message;
    //     let errorMessage = ""
    //     if (Array.isArray(message)) {
    //         for (let i = 0; i < message.length; i++) {
    //             let msg = message[i];
    //             // if msg is an object
    //             if (typeof msg === 'object') {
    //                 for (let key in msg) {
    //                     errorMessage += ${msg[key][0]};
    //                 }
    //             } else if (typeof msg === 'string') {
    //                 errorMessage += ${msg}
    //             }
    //         }
    //     }
    //     popup.error('Opss!', errorMessage);
    // })
    //     .finally(() => {
    //         setLoading(false);
    //     });
  };

  const renderdButtons = () => {
    let buttons = [
      <Button size={"large"} key="cancel" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button
        style={{ marginLeft: "auto" }}
        size={"large"}
        key="next"
        type="primary"
        onClick={() => form.submit()}
        loading={loading}
        disabled={paymentType == "credit" && !accountID}
      >
        Pay
      </Button>,
    ];
    return buttons;
  };
  const handleAddCard = async () => {
    try {
      await getPaymentMethodsList();
      // fetchAllPayments();
      getPaymentMethodsList();
    } catch (error) {
      console.error("Error adding card:", error);
    }
  };
  const payment_types = [
    {
      label: "Credit / Debit",
      value: "credit",
      icon: <CardIcon />,
    },
    {
      label: "Other Methods",
      value: "other",
      icon: <CashIcon />,
    },
  ];

  return (
    <>
      {open && (
        <Modal
          centered
          title={"Add payment method"}
          open={open}
          onCancel={handleCancel}
          footer={
            // paymentType == "credit" && cards.length == 0 ? "" :
            renderdButtons()
          }
        >
          <Form
            style={{ marginTop: 24 }}
            form={form}
            name="validateOnly"
            layout="vertical"
            autoComplete="off"
            className="modal-form payments-modal"
            onFinish={handleSubmit}
          >
            <div className="options">
              {payment_types.map((item, i) => (
                <div
                  key={i}
                  className={`item ${item.value === paymentType && "active"}`}
                  onClick={() => setPaymentType(item.value)}
                >
                  {item.icon}
                  {item.label}
                </div>
              ))}
            </div>
            {paymentType === "credit" && !accountID ? (
                <StripeAccountError />
              // <Form.Item
              //   name="cheque"
              //   label="Check front & back Images*"
              //   rules={[
              //     {
              //       required: true,
              //       message: "Check front and back images are required!",
              //     },
              //   ]}
              // >
              //   <ImageUploader
              //     max_length={2}
              //     input={chequeImage}
              //     output={(val) => {
              //       if (val?.length > 1) {
              //         form.setFieldValue("cheque", true);
              //       } else {
              //         form.setFieldValue("cheque", "");
              //       }
              //       setChequeImage(val);
              //     }}
              //   />
              // </Form.Item>
            ) : null}
            {paymentType === "other" ? (
              <>
                <Form.Item label="Name">
                  <Input
                    value={payment?.created_user}
                    size="large"
                    placeholder="Name is missing"
                  />
                </Form.Item>
                <Form.Item label="Amount $">
                  <Input
                    prefix={<>$</>}
                    value={payment?.payment_expected}
                    size="large"
                    readOnly
                    placeholder="Amount missing"
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <>
                      {" "}
                      Note <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  name="notes"
                  rules={[
                    {
                      required: true,
                      message: "Please add Notes!",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Instructions..." />
                </Form.Item>
              </>
            ) : null}
          </Form>
        </Modal>
      )}
      {(openCheckout && accountID) && (
        <Modal
          centered
          // title={"Payment"}
          open={true}
          onCancel={() => setOpenCheckout(false)}
          footer={[]}
        >
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
              clientSecret: clientSecret,
              onComplete: () => madePaymentOnServer(payment?.id),
            }}
          >
            <EmbeddedCheckout id="card-element" />
          </EmbeddedCheckoutProvider>
        </Modal>
      )}

      {/* <AddCard
        open={openAddCard}
        handelClose={() => setOpenAddCard(false)}
        event={event}
        deleteCreditCard={deleteCreditCard}
        handleAddCard={handleAddCard}
        fetchAllPayments={fetchAllPayments}
        // getPaymentMethodsList={getPaymentMethodsList}
      /> */}
    </>
  );
};

export default PaymentMode;
