import { Modal, Upload } from "antd";
import React, { useState, useEffect } from "react";
import "./EditServiceForm.scss";

import { ReactComponent as CrossIcon } from "../../../../assets/Modals/CrossIcon.svg";
import { ReactComponent as BackArrow } from "../../../../assets/Modals/backArrow.svg";
import { Input, Select, Row, Col, Spin } from "antd";
// import AddImage from "../AddImage";
import axios from "axios";
import { useSelector } from "react-redux";

const options = [];
for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}

const EditServiceForm = ({ open, setOpen, getServices, serviceData }) => {
  const location = useSelector((state) => state.venues.selectedVenue);
  const [isfirstScreen, setIsFirstScreen] = useState(true);
  const [isSecondScreen, setIsSecondScreen] = useState(false);
  const [uploadImageURL, setUploadImageURL] = useState("");
  const [file, setFile] = useState(null);
  const [serviceName, setServiceName] = useState(serviceData?.name);
  const [isSpin, setIsSpin] = useState(false);
  const [description, setDescription] = useState(serviceData?.description);
  const [price, setPrice] = useState(serviceData?.price);
  const [room, setRoom] = useState(serviceData?.room);
  const [rooms, setRooms] = useState([]);

  const [isNameError, setIsNameError] = useState(false);
  const [isDescriptionError, setIsDescriptionError] = useState(false);
  const [isPriceError, setIsPriceError] = useState(false);

  const submitData = () => {
    setIsSpin(true);
    const formData = new FormData();
    if (serviceName) {
      formData.append("name", serviceName);
    }

    if (description) {
      formData.append("description", description);
    }

    if (price) {
      formData.append("price", price);
    }

    if (room?.id) {
      formData.append("room", room?.id);
    } else {
      formData.append("room", room);
    }
    if (file) {
      formData.append("image", file);
    }
    formData.append("location", location?.id);

    axios
      .put(
        `${process.env.REACT_APP_BASE_API_URL}/api/company/addon/${serviceData?.id}`,
        formData,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("access_token")}`,
          },
        },
      )
      .then((res) => {
        setFile(null);
        setUploadImageURL(null);
        setServiceName("");
        setDescription("");
        setPrice("");
        setIsSpin(false);
        setOpen(false);
        getServices();
      })
      .catch(() => {
        setIsSpin(false);
      });
  };

  const getRooms = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_API_URL}/api/company/room/?location=${location?.id}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("access_token")}`,
          },
        },
      )
      .then((res) => {
        setRooms(res.data.results);
      });
  };

  useEffect(() => {
    getRooms();
  }, []);

  const dummyRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    customRequest: dummyRequest,
    onRemove: (file) => {
      // setFile(null);
      // setUploadImageURL("");
    },

    // beforeUpload(file, fileList) {
    //   const isJpgOrPng =
    //     file.type === "image/jpeg" || file.type === "image/png";
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   if (!isJpgOrPng) {
    //     setMsgModalData({
    //       title: "Image Upload",
    //       data: "You can only upload <b>JPG/PNG</b> file!",
    //       error: true,
    //     });
    //   }
    //   if (!isLt2M) {
    //     setMsgModalData({
    //       title: "Image Upload",
    //       data: "Image must smaller than <b>2MB</b>!",
    //       error: true,
    //     });
    //   }
    //   return isJpgOrPng && isLt2M;
    // },
    onChange(info) {
      const { status } = info.file;

      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
        setFile(info.file.originFileObj);
        setUploadImageURL(URL.createObjectURL(info.file.originFileObj));
      } else if (status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },

    onDrop(e) {
      console.log("Dropped files", e);
    },
  };

  return (
    <>
      <Modal
        title={null}
        centered
        open={open}
        onOk={() => setOpen(false)}
        footer={null}
        className="edit-service"
        closable={false}
        onCancel={() => setOpen(false)}
      >
        <div className="header">
          <div className="header-left">
            <BackArrow
              onClick={() => {
                if (isfirstScreen) {
                  setOpen(false);
                } else {
                  setIsFirstScreen(true);
                  setIsSecondScreen(false);
                }
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
          <div className="header-center">Edit Service</div>
          <div className="header-right">
            <CrossIcon
              onClick={() => {
                setOpen(false);
                setIsNameError(false);
                setIsPriceError(false);
                setIsDescriptionError(false);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </div>

        {isfirstScreen && (
          <Spin spinning={isSpin} delay={400}>
            <div className="content-edit-company-2">
              <div className="content-edit-company-input-wrapper">
                <Input
                  placeholder="Service Name"
                  size="large"
                  value={serviceName}
                  onChange={(e) => setServiceName(e.target.value)}
                />
                {isNameError && (
                  <span style={{ color: "red" }}>Service Name is required</span>
                )}
              </div>
              <div className="content-edit-company-input-wrapper">
                <Input
                  placeholder="Description"
                  size="large"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                {isDescriptionError && (
                  <span style={{ color: "red" }}>
                    Service description is required
                  </span>
                )}
              </div>

              <Row gutter={[16, 16]}>
                <Col md={24}>
                  <div className="content-edit-company-input-wrapper">
                    <Input
                      placeholder="Price"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      size="large"
                    />
                    {isPriceError && (
                      <span style={{ color: "red" }}>
                        Service price is required
                      </span>
                    )}
                  </div>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col md={24}>
                  <div className="content-edit-company-input-wrapper">
                    <Select
                      placeholder="Select Room to associate with"
                      style={{
                        width: "100%",
                      }}
                      onChange={(value) => {
                        setRoom(value);
                      }}
                      defaultValue={{
                        label: room?.name,
                        value: room?.id,
                      }}
                      size="large" // onChange={handleChange}
                      options={rooms.map((room) => {
                        return {
                          value: room.id,
                          label: room.name,
                        };
                      })}
                    />
                  </div>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col sm={12}>
                  {uploadImageURL ? (
                    <Upload
                      {...props}
                      showUploadList={false}
                      accept=".jpg, .jpeg, .png"
                    >
                      <div className="add-new-img">
                        <img
                          src={uploadImageURL}
                          alt="upload"
                          style={{
                            width: "100%",
                          }}
                        />
                      </div>
                    </Upload>
                  ) : serviceData?.image && !uploadImageURL ? (
                    <Upload
                      {...props}
                      showUploadList={false}
                      accept=".jpg, .jpeg, .png"
                    >
                      <div className="add-new-img">
                        <img
                          src={serviceData?.image}
                          alt="upload"
                          style={{
                            width: "100%",
                          }}
                        />
                      </div>
                    </Upload>
                  ) : (
                    <div className="add-new-img">
                      <span>Add Image</span>
                      <Upload
                        {...props}
                        showUploadList={false}
                        accept=".jpg, .jpeg, .png"
                      >
                        <button onClick={() => {}}>Add</button>
                      </Upload>
                    </div>
                  )}
                </Col>
                <Col md={12}></Col>
              </Row>

              {/* <Row gutter={[16, 16]}>
              <Col sm={12}>
                <div className="add-new-img">
                  <span>Add Image</span>
                  <button
                    onClick={() => {
                      setIsAddImage(true);
                      setOpen(false);
                    }}
                  >
                    Add
                  </button>
                </div>
              </Col>
              <Col md={12}></Col>
            </Row> */}
            </div>
          </Spin>
        )}

        <div className="footer">
          <div className="footer-left"></div>
          <div className="footer-center"></div>
          <div className="footer-right">
            <div className="back-btn">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  if (serviceName && description && price) {
                    // setIsActive(false);
                    setIsNameError(false);
                    setIsDescriptionError(false);
                    setIsPriceError(false);
                    submitData();
                  } else {
                    if (!serviceName) {
                      setIsNameError(true);
                    }
                    if (!description) {
                      setIsDescriptionError(true);
                    }
                    if (!price) {
                      setIsPriceError(true);
                    }
                  }
                }}
              >
                Save
              </button>
            </div>
            <div className="next-btn">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  if (serviceName && description && price) {
                    submitData(true);
                    setIsNameError(false);
                    setIsDescriptionError(false);
                    setIsPriceError(false);
                  } else {
                    if (!serviceName) {
                      setIsNameError(true);
                    }
                    if (!description) {
                      setIsDescriptionError(true);
                    }
                    if (!price) {
                      setIsPriceError(true);
                    }
                  }
                }}
              >
                Save & Activate
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* <AddImage open={isAddImage} setOpen={setIsAddImage} /> */}
    </>
  );
};
export default EditServiceForm;
