import React, { useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabList, TabContext, TabPanel } from "@mui/lab";

import { UserOutlined, LockOutlined, BellOutlined } from "@ant-design/icons"; // Import Ant Design icons

import ProfileSetting from "./ProfileSetting";
import PasswordChange from "./PasswordChange";
import Notification from "./Notification";
// import Notifications from './notifications';
// import UserSubscriptions from './userSubscriptions';

// style
import "./userSettings.scss";

const UserSettings = () => {
  const [tabIndex, setTabIndex] = useState("profile");

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <TabContext value={tabIndex}>
          <Box className="event-tab-header settings-header">
            <TabList
              className="tabs-header"
              onChange={(e, value) => setTabIndex(value)}
              aria-label="settings tab"
            >
              <Tab
                icon={<UserOutlined />}
                iconPosition="start"
                label="My Profile"
                value="profile"
              />
              <Tab
                icon={<LockOutlined />}
                iconPosition="start"
                label="Password"
                value="password"
              />
              {/* <Tab icon={<BellOutlined/>} iconPosition="start" label="Notification" value="notification" /> */}
            </TabList>
          </Box>
          <TabPanel className="events-content settings-content" value="profile">
            <div className="scroll-container">
              <ProfileSetting />
            </div>
          </TabPanel>
          <TabPanel
            className="events-content settings-content"
            value="password"
          >
            <div className="scroll-container">
              <PasswordChange />
            </div>
          </TabPanel>
          {/* <TabPanel className="events-content settings-content" value="notification">
          <div className="scroll-container">
            <Notification />
          </div>
        </TabPanel> */}
        </TabContext>
      </Box>
    </>
  );
};

export default UserSettings;
