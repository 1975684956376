import React, { useState } from "react";
import { Row, Col } from "antd";
import "./VenueDetails.scss";
import { ReactComponent as EditIcon } from "./../../../../assets/Menu/edit.svg";
import { ReactComponent as DelIcon } from "./../../../../assets/DeleteWithCircle.svg";
import { ReactComponent as MailIcon } from "./../../../../assets/email.svg";
import { ReactComponent as PhoneIcon } from "./../../../../assets/phone.svg";
import { ReactComponent as EditIcon2 } from "../../../../assets/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/deleteIcon.svg";
import { ReactComponent as LocIcon } from "./../../../../assets/location.svg";
import roomImg from "./../../../../assets/background.jpg";
import ConfirmationModal from "../../Modals/ConfirmationModal";
import EditLocation from "../../Modals/EditLocation";
import client from "../../../../axios";
import RoomDetails from "./RoomDetails";
import AddNewRoom from "../../Modals/AddNewRoom";

const VenueDetails = ({
  venueData,
  getVenues,
  setIsVenueDetails,
  isRoomDetails,
  setIsRoomDetails,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfOpen, setIsConfOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isRoomModalOpen, setIsRoomModalOpen] = useState(false);
  const [currentRoom, setCurrentRoom] = useState(null);

  const deleteVenue = () => {
    try {
      setIsLoading(true);
      client
        .delete(
          `${process.env.REACT_APP_BASE_API_URL}/api/company/location/${venueData?.id}/`,
        )
        .then((res) => {
          setIsLoading(false);
          setIsVenueDetails(false);
          setIsRoomDetails(false);
          getVenues();
          setIsModalOpen(false);
        });
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = "/server-error";
        console.log("Redirecting to server error page...");
      } else {
        console.log(error);
      }
    }
  };

  const deleteRoom = async () => {
    setIsRoomDetails(false);
    setIsVenueDetails(true);
    localStorage.removeItem("currentRoom");
    setIsLoading(true);
    try {
      await client.delete(
        `${process.env.REACT_APP_BASE_API_URL}/api/company/room/${isConfOpen?.id}/`,
      );
      getVenues(venueData?.id);
      setIsConfOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isRoomDetails ? (
        <RoomDetails
          venueData={venueData}
          setIsConfOpen={setIsConfOpen}
          editRoom={(val) => setIsRoomModalOpen({ ...val, type: "edit" })}
        />
      ) : (
        <div className="venue-details-wrapper">
          <Row gutter={[16, 16]}>
            <Col sm={24} md={12} lg={8} xl={8} xxl={8} className="c1">
              <dir>
                <img
                  src={
                    venueData?.images.length
                      ? venueData?.images[0]?.image
                      : roomImg
                  }
                  // src={venueData.rooms[0].images}
                  alt=""
                  srcset=""
                  style={{
                    width: "100%",
                  }}
                />
              </dir>
            </Col>
            <Col sm={24} md={12} lg={8} xl={4} xxl={4} className="c2">
              <div>
                <img
                  src={venueData?.images[1] ? venueData?.images[1] : roomImg}
                  alt=""
                  srcset=""
                  style={{
                    width: "100%",
                  }}
                />
              </div>
              <div>
                <img
                  src={venueData?.images[2] ? venueData?.images[2] : roomImg}
                  alt=""
                  srcset=""
                  style={{
                    width: "100%",
                  }}
                />
              </div>
            </Col>
            <Col sm={24} md={12} lg={8} xl={4} xxl={4} className="c3">
              <div>
                <img
                  src={venueData?.images[3] ? venueData?.images[3] : roomImg}
                  alt=""
                  srcset=""
                  style={{
                    width: "100%",
                  }}
                />
              </div>
              <div>
                <img
                  src={venueData?.images[4] ? venueData?.images[4] : roomImg}
                  alt=""
                  srcset=""
                  style={{
                    width: "100%",
                  }}
                />
              </div>
            </Col>
            <Col sm={24} md={12} lg={8} xl={8} xxl={8} className="c4">
              <div className="venue-details">
                <span>{venueData?.name}</span>
                <span
                  className="edit-icon"
                  onClick={() => setIsModalOpen(true)}
                >
                  <DelIcon />
                </span>
                <span
                  className="edit-icon"
                  onClick={() => setIsEditModalOpen(true)}
                >
                  <EditIcon />
                </span>
              </div>
              <Row gutter={[16, 16]} className="venue-data">
                <Col span={10}>
                  <p>
                    {" "}
                    <MailIcon />
                    &nbsp; Email
                  </p>
                  <p style={{ display: "flex" }}>
                    <PhoneIcon />
                    &nbsp;&nbsp;Phone
                  </p>
                  <p style={{ display: "flex" }}>
                    <LocIcon />
                    &nbsp;Address
                  </p>
                </Col>
                <Col span={14}>
                  <p> :&nbsp;&nbsp;{venueData?.email}</p>
                  <p>:&nbsp; {venueData?.phone}</p>
                  <p>
                    :&nbsp; {venueData?.address}, {venueData?.zip_code?.city},{" "}
                    {venueData?.zip_code?.state},{" "}
                    {venueData?.zip_code?.zip_code}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="rooms-wrapper">
            <div className="header">
              <h1>Room</h1>
              <button onClick={() => setIsRoomModalOpen(true)}>Add Room</button>
            </div>

            <Row gutter={[16, 16]}>
              {venueData?.rooms?.map((room) => {
                return (
                  <Col key={room.id} md={12}>
                    <div className="room-card">
                      <div className="package-img">
                        <div className="btns">
                          <span
                            className="btn"
                            onClick={() => {
                              setIsRoomModalOpen({ ...room, type: "edit" });
                            }}
                          >
                            <EditIcon2 />
                          </span>
                          <span
                            className="btn"
                            onClick={() => {
                              setIsConfOpen(room);
                            }}
                          >
                            <DeleteIcon />
                          </span>
                        </div>
                        <img
                          src={room.images[0]?.image}
                          alt=""
                          srcset=""
                          style={{
                            height: "100%",
                            width: "283px",
                            objectFit: "cover",
                            borderRadius: "6px",
                          }}
                        />
                      </div>
                      <div className="room-card-details">
                        <h3
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setCurrentRoom(room);
                            localStorage.setItem(
                              "currentRoom",
                              JSON.stringify(room),
                            );
                            setIsRoomDetails(true);
                            setIsVenueDetails(false);
                          }}
                        >
                          {room.name}
                        </h3>
                        <p>Price : {room.flat_price}$</p>
                        <p>Maximum Capacity : {room.max_guests}</p>
                        {/* <span>4 Packages</span> */}
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      )}

      <ConfirmationModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        isLoading={isLoading}
        onConfirm={deleteVenue}
        content={`Are you sure you want to delete ${venueData?.name}?`}
      />

      <ConfirmationModal
        open={isConfOpen}
        setOpen={setIsConfOpen}
        isLoading={isLoading}
        onConfirm={deleteRoom}
        content={`Are you sure you want to delete ${isConfOpen?.name}?`}
      />
      <EditLocation
        open={isEditModalOpen}
        getVenues={getVenues}
        setOpen={setIsEditModalOpen}
        setIsVenueDetails={setIsVenueDetails}
        venueData={venueData}
      />
      {isRoomModalOpen && (
        <AddNewRoom
          getVenues={() => getVenues(venueData?.id)}
          open={isRoomModalOpen}
          setOpen={setIsRoomModalOpen}
          isDirect
          handleLocationModal={() => {}}
          venueId={venueData?.id}
        />
      )}
    </>
  );
};

export default VenueDetails;
