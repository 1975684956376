import React, { useState, useEffect } from "react";
import usePopup from "../../../common/MessageModal/usePopup";
import { Select } from "antd";
import { updateEvent } from "../../../services/events";
import styles from "./comps.module.scss";

const StatusDropdown = ({ eventId, value, fetchEvents, ...rest }) => {
  const popup = usePopup();
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState({});

  const updateEventStatus = async (val) => {
    setLoading(true);
    try {
      const res = await updateEvent(eventId, {
        status: val,
      });
      popup.success("Success!", res?.data?.message);
      fetchEvents("no_loading");
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = "/server-error";
        console.log("Redirecting to server error page...");
      } else {
        popup.error("Oops...", error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const EVENT_STATUSES = [
    {
      value: "open",
      label: "Open",
    },
    {
      value: "in_progress",
      label: "In Progress",
    },
    {
      value: "booked",
      label: "Booked",
    },
    {
      value: "scheduled",
      label: "Scheduled",
    },
    {
      value: "completed",
      label: "Completed",
    },
    {
      value: "cancelled",
      label: "Cancelled",
    },
  ];

  useEffect(() => {
    if (value) {
      const filtered = EVENT_STATUSES?.filter((item) => item.value === value);
      setSelectedStatus(filtered);
    }
  }, [value]);

  return (
    <Select
      {...rest}
      loading={loading}
      value={selectedStatus}
      popupMatchSelectWidth={false}
      options={EVENT_STATUSES}
      className={`${styles.statusDropdown} ${styles[value]}`}
      onChange={(newVal) => {
        updateEventStatus(newVal);
        setSelectedStatus(newVal);
      }}
    />
  );
};

export default StatusDropdown;
