import React from "react";
import { Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import toast, { Toaster } from "react-hot-toast";
import { theme } from "./theme";
import "./App.scss";
import PrivateRoutes from "./routes/PrivateRoutes";
import MainPage from "./pages/MainPage";
import Events from "./pages/Events";
import EventInfo from "./pages/Events/EventInfo/Index";
// import Venues from "./pages/Venues/Index";
import Team from "./pages/MainPage/components/Team/Team";
import Subscriptions from "./pages/MainPage/components/Subscriptions";
import UserSettings from "./pages/MainPage/components/UserSettings";
import FAQs from "./pages/MainPage/components/FAQs";
import ProfileSetting from "./pages/MainPage/components/ProfileSetting";
import PasswordChange from "./pages/MainPage/components/PasswordChange";
import Integrations from "./pages/Integrations/index";
import PaymentIntegration from "./pages/Integrations/PaymentIntegration/index";
import ErrorPage from "./pages/Error/index";
//---
import Loginscreen from "../src/Authorization/Login/Loginscr";
import Register from "../src/Authorization/Register/Register";
import RegisterInvited from "../src/Authorization/Register/RegisterInvited";
import ActivateAccount from "../src/Authorization/Register/ActivateAccount";
import ForgetPassword from "../src/Authorization/ForgetPasswrod/ForgetPasswrd";
import Resetpassword from "../src/Authorization/ResetPasswrd/Resetpaswrd";
import ResendPassword from "./Authorization/RegisterComplete/ResendPassword";
import Resendemail from "./Authorization/RegisterComplete/Resendemail";
import AdminDb from "../src/Dashboard/AdminDB";
import EndUserFlow from "./pages/EndUserFlow";
import { GoogleOAuthProvider } from "@react-oauth/google";
import SetupPayment from "./pages/Integrations/PaymentIntegration/SetupPayment";
import StripeAccountInfo from "./pages/Integrations/PaymentIntegration/StripeAccountInfo";
import Venues from "./pages/CompanySettings/Venues";
import VenueDetails from "./pages/CompanySettings/VenueDetails";
import RoomDetails from "./pages/CompanySettings/RoomDetails";
import Packages from "./pages/CompanySettings/Packages";
import Services from "./pages/CompanySettings/Services";
import MessageModalContext from "./common/MessageModal/MessageModalContext";
import MessageModal from "./common/MessageModal";
import LinkStripe from "./pages/Integrations/PaymentIntegration/LinkStripe";
//onBoarding
import OnBoardAdmin from "./pages/CompanySettings/Onboarding/OnBoardAdmin";
import BusinessType from "./pages/CompanySettings/Onboarding/BaseSteps/BizType";
import BusinessName from "./pages/CompanySettings/Onboarding/BaseSteps/BizName";
import BusinessAddress from "./pages/CompanySettings/Onboarding/BaseSteps/BizAddress";
import BusinessWebsite from "./pages/CompanySettings/Onboarding/BaseSteps/BizWebsite";
import BusinessEmail from "./pages/CompanySettings/Onboarding/BaseSteps/BizEmail";
import BusinessPhone from "./pages/CompanySettings/Onboarding/BaseSteps/BizPhone";
import SetupLoading from "./pages/CompanySettings/Onboarding/BaseSteps/SetupLoading";
import SetupComplete from "./pages/CompanySettings/Onboarding/BaseSteps/SetupComplete";
import Setupsidebar from "./pages/CompanySettings/Onboarding/common/setupsidebar";
import ServerError from "./pages/Error/ServerError";
import AccountOnboarding from "./pages/Integrations/PaymentIntegration/AccountOnboarding";

function App() {
  return (
    <ConfigProvider theme={theme}>
      <MessageModalContext>
        <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
          <Routes>
            {/* All private routs goes here */}
            <Route element={<PrivateRoutes />}>
              <Route path="/company" element={<MainPage />} />
              <Route path="/company/venues" element={<Venues />} />
              <Route path="/company/packages" element={<Packages />} />
              <Route path="/company/services" element={<Services />} />
              <Route path="/company/venues/:id" element={<VenueDetails />} />
              <Route path="/company/venues/:venue_id/rooms/:id" element={<RoomDetails />} />
              <Route path="/events" element={<Events />} />
              <Route path="/event/details" element={<EventInfo />} />
              <Route path="/userSettings" element={<UserSettings />} />
              <Route path="/passwordchange" element={<PasswordChange />} />
              <Route path="/team" element={<Team />} />
              <Route path="/subscriptions" element={<Subscriptions />} />
              <Route path="/ProfileSetting" element={<ProfileSetting />} />
              <Route path="/FAQs" element={<FAQs />} />
              <Route path="/" element={<AdminDb />} />
              <Route path="/integrations" element={<Integrations />} />
              <Route path="/Integration/LinkStripe" element={<LinkStripe />} />
              <Route path="/integration/stripe/setup/:accountType" element={<PaymentIntegration />} name="PaymentIntegration" />
              <Route path="/integration/LinkStripe/setup" element={<SetupPayment />} name="SetupPayment" />
              <Route path="/integration/stripe/onboarding" element={<AccountOnboarding />} name="AccountOnboarding" />
              <Route path="/integration/StripeInfo" element={<StripeAccountInfo />} />
            </Route>
            <Route path="onboarding" element={<OnBoardAdmin />} />
            <Route path="businesstype" element={<BusinessType />} />
            <Route path="businessname" element={<BusinessName />} />
            <Route path="businessaddress" element={<BusinessAddress />} />
            <Route path="businesswebsite" element={<BusinessWebsite />} />
            <Route path="businessemail" element={<BusinessEmail />} />
            <Route path="businessphone" element={<BusinessPhone />} />
            <Route path="setuploading" element={<SetupLoading />} />
            <Route path="setupcomplete" element={<SetupComplete />} />
            <Route path="setupsidebar" element={<Setupsidebar />} />
            <Route path="/register" element={<Register />} />
            <Route path="/register/invited" element={<RegisterInvited />} />
            <Route path="/login" element={<Loginscreen />} />
            <Route path="/forgetPassword" element={<ForgetPassword />} />
            <Route path="/resetPassword" element={<Resetpassword />} />
            <Route path="/Resendcode" element={<ResendPassword />} />
            <Route path="/Resendemail" element={<Resendemail />} />
            <Route path="/register/activate" element={<ActivateAccount />} />
            <Route path="/booking/:id" element={<EndUserFlow />} />
            <Route path="/server-error" element={<ServerError />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          <Toaster position="top-right" />
          <MessageModal />
        </GoogleOAuthProvider>
      </MessageModalContext>
    </ConfigProvider>
  );
}

export default App;
