import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Card, Button, Progress } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import axios from "../network/axios";
import myApi from "../network/axios";

const ProgressCard = () => {
  const [company, setCompany] = useState(null);
  const [locations, setLocations] = useState(null);
  const [packages, setPackages] = useState(null);
  const [services, setServices] = useState(null);
  const [isRemainingSetups, setIsRemainingSetups] = useState(false);
  const selectedLocation = useSelector((state) => state.venues.selectedVenue);
  const navigate = useNavigate();

  const getCompany = () => {
    return new Promise((resolve, reject) => {
      myApi
        .get("/api/company/")
        .then((response) => resolve(response))
        .catch((error) => {
          handleServerError(error);
        });
    });
  };

  const getCompanyDetails = () => {
    getCompany()
      .then((res) => {
        setCompany(res?.data?.results[0]);
      })
      .catch((error) => {});
  };

  const getLocation = async () => {
    try {
      const response = await axios.get("/api/company/location/");
      setLocations(response?.data?.results);
    } catch (error) {
      handleServerError(error);
    }
  };

  const getPackages = async () => {
    try {
      const response = await axios.get(
        `/api/company/package/?location=${selectedLocation?.id}`,
      );
      setPackages(response?.data?.results);
    } catch (error) {
      handleServerError(error);
    }
  };

  const getServices = async () => {
    try {
      const response = await axios.get(
        `/api/company/addon/?location=${selectedLocation?.id}`,
      );
      setServices(response?.data?.results);
    } catch (error) {
      handleServerError(error);
    }
  };

  const handleServerError = (error) => {
    const errorCode = error?.response?.status || 500;
    if (errorCode === 500 || errorCode === 503) {
      // Navigate to the server error page
      navigate("/server-error");
    }
  };
  useEffect(() => {
    getCompanyDetails();
    getLocation();
  }, []);

  useEffect(() => {
    if (selectedLocation?.id) {
      getPackages();
      getServices();
    }
  }, []);

  const totalProgress = () => {
    let total = 0;
    total += Math.round(company?.progress) === 100 ? 25 : 0;
    total += locations?.length ? 25 : 0;
    total += packages?.length ? 25 : 0;
    total += services?.length ? 25 : 0;
    return total;
  };

  return (
    <div>
      <Card className="Dashboard-cards">
        <p
          style={{
            fontSize: "13px",
            fontWeight: "300",
            lineHeight: "20px",
            letterSpacing: "0.07px",
          }}
        >
          We are glad to have you onboard. Here is your quick start guide to set
          up the system.
        </p>
        <br />
        <Card
          style={{
            width: "100%",
            borderRadius: "12px",
            border: "1px solid #E0E2E7",
            background: "#FFF",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <div style={{ flex: 1, textAlign: "left" }}>
                <span style={{ color: "#0499CB", fontSize: "16px" }}>
                  {totalProgress() || 0}%
                </span>
              </div>

              <Progress
                percent={totalProgress()}
                strokeColor="#0499CB"
                showInfo={false}
              />
              <div className="dashed-border">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1 }}></div>
                </div>
              </div>
            </div>
          </div>

          {isRemainingSetups && (
            <>
              <div>
                <Link to="/company/venues">
                  <Card className="progress-cards">
                    <div className="progress-cards-info">
                      <CheckCircleOutlined />
                      &nbsp;&nbsp;
                      <p>Finish Company Details</p>
                      <Progress
                        type="circle"
                        percent={Math.round(company?.progress) || 0}
                        size={35}
                        style={{ marginLeft: "auto" }}
                      />
                    </div>
                  </Card>
                </Link>
                <Link to="/company/venues">
                  <Card className="progress-cards">
                    <div className="progress-cards-info">
                      <CheckCircleOutlined />
                      &nbsp;&nbsp;
                      <p>Add Venue</p>
                      <Progress
                        type="circle"
                        percent={locations?.length ? 100 : 0}
                        size={35}
                        style={{ marginLeft: "auto" }}
                      />
                    </div>
                  </Card>
                </Link>
                <Link to="/company/packages">
                  <Card className="progress-cards">
                    <div className="progress-cards-info">
                      <CheckCircleOutlined />
                      &nbsp;&nbsp;
                      <p>Add Packages</p>
                      <Progress
                        type="circle"
                        percent={packages?.length ? 100 : 0}
                        size={35}
                        style={{ marginLeft: "auto" }}
                      />
                    </div>
                  </Card>
                </Link>
                <Link to="/company/services">
                  <Card className="progress-cards">
                    <div className="progress-cards-info">
                      <CheckCircleOutlined />
                      &nbsp;&nbsp;
                      <p>Add Services</p>
                      <Progress
                        type="circle"
                        percent={services?.length ? 100 : 0}
                        size={35}
                        style={{ marginLeft: "auto" }}
                      />
                    </div>
                  </Card>
                </Link>
              </div>
            </>
          )}

          <div
            style={{
              textAlign: "center",
              marginTop: "1rem",
              width: "100%",
              textAlign: "start",
            }}
          >
            <Button
              type="primary"
              style={{
                color: "#0499CB",
                background: "#E1F8FF",
                width: "97%",
              }}
              onClick={() => setIsRemainingSetups(!isRemainingSetups)}
            >
              Finish the remaining Setups
            </Button>
          </div>
        </Card>
      </Card>
    </div>
  );
};

export default ProgressCard;
