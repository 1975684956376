import React, { useState, useEffect } from "react";
import { Col, Row, Button, Card, Spin } from "antd";
import { Link } from "react-router-dom";
import illustrate from "../Assets/product-illustration.png";
import { useNavigate } from "react-router";
import myApi from "./MyApi";
import axios from "./MyApi";
import { toast } from "react-toastify";
import stripeLogo from "../../../assets/icons/Powered by Stripe - black.svg";
import stripeDesc from "../../../assets/icons/stripe.svg";

const LinkStripe = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isLinkAccount, setIsLinkAccount] = useState(false);

  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    checkAccount();
  }, []);

  const getStripeAccountInfo = async () => {
    myApi
      .get(
        `${process.env.REACT_APP_BASE_API_URL}/api/company/integration/stripe/`,
      )
      .then((res) => {
        navigate("/integration/StripeInfo");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkAccount = async () => {
    setButtonLoading(true);
    await myApi
      .get(
        `${process.env.REACT_APP_BASE_API_URL}/api/company/integration/stripe/check/`,
      )
      .then((result) => {
        getStripeAccountInfo();

        setIsLinkAccount(true);
        setButtonLoading(false);
      })
      .catch((error) => {
        getStripeAccountInfo();

        setIsLinkAccount(false);
        setButtonLoading(false);
      });
  };

  const LinkAccount = async () => {
    setLoading(true);
    await myApi
      .post(
        `${process.env.REACT_APP_BASE_API_URL}/api/company/integration/stripe/link/`,
      )
      .then((result) => {
        setLoading(false);
        navigate("/integration/StripeInfo");
      })
      .catch((error) => {
        toast.error("No Previous Data Found", {
          position: "top-right",
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Card style={{ padding: "60px 30px 60px 30px" }}>
        <Row justify="center" align="middle" gutter={[16, 16]}>
          <Col span={24} lg={8}>
            <img src={illustrate} alt="" />
          </Col>
          <Col span={24} lg={12}>
            <div>
              <div style={{ display: "flex", marginBottom: "10px" }}>
                <img
                  src={stripeDesc}
                  alt=""
                  style={{ height: "50px", marginRight: "10px" }}
                />
                <img src={stripeLogo} alt="" style={{ height: "50px" }} />
              </div>
            </div>
            <p
              style={{
                fontSize: "24px",
                fontWeight: 700,
                marginBottom: "20px",
                color: "#0B98D2",
              }}
            >
              Oops!, Stripe Account Not Linked
            </p>
            <p style={{ fontSize: "16px" }}>
              Click to create and link your stripe account.
            </p>
            {buttonLoading === true ? (
              <>
                <Spin size="large">
                  <div
                    style={{
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  />
                </Spin>
              </>
            ) : (
              <>
                <div>
                  {isLinkAccount === true ? (
                    <Button
                      type="primary"
                      size="large"
                      loading={loading}
                      onClick={LinkAccount}
                      style={{ marginTop: "50px", marginRight: "20px" }}
                    >
                      Link Account
                    </Button>
                  ) : null}
                  <Link to="/integration/stripe/setup/company">
                    <Button
                      type="primary"
                      size="large"
                      style={{ marginTop: "50px" }}
                    >
                      Create Account
                    </Button>
                  </Link>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default LinkStripe;
