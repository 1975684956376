import React from "react";
import { Button, Modal } from "antd";

const TermsModal = ({ open, handelClose, setChecked, content }) => {
  const renderdButtons = () => {
    return [
      <Button
        size={"large"}
        key="next"
        type="primary"
        onClick={() => {
          setChecked(true);
          handelClose();
        }}
      >
        I agree to terms & conditions
      </Button>,
    ];
  };

  return (
    <Modal
      centered
      title={content && content[0]?.title ? content[0]?.title : "Terms & Conditions"}
      open={open}
      onCancel={handelClose}
      footer={renderdButtons()}
    >
      <div className="end-user-terms-wrapper">{content && content[0]?.terms_html ? content[0]?.terms_html : ""}</div>
    </Modal>
  );
};

export default TermsModal;
