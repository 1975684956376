import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, Spin } from "antd";
import LoginHero from "../ResetPasswrd/Assets/Login.png";
import logo from "../ResetPasswrd/Assets/logo.png";
import "./Resetpassword.css";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import PasswordCheckList from "../Register/PassowrdCheckList";
import PasswordStrengthBar from "react-password-strength-bar";
import axiosInstance from "../../common/axiosInstance";
const Resetpaswrd = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(null); // Change the initial state to null
  const [passwordValue, setPasswordValue] = useState("");
  const [message, setMessage] = useState("");
  const [strength, setStrength] = useState(0);
  const [password, setPassword] = useState(0);
  const [progress, setProgress] = useState(0);
  const [passwordBorderColor, setPasswordBorderColor] = useState("");
  const [showPasswordStrengthModal, setShowPasswordStrengthModal] =
    useState(false);
  const [form] = Form.useForm();
  function useQuery() {
    let search = new URL(document.location).searchParams;
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  const token = query.get("token");
  const uid = query.get("uid");

  const validateToken = async () => {
    try {
      // Send a request to validate the token
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/auth/password_reset/validate_token/`,
        { uid, token },
      );
      setIsTokenValid(true);
    } catch (error) {
      setIsTokenValid(false); // Invalid or expired token
    }
  };

  useEffect(() => {
    validateToken();
  }, [uid, token]);

  const resetPassword = async (values) => {
    setLoading(true);
    try {
      const payload = {
        uid: uid,
        token: token,
        password: values.password,
        confirm_password: values.confirm_password,
      };

      // Send a request to reset the password
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/auth/password_reset/confirm/`,
        payload,
      );

      toast.success(response?.data?.message);
      navigate("/login");
    } catch (error) {
      toast.error("Invalid/Expired link");
    } finally {
      setLoading(false);
    }
  };
  //password Strength cheker:::
  const [passwordErrors, setPasswordErrors] = useState({
    length: false,
    hasUpperCase: false,
    hasDigit: false,
    hasSpecialChar: false,
  });

  const handlePassword = (passwordValue) => {
    const strengthChecks = {
      length: passwordValue.length >= 8,
      hasUpperCase: /[A-Z]+/.test(passwordValue),
      hasDigit: /[0-9]+/.test(passwordValue),
    };

    setPassword(passwordValue);
    setPasswordValue(passwordValue);
    setPasswordErrors(strengthChecks);

    const newStrength = Object.values(strengthChecks).filter(Boolean).length;
    setStrength(newStrength);
    setProgress((newStrength / 4) * 100);

    if (newStrength === 1) {
      setMessage("Too Short");
    } else if (newStrength === 2) {
      setMessage("Weak");
    } else if (newStrength === 3) {
      setMessage("Okay");
    } else if (newStrength === 4) {
      setMessage("Good");
    } else if (newStrength == 5) {
      setMessage("Strong");
      setStrength(5);
    }
    const borderColor = getPasswordBorderColor(newStrength);
    setPasswordBorderColor(borderColor);
    setShowPasswordStrengthModal(true);
  };

  const getPasswordBorderColor = (strength) => {
    const barColors = ["", "red", "orange", "blue", "#25c281"];
    return barColors[Math.min(strength, barColors.length - 1)];
  };
  const handlePasswordBlur = (e) => {
    const isClickOutsideCard =
      !e.relatedTarget || !e.relatedTarget.closest(".password-strength-modal");
    if (isClickOutsideCard) {
      setTimeout(() => {
        setShowPasswordStrengthModal(false);
      }, 0);
    }
  };
  return (
    <Row>
      <Col span={24} lg={11} className="reset-image-column">
        <div className="reset-image">
          <img src={LoginHero} alt="reset Image" style={{ height: "100vh" }} />
        </div>
      </Col>
      {isTokenValid === null ? (
        <Col span={24} lg={13} className="reset-form-col">
          <div className="reset-form-container">
            <Spin size="large" />
          </div>
        </Col>
      ) : isTokenValid ? (
        <>
          <Col span={24} lg={13} className="reset-form-col">
            <div className="reset-form-container">
              <div>
                <div className="reset-logo">
                  <img src={logo} alt="" />
                </div>
                <p className="reset-heading">Reset Password</p>
              </div>
              <br />
              <Form
                name="reset-form"
                form={form}
                initialValues={{ remember: true }}
                onFinish={resetPassword}
              >
                <label className="reset-password-lable" htmlFor="password">
                  New Password*
                </label>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject("Please enter your password!");
                        }
                        // const passwordPattern = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&#.^()_]{8,}$/;
                        if (value.length < 8) {
                          return Promise.reject("Password is too short!");
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="New passowrd"
                    className="reset-form-item"
                    onChange={({ target }) => {
                      handlePassword(target.value);
                    }}
                    style={{ borderColor: getPasswordBorderColor(strength) }}
                    onBlur={(e) => handlePasswordBlur(e)}
                  />
                </Form.Item>
                {showPasswordStrengthModal && (
                  <div className="overlay">
                    <div className="password-strength-modal">
                      <p>Password Strength </p>
                      <PasswordStrengthBar password={passwordValue} />
                      <PasswordCheckList passwordErrors={passwordErrors} />
                    </div>
                  </div>
                )}
                <label className="reset-password-lable" htmlFor="password">
                  Confirm Password*
                </label>
                <Form.Item
                  name="confirm_password"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    {
                      validator: (_, value) => {
                        if (value !== form.getFieldValue("password")) {
                          return Promise.reject("Mismatched passwords!");
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Confirm  password"
                    className="reset-form-item"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="reset-button"
                    loading={loading}
                  >
                    <span className="button-text">
                      {loading ? null : "Reset Password"}
                    </span>
                  </Button>
                </Form.Item>
                <p className="back-to-login-text">
                  Back to log in page?{" "}
                  <Link
                    to="/login"
                    style={{ color: "#0499CB", fontWeight: "bold" }}
                  >
                    Back now
                  </Link>
                </p>
              </Form>
            </div>
          </Col>
        </>
      ) : (
        <Col span={13} className="reset-form-col">
          <div className="reset-form-container" style={{ textAlign: "center" }}>
            <div className="logo-container">
              <img src={logo} alt="" />
            </div>
            <div className="center-content">
              <h3>Invalid OR Expired Link</h3>
            </div>
          </div>
        </Col>
      )}
    </Row>
  );
};

export default Resetpaswrd;
