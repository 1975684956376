import React from "react";
import "./Resendemail.css";
import mail from "../RegisterComplete/Assets/mail.png";
import axios from "axios";
import toast from "react-hot-toast";
import { Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../common/axiosInstance";
const Resendemail = ({ email }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const resendEmail = async () => {
    setLoading(true);
    try {
      await axiosInstance.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/auth/register/email_resend/`,
        { email: email },
      );
      toast.success("Email resent successfully");
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        // Navigate to the server error page
        navigate("/server-error");
      } else {
        let errorMessage = error?.response?.data?.message;
        errorMessage = errorMessage?.errors
          ? errorMessage?.errors[0]
          : String(errorMessage);
        toast.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="main-container">
      <div className="box">
        <div className="rectangle">
          <div className="centered-content">
            <img src={mail} alt="img" />
            <div className="label">
              <div className="text-wrapper">
                <h1 className="success-heading">Almost There!</h1>
              </div>
              <p className="resend-email-paragraph">
                Check your email inbox and confirm your account
              </p>
            </div>
            <br />
            <hr style={{ marginTop: "1.5rem" }} />
            <div style={{ textAlign: "center", color: "#5A607F" }}>
              <p>Did not receive the email? Resend</p>
              <div style={{ marginTop: "1.4rem", height: "70px" }}>
                {/* <Button type='primary' className='resend-code' onClick={resendEmail} loading={loading} >
                  Resend email
                </Button> */}
                <button
                  type="primary"
                  className="resend-code"
                  onClick={resendEmail}
                  loading={loading}
                >
                  {loading ? "loading..." : "Resend email"}
                </button>
              </div>
              <p className="back-to-login-text">
                Back to log in page?{" "}
                <Link
                  to="/login"
                  style={{
                    color: "#0499CB",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  Back now
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resendemail;
